import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Container, Button } from '@mui/material';

import { ContentWrapper } from '../common';

const bannerImg = require('../../assets/nurses_versus_banner.png');
const headerImg = require('../../assets/one_header_img.png');

const Wrapper = ({
  prevPath,
  nextPath,
  nextPathLabel,
  containerWidth,
  children,
  hasHeader,
  showButtons
}) => {
  const navigate = useNavigate();
  const { section } = useParams();

  const topic = useSelector((state) => state.data.topic);
  const region = useSelector((state) => state.data.region);

  const renderHeader = () => {
    if (section === 'game') {
      return <img src={bannerImg} alt="Nurses versus" style={{ width: '100%' }} />;
    }
    return (
      <h2
        className="purple flex vertical-center horizontal-center"
        style={{
          height: 150,
          padding: 0,
          margin: 0,
          textAlign: 'center',
          backgroundImage: `url(${headerImg})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        }}
      >
        Find Your <br /> Oncology Nurse Educator (ONE)
      </h2>
    );
  };

  return (
    <ContentWrapper hasCurtains={section === 'game'} standAlone={section === 'standalone'}>
      <Container maxWidth="lg" className="flex-center" sx={{ height: '90%', display: 'flex' }}>
        <div className="flex-center direction-column">
          {hasHeader && renderHeader()}
          <Container
            maxWidth={containerWidth || 'sm'}
            className="flex-center"
            style={{ marginTop: !hasHeader && 80 }}
          >
            {children}
            {showButtons && (
              <div
                className="flex horizontal-center"
                style={{
                  justifyContent: 'space-around',
                  margin: '20px auto',
                  width: '75%'
                }}
              >
                {section !== 'standalone' && (
                  <Button
                    variant="contained"
                    color="midLightGray"
                    onClick={() => navigate(prevPath)}
                    className="bold"
                    sx={{ width: 200, height: '3.5rem', margin: '0 auto' }}
                  >
                    Previous
                  </Button>
                )}
                <Button
                  variant="contained"
                  color={!topic || !region ? 'midLightGray' : 'secondary'}
                  disabled={!topic || !region}
                  onClick={() => navigate(nextPath)}
                  className="bold"
                  sx={{ width: 200, margin: '0 auto' }}
                >
                  {nextPathLabel}
                </Button>
              </div>
            )}
          </Container>
        </div>
      </Container>
    </ContentWrapper>
  );
};

export default Wrapper;
