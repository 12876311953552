/* eslint-disable no-console */
import { hot } from 'react-hot-loader/root';

import ReactGA from 'react-ga';

import { useEffect } from 'react';

import { CircularProgress } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';

import { useIdleTimer } from 'react-idle-timer';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';

import '../styles/App.css';

import { getInitialData } from '../redux/dataSlice';

import Home from './Home';
import TopicAndRegion from './TopicAndRegion';
import Game from './Game';
import OneAndLeaderBoard from './OneAndLeaderBoard';
import LiveLeaderboard from './OneAndLeaderBoard/LiveLeaderboard';

ReactGA.initialize('UA-15348392-70');

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  // const { pathname } = window.location;

  const { loading, loaded } = useSelector((state) => state.data);

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    dispatch(getInitialData());
  }, []);

  const handleOnIdle = (event) => {
    if (process.env.REACT_APP_IGNORE_IDLE === 'TRUE') return;
    console.log('user is idle', event);
    console.log('last active', getLastActiveTime());
    if (window.location.pathname !== '/live-leaderboard') {
      if (window.location.pathname.indexOf('standalone') > -1) {
        window.location.href = '/standalone/topics';
      } else {
        window.location.href = '/';
      }
    }
  };

  const { getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60,
    onIdle: handleOnIdle,
    debounce: 500
  });

  if (loading || !loaded) {
    return (
      <div className="flex-center direction-column" style={{ height: '100vh' }}>
        <p>Loading data.... be patient!</p>
        <CircularProgress size={80} />
      </div>
    );
  }

  return (
    <div className="flex horizontal-center">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:section/topics/*" element={<TopicAndRegion />} />
        <Route path="/game/*" element={<Game />} />
        <Route path="/leaderboard" element={<OneAndLeaderBoard hasLeaderBoard />} />
        <Route path="/one/*" element={<OneAndLeaderBoard />} />
        <Route path="/standalone/one/*" element={<OneAndLeaderBoard standAlone />} />

        <Route path="/live-leaderboard" element={<LiveLeaderboard />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
};

export default process.env.NODE_ENV === 'development' ? hot(App) : App;
