import Typography from '@mui/material/Typography';
import styles from '../../styles/ScoreHolder.module.scss';

const ScoreBox = ({ score, opponentScore }) => {
  return (
    <div className={styles.scoreHolder}>
      <div className={styles.scoreBox}>
        <Typography variant="h5" component="p">
          Your Score
        </Typography>
        <Typography
          variant="h3"
          component="div"
          align={'center'}
          style={{ backgroundColor: '#5EC2D2', borderBottomLeftRadius: 10 }}
        >
          {score}
        </Typography>
      </div>
      <div className={styles.scoreBox}>
        <Typography variant="h5" component="p">
          Challenger
        </Typography>
        <Typography
          variant="h3"
          component="div"
          align={'center'}
          style={{ backgroundColor: '#3C8CAC', borderBottomRightRadius: 10 }}
        >
          {opponentScore}
        </Typography>
      </div>
    </div>
  );
};

export default ScoreBox;
