const PrivacyNotice = () => {
  return (
    <div style={{ paddingRight: 15, marginRight: 20 }}>
      By registering on this form, you will sign up for the latest news, resources, and educational
      materials from AstraZeneca. <br />
      <br />
      <b>Brief Privacy Notice:</b>
      <br />
      AstraZeneca is committed to helping Healthcare Professionals provide appropriate care for
      their patients. Please provide us with the following information so that we can keep your
      records up-to-date and can send you any information that you request. If you no longer want to
      receive health-related materials, please call 1-800-236-9933. Please visit
      www.azprivacynotice.com to review our Privacy Notice.
    </div>
  );
};

export default PrivacyNotice;
