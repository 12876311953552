// import { Grid } from '@mui/material';
import HeaderNav from './HeaderNav';
import styles from '../../styles/ContentWrapper.module.scss';

const ContentWrapper = ({ children, hasCurtains, hideHeader, standAlone }) => {
  const curtainWrapper = () => {
    // return (
    //   <>
    //     <HeaderNav color="light" />
    //     <Grid container className={styles.mainBg} wrap={'nowrap'}>
    //       <Grid item xs={1} className={`${styles.curtain} ${styles.curtainLeft}`} />
    //       <Grid item xs={12} md={10} sx={{ minWidth: 400 }}>
    //         {children}
    //       </Grid>
    //       <Grid item xs={1} className={`${styles.curtain} ${styles.curtainRight}`} />
    //     </Grid>
    //   </>
    // );
    return (
      <div className={styles.gameBg}>
        {!hideHeader && <HeaderNav color="light" hideSong />}
        {children}
      </div>
    );
  };

  const oneWrapper = () => {
    return (
      <div className={styles.oneBg}>
        {!hideHeader && <HeaderNav color="purple" hideSong standAlone={standAlone} />}
        {children}
      </div>
    );
  };

  return hasCurtains ? curtainWrapper() : oneWrapper();
};

export default ContentWrapper;
