import { Checkbox, SvgIcon } from '@mui/material';

const CheckedIcon = (props) => {
  // console.log('props', props);
  return (
    <SvgIcon {...props}>
      <g transform="translate(0.000000,508.000000) scale(0.100000,-0.100000)">
        <path
          d="M208.46,5063.43c-0.92-0.83-5.61-5.04-10.42-9.39l-8.75-7.86l-88.37-0.21l-88.37-0.21l-1.67-0.91
    c-1.97-1.03-3.98-3.06-5.07-5.09c-0.75-1.41-0.8-4.42-0.8-89.31v-87.86l0.92-1.65c1.05-1.94,3.1-3.93,5.15-5
    c1.42-0.74,4.56-0.79,89.59-0.91c97.67-0.08,90.22-0.29,93.69,2.6c0.92,0.79,2.14,2.32,2.68,3.43l0.96,1.94l0.13,65.58l0.08,65.58
    l3.89,3.68c8.88,8.44,18.21,16.37,27.38,23.2l5.53,4.13l-2.76,3.68c-7.58,10.13-15.49,22.78-19.68,31.51
    c-1.21,2.52-2.26,4.63-2.3,4.63C210.17,5065,209.38,5064.3,208.46,5063.43z M171.16,5028.53c-32.28-32.38-56.18-61.65-68.2-83.52
    c-1.09-2.03-2.09-3.72-2.3-3.85c-0.17-0.08-2.68,2.48-5.57,5.71c-11.81,13.35-25.83,27.41-37.72,37.83
    c-3.43,2.98-6.36,5.46-6.53,5.46c-0.17,0-2.68-3.23-5.57-7.11c-6.2-8.35-13.27-17.24-18.29-22.87c-1.97-2.19-3.6-4.13-3.68-4.34
    c-0.04-0.17,1.72-1.78,3.98-3.56c24.87-19.76,51.53-45.94,74.48-73.14l1.67-2.03l4.14,6.16c18,26.79,43.41,59.71,64.14,83.07
    c5.36,6,18.5,20.09,18.8,20.09c0.08,0,0.17-27.45,0.17-60.99v-61.03l-1.05-0.99l-1-1.03h-87.16h-87.2l-0.96,0.91l-0.96,0.87v86.25
    v86.21l0.92,0.95l0.88,0.95h83.52h83.52L171.16,5028.53z"
        />
      </g>
    </SvgIcon>
  );
};

const CustomCheckBox = (props) => {
  return (
    <Checkbox
      {...props}
      checkedIcon={<CheckedIcon />}
      sx={{
        'color': props.section === 'game' ? 'neutral.main' : null,
        'transform': 'scale(1.5)',
        '&.Mui-checked': {
          color: props.checkBoxColor || 'text.green'
        }
      }}
    />
  );
};

export default CustomCheckBox;
