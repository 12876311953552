import _ from 'lodash';

import './mapStyles.css';

const USAMap = (props) => {
  const { handleMapClick } = props;

  return (
    <svg version="1.1" x="0px" y="0px" viewBox="0 0 1491.46 922.25">
      <g id="Northwest_Region" data-name="Northwest" onClick={handleMapClick}>
        <g id="Northwest_Section_7">
          <path
            className="st0"
            d="M427.8,352.3c-3-0.7-6.1-1.3-9.1-2c-21.1-4.6-42.2-9.2-63.3-13.8c-0.1,0.3-0.3,0.8-0.4,1.3
      c-5.1,20.5-10.3,41.1-15.3,61.7c-0.1,0.5-0.1,1.1,0.1,1.7c0.1,0.5,0.3,0.9,0.5,1.2c2.9,4.1,5.7,8.2,8.6,12.3v-13.2l63.5,2.5
      l0.1,9.7h7.2l-0.1-9.4l-0.1-7.3l6.5-0.2L427.8,352.3z"
          />
        </g>
        <g id="Northwest_Section_6">
          <path
            className="st0"
            d="M352.7,335.4c-20.9-5.5-41.8-10.9-62.8-16.3c-0.5-0.1-1.6,0.1-1.8,0.5c-0.4,0.9-0.9,2-0.6,2.8
      c1,3.7,2,7.3-1,10.7c-1,1.1-1.8,2.6-2.1,4c-0.3,1.7-0.8,3.3-1.4,4.8c-1,2.4-2.6,4.5-4.8,6.3c-0.5,0.4-0.9,0.9-1.4,1.4
      c-1.2,1.2-1.6,4.2-0.5,5.6c0.8,1.1,1.6,2.6,2.3,4.3l11.4-0.5l20.3,49.4l27.2-7.3c-0.1-0.4,0-0.8,0.1-1.3
      c3.8-14.8,7.5-29.6,11.2-44.5c1.6-6.5,3.2-13,4.8-19.5C353.2,335.6,352.9,335.5,352.7,335.4"
          />
        </g>
        <g id="Northwest_Section_5">
          <path
            className="st0"
            d="M325.4,233c0.7,0,1.4,0,2.1,0c3.6,0,5.4,2.3,4.6,5.9c-0.4,1.8-1,3.7-1.2,5.5c-0.1,0.6,0.6,1.4,0.9,2
      c0.1,0.1,0.3,0.1,0.4,0.2c3.9,2.9,7.9,4.2,12.9,2.4c2-0.7,4.5-0.2,6.7,0.1c1.3,0.2,2.6,0.8,3,2.5c0.1,0.5,0.9,1.2,1.5,1.3
      c1.9,0.3,3.8,0.7,5.6,0.3c2.1-0.5,3.8,0.1,5.6,0.9c1,0.4,2.4,0.6,3.4,0.3c7.8-2.5,15.6-2.1,23.5-0.5c6.9,1.4,13.9,2.6,20.9,3.7
      c1.7,0.3,3.5,0,5.2,0.2c0.6,0.1,1.7,0.8,1.6,1.1c-0.4,3.6,2.2,5.5,4,7.9c0.3,0.4,0.6,0.8,1.1,1.4c-2.1,2.2-4.1,4.4-6.2,6.7
      c-1.1,1.2-2.1,2.4-3.3,3.5c-1.4,1.3-2.4,2.6-1.7,4.7c0.2,0.5-0.3,1.5-0.8,2c-1.7,1.7-3.4,3.4-5.2,5c-1.1,0.9-1.1,1.9-1.2,3.2
      c-0.2,3.5,1.8,4.8,5,5.2c-0.2,0.6-0.2,0.9-0.4,1.1c-4,4.9-5,10.8-6,16.8c-1.5,9-3.2,18.1-4.9,27.1c-0.1,0.4-0.2,0.7-0.4,1.3
      c-4.1-0.9-8.2-1.7-12.3-2.7c-33-8.3-65.9-16.6-98.9-24.9c-1.6-0.4-2.3-1.1-2.6-2.7c-0.1-1-0.4-2-0.7-3c-0.7-2.2-0.8-4.3,0.6-6.2
      c1.5-2,0.8-4.2,0.4-6.3c-0.1-0.5-0.3-1-0.3-1.5c-0.1-1.5,5.1-9,6.4-9.8c1-0.6,2.2-1.5,2.6-2.5c5.4-13.4,10.4-26.9,16-40.2
      c1.8-4.3,2.7-8.7,3.8-13.2c0.3-1.2,0.1-3.1,2.2-3.2c3.7,0,4.6,0.5,5.6,3.3C325.1,232.5,325.3,232.7,325.4,233"
          />
        </g>
        <g id="Northwest_Section_4">
          <path
            className="st0"
            d="M564.2,304.6c-17.9-2-35.8-4.4-53.9-6.7c-4.4,28.7-8.8,57.3-13.2,86c6,1.1,16.3,2.6,28.3,4.2
      c20.1,2.6,45,5.5,63.1,7.3v-7.4l18.6,0v9c2.6,0.2,4.4,0.2,5.2,0.1c0.2-3,0.4-6,0.6-9.1c1.9-26.2,3.7-52.3,5.6-78.2
      C600.1,308,582.1,306.6,564.2,304.6"
          />
        </g>
        <g id="Northwest_Section_3">
          <path
            className="st1"
            d="M472.4,294.8c2.8-5,1.3-8-2.7-10.3c-0.5-0.3-0.6-1.5-0.6-2.3c0.2-3.2,0.6-6.4-1.9-9.3
      c-2.1,1.5-4.4,3.1-6.7,4.7c-0.6-0.5-1.2-1-1.7-1.5c-1.9-1.7-1.5-4.7,0.9-5.6c1.7-0.6,2.4-1.4,1.9-3.2c-1.2-3.8-0.1-7.1,2-10.3
      c1.3-1.9,2.2-4,3.4-6.3c-0.6-0.1-0.8-0.2-1.1-0.1c-5,1-7.1-2.5-9-6c-1-1.7-1.8-3.5-2.4-5.4c-1-2.7-2.6-4.8-4.8-6.6
      c-0.7-0.6-1.2-1.9-1.2-2.9c0-3.4-0.6-6.6-2.2-9.7c-0.8-1.6-1.1-3.9-0.8-5.7c1.1-5.9,2.6-11.8,4-18c57.8,11.8,116,20,175.2,24.5
      c-2.1,28.9-4.1,57.6-6.2,86.7c-18.6-0.8-36.9-2.6-55.1-4.7c-18.2-2.2-36.4-4.6-54.7-6.9c-0.5,3.1-1,6.2-1.6,10
      c-1.5-2.7-2.7-4.9-4.1-7.5c-1.2,1.5-2.2,2.9-3.4,4c-0.6,0.5-1.6,0.9-2.4,0.8c-2.8-0.5-5.5-1.2-8.6-2c-0.5,2.8-2.3,3-4.6,2.2
      c-2.1-0.7-4.3-1.3-4.7,2.5c-0.2-0.1-0.4-0.3-0.6-0.4c-0.4-0.7-0.9-1.3-1-2c-0.3-1.6-0.6-3.3-0.7-5
      C476.7,296.2,476.6,294,472.4,294.8"
          />
        </g>
        <g id="Northwest_Section_2">
          <path
            className="st0"
            d="M502.4,301.3c-1.5,5.4-4.9,4.1-8.2,3.1c-0.4-0.1-0.7-0.1-1.1-0.2c-1.3-0.5-2.6-0.8-3.5-0.3
      c-0.6,0.3-1,1-1.3,2.2c-1.9-0.4-3.7-0.7-5.3-1.1c-2.7-0.7-2,2.7-3.5,3c-5.4-2.3-3.3-7.6-4.9-11.5c-1.2-0.2-2.4-0.4-3.8-0.7
      c0.3-1.5,0.4-2.7,0.8-3.9c0.8-2.2,0.4-3.8-1.7-4.9c-2.3-1.3-3.1-3.1-2.6-5.8c0.3-1.8-0.1-3.7-0.1-5.6c-0.2-0.1-0.5-0.1-0.7-0.2
      c-2,1.4-4,2.8-6,4.3c-1.2-1.1-2.4-2-3.4-3c-0.6-0.7-1.6-1.7-1.5-2.3c0.6-1.9,0.1-4.5,2.6-5.4c1.7-0.7,2-1.6,1.5-3.1
      c-0.8-2.9-0.5-5.5,1.3-8c1.2-1.6,2-3.5,2.8-5.1c-1.6-0.6-3.4-0.7-4.1-1.6c-2.1-2.6-4-5.4-5.5-8.3c-1.8-3.7-3.4-7.6-7-10.1
      c-0.5-0.4-0.8-1.5-0.7-2.2c0.4-3.5,0.1-6.8-2-9.8c-0.3-0.4-0.5-0.9-0.5-1.4c0.1-2.7-0.1-5.5,0.3-8.2c0.9-5.1,2.2-10.1,3.4-15.2
      c-4.4-0.9-8.5-1.8-12.7-2.7c-0.1,0-0.1,0-0.2,0c-0.2,0.7-0.4,1.3-0.5,1.8c-3,13.8-6.2,27.6-9.1,41.4c-1.1,5.1-2.8,10-2.3,15.4
      c0.6,6.1,1,12,6.3,16.2c0.1,0.1,0.1,0.2,0.2,0.5c-3.4,3.6-6.8,7.3-10.2,11c-1.1,1.2-1.7,2.4-1.5,4.1c0.1,0.9-0.4,2.1-1.1,2.8
      c-1.7,1.8-3.6,3.4-5.4,5.2c-1.4,1.4-0.8,4.2,1,4.9c1.3,0.5,2.7,1,4.1,1.6c-1.3,2.1-2.6,4-3.6,6c-1.1,2.2-2.1,4.5-2.6,6.8
      c-1.6,7.6-2.9,15.3-4.3,23c-0.7,3.8-1.3,7.7-2,11.5c0.6,0.2,1,0.4,1.3,0.5c7.5,1.6,15,3.2,22.5,4.7l1-22.1h60.7v-9.5h7.4v9.5h6.6
      c0.5-3.2,1-6.4,1.5-9.5c0.3-1.8,0.6-3.5,0.9-5.2C507,308.8,504.8,305.5,502.4,301.3"
          />
        </g>
        <g id="Northwest_Section_1">
          <path
            className="st0"
            d="M433,193.3c-1.1,5-2.3,10.3-3.4,15.7c-2.8,12.6-5.6,25.1-8.2,37.8c-0.5,2.3,0.2,4.8,0.3,7.1
      c0,0.7,0.1,1.4,0.2,2.2c-4.9,0.7-9.4-0.6-14-1.3c-6.6-1.1-13.2-2.3-19.8-3.4c-5.8-0.9-11.5-0.2-17.1,1.3c-1.1,0.3-2.5,0.1-3.4-0.3
      c-1.8-0.9-3.5-1.4-5.6-0.7c-1.1,0.4-2.5,0.4-3.7,0.1c-0.8-0.2-1.5-1-2.2-1.6c-1.3-0.9-2.6-2.4-4-2.6c-3.3-0.3-6.7-0.2-9.9,0.3
      c-2.1,0.3-3.8,0.4-5.7-0.8c-3.8-2.4-3.8-2.2-2.9-6.6c0.3-1.6,0.6-3.3,0.2-4.8c-0.8-3.6-3.2-5.3-7.2-4.1c0.1-3.2-1.8-3.9-4.2-3.9
      c-0.8,0-1.6-0.2-2.4-0.3c-3-0.4-3.8-1.8-2.6-4.6c0.2-0.5,0.5-1,0.9-1.8c0.8,1.5,1.4,2.7,2.2,4.3c1.1-2.7,2.1-4.9,2.9-7.3
      c0.1-0.3-0.3-1-0.7-1.4c-0.5-0.5-1.2-0.8-2-1.4c2.1-1,4-1.9,6.1-2.9c-1.1-0.6-2-1.2-2.9-1.5c-3.3-0.9-4-3-3.6-6.2
      c0.4-3.9,0.4-7.9,0.4-11.8c0-0.9-0.3-2.1-0.9-2.8c-1.3-1.6-1.5-3.1-0.8-5c0.6-1.6,0.9-3.3,1.4-5.4c2.6,2.3,4.8,4.5,7.3,6.4
      c1.6,1.2,3.5,2.2,5.4,3c3.2,1.4,6.4,2.7,9.6,3.9c1.8,0.7,1.6,2.4,2.3,3.7c1,1.7,0.5,2.8-0.7,3.9c-1.3,1.2-1.3,2.5-0.3,3.8
      c0.4,0.5,0.6,1.1,1,1.9c-0.9,0.6-1.7,1.1-2.6,1.7c-1.2-0.9-2.4-1.8-3.7-2.8c1-1.4,2-2.7,3.1-4c-0.7-1.1-1.3-1.3-2.1-0.2
      c-1.1,1.4-2.3,2.8-3.4,4.2c0.2,0,0.5,0,0.7,0c0.2,1.3,0.8,2.8,0.5,4c-0.5,2.9,0.6,4.7,3,6.2c2.8-1.7,5.7-3.4,8.5-5.2
      c0.5-0.3,0.9-1.1,1-1.7c0.2-0.8,0.2-1.6,0.2-2.4c0.1-3.7,0.5-7.2,4.4-9.2c0.5-0.3,0.9-1.5,0.7-2.2c-0.7-3.2-1.5-6.3-2.3-9.5
      c0.6-0.6,2.1-1.3,2.2-2.2c0.1-2,0.1-4.2-1.6-5.9c-1.5-1.6-2.7-3.5-4-5.3C377.3,179,405,186.1,433,193.3"
          />
        </g>
      </g>
      <g id="Southwest_Region" data-name="Southwest" onClick={handleMapClick}>
        <g id="Southwest_Section_5">
          <path
            className="st2"
            d="M400,562.4c1.2-3.4,1.2-3.3,4.6-2.9c0.9,0.1,2.2,0.1,2.5-0.3c0.9-1.5,1.5-3.1,1.9-4.8c0.1-0.3-1-1-1.5-1.4
      c-1-0.8-2-1.5-2.7-1.9c0-1.8-0.1-3.2,0-4.7c0-0.7,0.1-1.7,0.5-1.9c4-2,4.5-6,5.4-9.7c0.4-1.6,1.1-2.7,2.5-3.6
      c2.6-1.8,5-3.7,7.4-5.5c-0.1-0.3,0-0.4-0.1-0.4c-5.3-3.4-5.4-9.2-6.6-14.5c-0.8-3.4-0.3-6.6,0.8-9.8c0.6-1.7,0.4-3.6,0.4-5.4
      c0.1-2-0.5-4.2,2.2-5c-0.7-1.3-1.5-2.2-1.8-3.3c-0.2-0.8-0.2-2,0.3-2.6c0.3-0.4,1.7-0.5,2.3-0.2c2.1,1.3,4,2.8,6,4.2
      c3.2-1.5,4.5-4.3,5-7.9c0.5-4.3,1.5-8.6,2.2-12.9c28.6,4,57,7.9,85.8,11.9c-5.1,41.3-10.1,82.7-15.3,124.3
      c-3.7-0.4-7.2-0.8-10.8-1.3c-7.9-1-15.8-1.9-23.6-3c-1.3-0.2-2.6-0.6-3.7-1.2c-21-11.8-42-23.7-63-35.5
      C400.7,562.9,400.4,562.6,400,562.4"
          />
        </g>
        <g id="Southwest_Section_4">
          <path
            className="st3"
            d="M451.4,357.1c15.6,2.2,31.1,4.5,46.9,6.7c-1.1,7-2.1,14.2-3.3,21.6c11.1,1.6,22,3.2,33.3,4.8
      c-3.6,29.1-7.1,58.1-10.8,87.7c-28.6-4-57.1-7.9-85.7-11.8C438.4,429.4,444.9,393.2,451.4,357.1"
          />
        </g>
        <g id="Southwest_Section_3">
          <path
            className="st2"
            d="M497,328.5v-9.5h-7.4v9.5H429l-1,22.1c23.3,4.7,46.8,8.8,70.5,11.2c0.1-0.5,0.3-0.9,0.4-1.4
      c1.6-10.6,3.1-21.2,4.8-31.8H497z"
          />
        </g>
        <g id="Southwest_Section_2">
          <path
            className="st2"
            d="M427.8,352.3l-2,44.5l-6.5,0.2l0.1,7.3l0.1,9.4h-7.2l-0.1-9.7l-63.5-2.5v13.2c17.5,25.1,35,50.3,52.5,75.4
      c3.4,4.9,6.8,9.8,10.3,14.7c0.9-1.6,1.3-3.1,1.6-4.7c0.4-2.1,0.4-4.3,0.4-6.5c0-1.3,0.5-2.6,0.8-3.9c0.4-0.3,0.2-0.6,0.1-0.9
      c-1.2-2.1-1.1-4.3,0.3-5.6c1.7-1.6,3.6-0.9,5.2,0.1c1.5,0.9,2.9,2.1,4.8,3.4c0.8-1.2,2-2.3,2.2-3.6c3.2-17.4,6.3-34.8,9.4-52.2
      c1-5.7,2-11.4,3.1-17.2c0.5-2.9,1-5.7,1.5-8.6c0.5-2.9,1-5.8,1.6-8.7c1.7-9.4,3.4-18.8,5-28.2c0.7-3.8,1.3-7.7,1.9-11.2
      C442.3,355.5,435,353.9,427.8,352.3"
          />
        </g>
        <g id="Southwest_Section_1">
          <g>
            <path
              className="st2"
              d="M407,554.8c-0.3,2.5-1.4,2.9-3.8,2.7c-11.4-1.4-22.7-2.4-34.1-3.6c-3.2-0.3-6.5-0.7-9.8-0.9
        c-1.5-0.1-1.9-0.9-2-2.2c-0.1-3.6-0.3-7.2-0.5-10.8l47.7,3.1c-0.1,0.1-0.2,0.1-0.3,0.2c-1.1,0.6-1.4,1.4-1.2,2.9
        c0.3,1.5-0.1,3.2-0.2,5.5C403.7,552.3,405.4,553.6,407,554.8"
            />
            <g>
              <path
                className="st2"
                d="M315.4,513.5v-6c2.8,1.1,5.3,2,7.7,3.1c0.4,0.2,0.6,0.8,0.7,1.3c0,0.2-0.5,0.7-0.8,0.8
          C320.5,513,318,513.2,315.4,513.5"
              />
              <path
                className="st2"
                d="M336.4,545.1c-4.8-3.6-5.6-5.3-5.3-11.1c2,2.4,4.1,4.5,5.6,6.9C337.2,541.8,336.5,543.4,336.4,545.1"
              />
              <path
                className="st2"
                d="M334.4,526.1c4.7,0.7,6.7,4,7.7,8.5C338.4,532.6,334,531.6,334.4,526.1"
              />
              <path
                className="st2"
                d="M312.3,513.7c-1.8-1.1-3.4-2.1-5.2-3.1c1.5-2.7,4.3-2,6.7-3.4C313.2,509.6,312.8,511.4,312.3,513.7"
              />
              <path
                className="st2"
                d="M314.5,526.5c3.4-0.5,4.3,2.2,6.5,3.6c-1.9,0.7-3.4,1.3-5.2,2C315.3,530.2,314.9,528.5,314.5,526.5"
              />
              <path
                className="st2"
                d="M418.4,525.2c-2.6,1.9-4.8,3.4-6.7,5.1c-1,0.8-1.6,2.1-2.3,3.2c-0.4,0.7-0.5,1.7-0.6,2.5
          c-0.4,3-1.7,5.4-4.3,7l-47.7-3.1c0-0.2,0-0.5-0.1-0.7c-0.1-0.9-0.4-2-1-2.8c-3.3-4.5-6.7-9-10.2-13.4c-0.6-0.7-1.7-1.2-2.7-1.5
          c-0.9-0.3-2-0.2-3.1-0.4c-0.1-2.2-0.3-4.2-0.4-6.2c0-1.2-0.4-1.7-1.6-1.7c-1,0-1.9-0.1-2.8-0.1c-4.7-0.3-6.9-2.2-8-6.9
          c-0.1-0.5-0.3-1.1-0.7-1.3c-2.5-1.5-4.9-3.2-7.6-4.2c-3.3-1.2-6.9-1.9-10.3-2.8c-2.6-0.7-4-3.3-3.2-5.9c0.8-2.8,1.6-5.6,2.6-8.3
          c0.6-1.7,0.3-2.6-1.4-3.1c-0.7-0.2-1.4-0.7-2.1-1.1c0-0.3-0.1-0.6,0-0.7c2.3-2.5,1.4-4.6-0.6-6.7c-0.9-0.9-1.5-2.1-2.4-3.1
          c-0.9-1-1.7-2-2.2-3.1c-0.9-1.7-1.4-3.6-1.4-5.7v-0.1c0-0.9-0.7-1.8-1.1-2.7c-1-2-2.2-4-3.2-6c-1.4-2.8-0.7-5.8,2-7.1
          c2.5-1.2,3.6-2.8,3.1-5.6c-0.1-0.9,0-1.7,0-2.9c-1.6-0.2-3.2-0.3-4.8-0.6c-0.6-0.1-1.4-0.3-1.7-0.7c-1.5-2.4-3.3-4.7-2.5-7.8
          c0.3-1.3,0.2-2.6,0.4-3.9c0.1-0.7,0.5-1.4,0.7-2.1c0.6,0.4,1.2,0.7,1.7,1.2c1.6,1.6,3.1,3.4,5.1,4.9c-0.7-4.2-1.5-8.5-2.3-12.7
          c3.5,0,6.9,0,10.3,0c0.4,0,0.9,0.2,1.3,0c0.5-0.2,0.9-0.5,1.3-0.8c-0.3-0.4-0.5-1-0.9-1.2c-1.6-0.6-3.3-1.2-4.9-1.8
          c-0.7-0.2-1.4-0.5-2-0.7c-2.3-0.9-4.6-1.7-7.2-2.6c-1,2.1-1.9,4.1-3,6.6c-1.8-1.6-3.5-3.1-5.2-4.7c-0.2-0.2-0.1-1.3,0.2-1.7
          c1.3-1.7,1.1-3.3,0.2-5c-2.4-4.6-4.8-9.3-7.3-13.9c-0.5-1-0.8-1.7,0-2.8c0.4-0.6,0.4-1.5,0.4-2.3c0.1-1.5,0.2-3.1,0-4.6
          c-0.3-2.6,0.4-4.7,2.4-6.4c0.7-0.5,1-1.2,1.1-1.9c0.1-0.5,0-1-0.1-1.6c-0.1-0.4-1.4-5.3-3.1-9.4l11.4-0.5l20.3,49.4l27.2-7.3
          c0,0.2,0.1,0.5,0.2,0.7c0.2,0.6,0.6,1.2,1,1.8c5.6,8,11.2,16,16.8,24l-47.4-11.4v0l47.4,11.4c1,1.4,1.9,2.8,2.9,4.1
          c17.6,25.2,35.1,50.3,52.7,75.5c0.5,0.7,1,1.6,1.2,2.5c0.7,2.9,1.2,5.8,1.8,8.8C414.5,521.1,415.2,523.7,418.4,525.2"
              />
              <path
                className="st2"
                d="M300.5,412.4c-2.5-0.1-5.1-0.2-7.9-0.4c0.4-1,0.8-1.7,1.2-2.6c2.4,0.9,4.6,1.7,6.9,2.5
          C300.6,412.1,300.6,412.2,300.5,412.4"
              />
            </g>
          </g>
        </g>
      </g>
      <g id="Minneapolis_Region" data-name="Minneapolis" onClick={handleMapClick}>
        <g id="Minneapolis_Section_5">
          <path
            className="st4"
            d="M621.7,289.2c38.1,2.4,75.9,4.7,113.8,3.3c0.7,1.6-0.6,2.2-1.5,3c-1,0.8-1.9,1.5-2.9,2.3
      c1.4,3.3,2.6,6.4,6.3,7.7c0.6,0.2,1.3,1.2,1.3,1.9c0.1,12.3,0.1,24.6,0.1,37c-1.2,0.2-1.9,0.3-2.9,0.5c0.9,3.2-1,7.1,3.1,9.4
      c-2.3,3-3,6.2-2,9.6c-2.1-0.6-4.1-1.3-6.2-1.8c-1.7-0.4-3.2-0.9-3.8-2.8c-0.1-0.4-1-0.8-1.5-0.8c-1.4,0-2.8,0.2-4.1,0.2
      c-2.9,0-5.8-0.1-7,3.8c-2.5-1.8-4.6-3.4-6.8-4.9c-0.7-0.5-1.7-1-2.5-1c-14.1-0.5-28.1-0.7-42.2-1.3c-14.6-0.7-29.2-1.8-43.9-2.7
      c-0.5,0-1-0.2-1.7-0.3C618.7,331.2,620.2,310.3,621.7,289.2"
          />
        </g>
        <g id="Minneapolis_Section_4">
          <path
            className="st5"
            d="M614,397.5c1-14.5,2.1-28.8,3.1-43.4c3.6,0.2,7.1,0.5,10.5,0.7c17.8,1,35.7,2.2,53.5,3
      c7.7,0.4,15.4,0.3,23.2,0.5c0.8,0,1.7,0.4,2.4,0.8c2.6,1.8,5.1,3.6,7.6,5.4c0.2-0.1,0.4-0.1,0.5-0.2c2-4.5,6-4.2,9.8-4.1
      c0.5,0,1.3,0.5,1.5,0.9c0.5,1.5,1.7,2,3,2.1c4.3,0.4,7.6,2.6,10.6,5.6c0.4,0.4,0.7,1.1,0.6,1.6c-0.7,3.1,0.7,5.5,2.1,7.9
      c0.1,0.2,0.2,0.4,0.4,0.5c2.7,2.5,3.4,5.3,1.8,8.7c-0.3,0.7,0.1,2.2,0.8,2.7c1.9,1.7,2.9,3.5,2.6,6.1c-0.1,1,0.3,2.2,0.6,3.2
      c0.8,2.8,1.5,5.6,1,8.6c-0.1,0.5,0.2,1.6,0.5,1.7c1.6,0.5,1.4,1.6,1.4,2.9c0.1,1.1,0.8,2.4,1.6,3.2c1.8,1.9,3.9,3.6,3.4,6.6
      c-8.9,2.1-100.6,1-109.8-1.5c0.3-7.1,0.7-14.3,1-21.7C636.3,398.8,625.3,398.2,614,397.5"
          />
        </g>
        <g id="Minneapolis_Section_3">
          <path
            className="st5"
            d="M825.9,347.9c-2.6,3.9-2.2,9.9,1,13.1c0.6,0.6,2,0.6,3,0.7c3.1,0.2,3,0.2,3.4,3.2c0.1,0.9,0.7,1.8,1.4,2.4
      c2.4,2.2,4.9,4.2,7.5,6.2c2,1.5,1.8,3.5,0.2,4.5c-3.2,2.2-2.6,5.4-3,8.6c-3.7,0.3-7.5,0.7-11.4,1c0.8,3.3-0.5,7,2.6,9.6
      c0.3,0.3,0.3,1.2,0.1,1.7c-0.5,1.8-1.1,3.6-1.9,5.3c-0.4,0.8-1.4,1.5-2.3,1.7c-2.2,0.6-2.9,1.8-2.4,3.9c0.1,0.7,0,1.4,0,2.4
      c-1.8-1.4-3.3-2.6-4.9-3.7c-0.8-0.5-1.8-0.8-2.8-0.8c-12.2,0.6-24.3,1.4-36.5,1.9c-7.4,0.3-14.9,0.2-22.3,0.4
      c-2.4,0-4.8,0.1-5.9-1.2c-0.8-4.2-1.5-7.6-2.1-11.1c-0.1-0.6-0.3-1.2-0.2-1.7c0.4-2.8-0.4-4.9-2.6-6.7c-0.5-0.4-0.7-1.9-0.4-2.6
      c1.5-3.1,0.9-5.6-1.5-8c-3.1-3.2-4.2-6.9-2.2-11.1c-4.8-1.6-5.3-7.1-1.5-13.9c-2.6-1.7-4.1-3.9-3.1-7.3c3.5,0,7.1,0,10.6,0
      c13.1-0.2,26.2-0.2,39.3-0.5c11.1-0.3,22.1-1.2,33.2-1.7c0.7,0,1.6,0.4,2.2,0.9C824.3,345.9,825,346.9,825.9,347.9"
          />
        </g>
        <g id="Minneapolis_Section_2">
          <path
            className="st5"
            d="M728.1,224.1H757c0.4-2.9,0.7-5.5,1-8c2-0.5,3.1,0,3.5,2.1c0.7,3.3,1.8,6.5,2.6,9.7c0.3,1.2,0.8,1.7,2,1.9
      c3.5,0.7,7,1.8,10.5,2.1c3.4,0.2,6.9-0.2,10.2-0.9c4-0.9,6.8,0,9,3.5c1.4,2.2,3.4,4,5.2,6.2c1.1-1.3,2-2.4,3.1-3.8
      c3.3,1.7,6.6,3.3,9.8,5.1c1.3,0.7,2.3,0.7,3.3-0.3c3.5-3.8,7.6-4.7,12.5-3.1c1.7,0.6,3.6,0.7,5.4,0.9c1.7,0.2,3.4,0.4,5.5,0.6
      c-2.5,2.5-4.1,5.6-7.7,6.4c-5.5,1.2-9.7,4.5-13.4,8.7c-5.1,5.7-10.7,11-16.1,16.4c-1.2,1.2-1.5,2.3-1.3,3.8
      c0.5,3.6,0.7,7.3,1.1,10.9c0.2,1.4-0.3,2-1.6,2.3c-1.1,0.3-2.2,0.9-3.3,1c-2.7,0.4-3.7,2.1-4.3,4.6c-0.5,2.1-0.4,3.5,1.5,4.6
      c1.9,1.1,1.6,2.4,1.1,4.3c-0.6,2.5-0.1,5.3-0.2,7.9c-0.1,3.4,1.1,6,4.2,7.9c2.1,1.3,3.5,3.6,5.4,5.3c0.7,0.6,1.9,1,2.8,1
      c2.1-0.2,3.5,0.4,4,2.6c0.4,1.8,2,2.3,3.4,2.8c3.5,1,5.1,3.2,5.1,6.8c0,1.6,0.5,3.3,0.8,5.1c-27.2,2.1-54.4,2.6-81.7,2.3
      c0-3.9,0-7.7,0-11.5c0.1-8.8,0.2-17.6,0.4-26.5c0-1.4-0.4-2.1-1.6-2.7c-2-1-4.1-1.9-5-4.3c-0.4-1.2-0.5-1.9,0.6-2.5
      c2.1-1.2,3.2-2.8,3.1-5.3c0-1.2,0.2-2.5,0.5-3.7c0.6-3,0.7-5.7-1.9-7.9c-0.6-0.5-0.9-1.5-1-2.3c-1.5-6.7-2.1-13.4-1.6-20.2
      c0.4-4.7-1.5-8.8-2.9-13.1c-0.9-2.8-1.5-5.6-2.1-8.4c-0.1-0.3-0.2-0.8,0-1.1c2.1-3.3,0.3-6.5-0.5-9.6c-0.1-0.3-0.1-0.6-0.2-0.9
      C727.8,224.8,727.9,224.7,728.1,224.1"
          />
        </g>
        <g id="Minneapolis_Section_1">
          <path
            className="st5"
            d="M626.6,221c9.5,0.6,18.7,1.2,27.8,1.6c11.4,0.5,22.7,1,34.1,1.3c11.8,0.3,23.6,0.3,35.4,0.3
      c1.3,0,2,0.3,2.3,1.7c0.3,1.6,0.9,3.1,1.3,4.6c0.1,0.5,0.3,1.2,0.1,1.7c-2,4-0.6,7.9,0.8,11.4c2.7,6.7,3.6,13.5,3.5,20.7
      c-0.1,5,1,10.1,1.7,15.1c0.2,1.2,1.3,2.2,2,3.3c1,1.4,1.3,6.2,0.3,7.5c-0.3,0.4-1.1,0.6-1.7,0.6c-10,0.2-20,0.5-30,0.4
      c-10.9-0.1-21.9-0.4-32.8-1c-16.2-0.8-32.3-1.8-48.5-2.8c-0.3,0-0.6-0.1-1.2-0.2C623.4,265.2,625,243.2,626.6,221"
          />
        </g>
      </g>
      <g id="Mid_West_Region" data-name="Midwest" onClick={handleMapClick}>
        <g id="Mid_West_Section_6">
          <path
            className="st6"
            d="M678.9,548.6c-0.3-0.6-0.9-1.1-1.5-1.4c-0.2-0.1-0.3-0.1-0.5-0.2c-1.5-0.5-1.6-1.5-1.6-2.8
      c0.3-12.6,0.6-25.2,0.9-37.8c0.1-2.7,0.1-2.7-2.7-2.9c-1.4-0.1-2.9-0.2-4.3-0.2c-11.6-0.6-23.2-1.2-34.8-1.9
      c-3.7-0.2-7.3-0.3-11.1-0.5c-0.8,13.3-1.6,26.6-2.4,39.9l6,5.9l42.3,0.5v14.6h8.2v7.7h8.7v-17.6
      C683.3,551.6,680.5,551.7,678.9,548.6"
          />
        </g>
        <g id="Mid_West_Section_5">
          <path
            className="st6"
            d="M768.2,545.2v-17.8l2.6,1.8l2.5-24.4c-0.3-1.8-0.5-3.7-0.8-5.5c-0.3-2.2-0.1-4.4-0.1-6.8
      c-1.5,0.1-2.9,0.2-4.3,0.2c-47.8,2.4-95.1,0.6-142.6-2.7c-0.3,3.1-0.5,6-0.8,9.1c17.9,1,35.4,1.9,53,2.8c0,1,0,1.8,0,2.6
      c-0.3,11.8-0.5,23.6-0.9,35.4c-0.1,2.9-0.2,5.6,3,7.1c0.1,0.1,0.2,0.2,0.3,0.3c1.3,2.7,3.7,2.6,6.2,2.7c0.2-1.6,0.5-3.1,0.8-5.2
      c1.9,2.7,4.7,4.2,4.3,7.8c2.5,0.3,5.1-0.5,6.9,1.7c0.1,0.1,0.5,0,0.8-0.1c1.8-0.5,3.2,0.1,4.3,1.7c0.2,0.4,0.7,0.6,1.1,0.9
      c2.4-3.7,5.5-0.9,8.5-1.4v5.4c3.5,2.1,3.5-1.7,5.1-2.9c1.6,1.4,3.2,2.8,4.8,4.2c1.2-0.8,2.4-1.6,3.6-2.4c0.6,1.4,1.1,2.6,1.7,4.1
      c1.2-2.3,2.3-4.3,3.6-6.6c0.9,1.8,1.6,3.4,2.4,4.9c0.6-0.2,1-0.2,1.4-0.4c1.7-0.9,3.1-0.6,4.5,0.7c1.2,1.1,2.5,1.9,3.8,2.9
      c2-2.1,3.8-4.1,5.7-6.1c1.2,1.1,2.3,2.1,3.4,3.1c1.6-1.4,3.2-2.7,4.7-4.1c0.9,0.9,1.5,1.7,2.3,2.5c1.1-1.2,2.2-2.3,3.2-3.4
      c1.6,1.3,3.1,2.5,4.6,3.8c1,0.9,2.1,1.8,3.1,2.7c1.5,1.3,3,1.9,5.2,1.5v-2.3c0-5.5,0-10.9,0-16.4L768.2,545.2z"
          />
        </g>
        <g id="Mid_West_Section_4">
          <g>
            <path
              className="st6"
              d="M838.2,440.5c-0.2-1.8-1-3.2-2.4-4.4c-3.7-3.2-7.4-6.4-10.9-9.8c-0.8-0.7-1.3-1.9-1.6-2.9
        c-0.5-2-0.7-4-0.9-6c-0.1-1.1,0.1-2.2,0.2-3.6c-2.7-3.5-6.4-4.9-11-3.6c-0.3,0.1-0.7,0.1-1.1,0.1c-18.1,0.6-36.3,1.2-54.4,1.8
        c-1,0-2,0.3-3.1,0.5c0.6,1,1,2.1,1.8,3c1.3,1.5,3.1,2.7,3.1,5.1c-0.1,2.3,1.6,3.5,3,4.9c1.8,1.9,3.7,1.2,5.6,0.7
        c0.1,0,0.3,0.1,0.5,0.1c0.6,1.2,1.3,2.5,2,4c-1.7,1.5-3.4,3-5.2,4.6c1.8,1.9,3.4,3.5,4.8,5.2c1.1,1.3,2.2,2.3,4.3,1.3
        c0.5,20.1,1,39.9,1.4,59.4c1.1,0,2.3,0,3.4,0c5,0,9.9,0.1,14.7-0.1c1.9-0.1,3.9-0.1,5.8-0.2l4.3-22.1l36.4-32.9
        C838.7,443.8,838.4,442.2,838.2,440.5"
            />
            <path
              className="st6"
              d="M792.4,502.5c-2,0.1-4.1,0.2-6.2,0.2c-3.8,0.1-7.7,0.2-11.7,0.4c0.1,0.7,0.2,1.4,0.3,2.1l11.5,3.2l0.2,10.4
        l8,0.4l3.2-17C796.1,502.4,794.3,502.5,792.4,502.5"
            />
          </g>
        </g>
        <g id="Mid_West_Section_3">
          <path
            className="st7"
            d="M761.3,434.9c3.3,3.3,5.9,6.8,10.3,10.2c0,3.5-0.1,8.1,0,12.6c0.2,10.2,0.4,20.4,0.8,30.6
      c0.1,1.9-0.5,2.5-2.5,2.5c-18.3,0.3-36.6,1.2-54.9,1c-22.1-0.3-44.3-1.4-66.4-2.2c-1.8-0.1-3.6-0.2-5.6-0.3
      c1-22.1,2.1-43.9,3.1-66c3.8,0.2,7.4,0.3,11,0.5c25.8,1.5,51.6,1.9,77.4,1.6c6.4-0.1,12.8-0.4,19.2-0.8c2.4-0.1,4.4,0.3,6.1,2.1
      c1.4,1.4,2.9,2.7,5.2,1.5c0.3-0.1,1.2,0.3,1.3,0.6c0.2,0.4,0,1.1-0.3,1.5C764.6,431.9,763,433.3,761.3,434.9"
          />
        </g>
        <g id="Mid_West_Section_2">
          <g>
            <path className="st6" d="M607,387.9v9c-4.3-0.2-10.8-0.8-18.6-1.6v-7.4L607,387.9z" />
            <path
              className="st6"
              d="M645.5,401.4c-1.4,29.2-2.8,58.3-4.2,87.7c-3.8-0.2-7.4-0.3-10.9-0.6c-24.2-2.1-48.4-4.2-72.5-6.4
        c-12.3-1.1-24.5-2.7-36.7-4c-1.7-0.2-1.8-0.9-1.6-2.3c1.6-13,3.2-26,4.8-39.1c1.9-15.3,3.8-30.7,5.7-46.2
        c19.2,2.1,38.3,4.5,57.5,6.3C606.8,398.8,626,400,645.5,401.4"
            />
          </g>
        </g>
        <g id="Mid_West_Section_1">
          <g>
            <path
              className="st6"
              d="M576.4,598.6c-8.9-0.9-17.7-1.7-26.6-2.6c-0.1,1.3-0.2,2.3-0.3,3.5c3.3-0.1,5.6,1.3,6.8,4.2
        c0.5,1.2,0.8,2.5,1.3,3.7c0.2,0.5,0.4,1.2,0.8,1.5c5.1,2.8,8.2,7.5,11.6,11.9c0.8,1.1,2,2.1,3.2,2.7c1.1,0.6,2.2,1.2,3.2,2V609
        h17.7l1.2-8.5C589,599.9,582.7,599.2,576.4,598.6"
            />
            <path
              className="st6"
              d="M519.1,480.1c35,3.4,69.7,6.8,104.7,10.2c-0.2,2.4,0.5,5.6-0.8,7.7c-2.4,3.9-1.5,8-1.9,11.9
        c-0.5,4.1-0.6,8.3-0.9,12.4c-1,16.2-2,32.3-2.9,48.5c-0.5,8.3-1,16.6-1.5,24.9c-0.1,1.6-0.2,3.2-0.4,5
        c-22.4-2.2-44.6-4.4-67.1-6.6c-0.3,1.7-0.5,3.3-0.8,5.1c-9.4-1.1-18.5-2.1-28-3.2c-0.6,3.3-1.2,6.5-1.8,10
        c-4.7-0.6-9.2-1.2-13.9-1.8C508.9,562.7,514,521.4,519.1,480.1"
            />
          </g>
        </g>
      </g>
      <g id="Texas_Region" data-name="Texas" onClick={handleMapClick}>
        <g>
          <path
            className="st8"
            d="M774.3,654.3c1.2,3.2-0.3,5.4-2.2,7.7c-1.1-1.7-2.1-3.3-3.3-5.2C770.7,656,772.4,655.2,774.3,654.3"
          />
          <path
            className="st8"
            d="M743.5,679.2c1.2,0.4,2.2,0.7,3.5,1.1c-1,1.7-1.8,3.3-2.9,5.1c-1.2-1.3-2.2-2.4-3.2-3.5
      c0.1-0.2,0.2-0.3,0.4-0.5c0.5,0.3,0.9,0.6,1.6,1C743,681.4,743.2,680.5,743.5,679.2"
          />
          <path
            className="st8"
            d="M735.8,690.4c-0.3-2-0.5-3.5-0.7-5C737.5,686.3,737.5,686.4,735.8,690.4"
          />
          <path
            className="st9"
            d="M793.8,632.9c2-6.2,3.7-12.5-2-17.9c-0.2-0.1-0.3-0.3-0.4-0.5c-0.2-1-0.7-2-0.6-3c0.1-1.3,0-2.2-1.1-3
      c-1-0.8-1.8-1.8-2.7-2.5c-1.3-1-1.7-2.1-1.7-3.7c0.1-10.4,0.1-20.7,0-31.1c0-0.7-0.6-1.7-1.2-2.1c-0.9-0.7-2-0.9-2.8,0.4
      c-0.4,0.6-1,1-1.7,1.6c-1.5-2.8-3.8-3.9-6.9-3.8c-0.5,0-1.1-0.6-1.6-1c-2.4-2-4.8-4-7.2-6.1c-1.2,1.3-2.3,2.5-3.4,3.7
      c-0.9-0.9-1.6-1.7-2.5-2.7c-1.6,1.4-3.1,2.7-5,4.4c-0.9-1.3-1.7-2.4-2.6-3.7c-2.2,2.3-4,4.2-5.8,6.2c-1.2-0.9-2.3-1.6-3.2-2.4
      c-1.9-1.8-3.9-2.9-6.5-1.4c-0.3,0.2-1.3-0.4-1.8-0.9c-0.4-0.4-0.5-1-1-1.8c-1.3,2.3-2.4,4.5-3.8,6.9c-0.9-2.1-1.5-3.9-2.2-5.7
      c-1.1,0.7-2,1.2-3,1.8c-1.4-1.3-2.8-2.4-4.4-3.9c-1.2,4.8-4.4,2-7.1,2.3v-5.3c-3.2-1.5-5.2-0.6-6.5,2.7c-0.9-1-1.5-1.5-1.9-2.2
      c-1.2-1.6-2.4-2.6-4.6-1.9c-0.4,0.1-1.3-0.1-1.5-0.4c-0.8-1.4-2.1-1.3-3.4-1.3c-1.2,0-2.4,0-3.5,0c-0.7-1.7,0.5-3.7-1.5-5
      c-0.2,1-0.3,1.7-0.5,2.7c-0.7-0.2-1.4-0.3-2.1-0.4v17.6h-8.7v-7.7h-8.2v-14.6l-42.3-0.5l-6-5.9c-0.1,1.9-0.2,3.8-0.4,5.8
      c-0.3,5.1-0.6,10.2-0.9,15.2c-0.2,2.6-0.3,5.2-0.5,7.7c-0.7,11-1.3,22.1-2,33.1c-7.3-0.7-14.6-1.4-21.9-2.1l-1.2,8.5h-17.7v16.5
      c1.2,0.9,2.3,2.1,3,3.6c1.1,2.3,2.2,4.6,3.7,6.6c1.1,1.5,1.3,2.9,0.8,4.6c-0.5,1.5-1,3.2-0.6,4.6c1,3.2,2.3,6.3,4.3,8.9
      c1.3,1.6,2.9,3,4.8,4.2c0.3,0.2,0.6,0.5,0.9,0.7c1.3,1,2.4,2.6,4.6,1.7c0.3-0.2,1,0.3,1.5,0.6c3.1,2,6.1,4,9.2,5.9
      c0.5,0.3,1,0.7,1.5,0.7c1.2,0.1,5.7-3.2,6.1-4.4c1.2-3.4,2.4-6.9,3.5-10.3c0.4-1.2,0.9-1.8,2.1-1.6c2.5,0.4,4.5-0.6,6.5-2
      c0.5-0.3,1.1-0.6,1.7-0.6c4.6,0.3,9.2,0.6,13.7,1c0.8,0.1,1.7,0.5,2.2,1.1c4.1,5.3,9,9.7,14,14.1c0.7,0.7,1.3,2,1.3,2.9
      c-0.3,2.7,0.5,4.9,2.1,7c1.4,1.9,3.1,4.1,3.2,6.2c0.2,3.8,1.8,6.7,4.1,9.5c1.5,1.8,3,3.8,4.3,5.8c1.3,2,2.9,3.4,5.4,3.5
      c1.9,0.1,2.4,1.2,2.2,2.9c-0.7,5.9,0.5,11.4,3.4,16.5c0.2,0.4,0.4,0.8,0.7,1.2c0.2,0.4,0.4,0.8,0.5,1.2c1.3,4.1,3.8,6.8,8,8.1
      c1.9,0.6,3.6,1.6,5.3,2.5c3.3,1.8,6.8,3.2,10.6,3.2c4.3,0,7.9,1.5,11.1,4.3c0.4,0.3,0.9,0.5,1.1,0.6c1.1-0.8,2.1-1.5,2.6-1.9
      c-1.8-4.9-3.4-9.7-5.3-14.4c-1.5-3.7-2.2-7.1,0.3-10.6c0.1-0.1,0-0.3,0-0.6c-1.7-0.7-3.4-1.4-5.2-2.1c-0.1-2-0.2-3.9-0.4-6.2
      c2.3,1.1,4.3,2.1,6.5,3.1c0.5-1.5,0.8-2.9,1.3-4.1c0.8-1.6,0.4-2.9-0.7-4.2c-0.9-1.1-1.7-2.4-2.8-4c2,0.3,3.5,0.4,4.9,0.7
      c2.1,0.5,3.3-0.2,4-2.2c-0.9-1.3-1.9-2.6-3.1-4.2c2.7-0.3,5-0.5,7.5-0.7v2.8c0.2,0,0.4,0,0.6,0c0.6-3.6-0.9-7.1-0.8-11.1
      c2.5,2,4.7,3.9,7.1,5.8c0.3-0.3,0.8-0.7,1-0.9c-0.7-1.6-1.5-3-2-4.5c-0.3-1-0.3-2.1-0.4-3.2c1,0.2,2,0.4,3.1,0.7
      c0.1-0.4,0.4-1,0.6-1.7c2.5,0.9,4.8,1.7,7.1,2.5c-0.1,0.6-0.2,1.1-0.3,1.8c5.3-2.9,10.4-5.8,15.5-8.6c0.4-0.2,0.9-0.6,0.9-0.9
      c0.2-3.2,2.8-5.2,4.2-7.8c0.3-0.5,0.4-1.5,0.1-2.1c-1-1.9-2.2-3.7-3.4-5.7c3.3-1.5,6.5-3,9.9-4.6c-0.1,3.8,1.2,7.3-1.8,10.4
      c5.2-2.6,10.1-5,15.1-7.5c0.1-2-0.5-4.4,1.8-6.1c0.7-0.5,1.2-1.3,1.8-1.9c1.2-1,1.4-2,0.7-3.4
      C792.4,639.6,792.7,636.3,793.8,632.9"
          />
        </g>
      </g>
      <g id="Mid_South_Region" data-name="MidSouth" onClick={handleMapClick}>
        <g id="Mid_South_Section_9">
          <path
            className="st10"
            d="M978.2,481.6c-27.9,4.4-60.8,8-89,9.3v4.7c-1.4,0.1-2.7,0.3-4,0.4c-4.4,0.4-8.8,0.7-13.2,1.1
      c-1.4,0.1-1.8,0.7-3.2,0.8l3.1,10.5l5.9,16.5l-8.2,0.8l-11.9,1.1c0.8,1.1,0.2,0.9,0.9,2c-0.9,1.1-2.7,3-3.8,4.3
      c4.1-0.4,8.3-0.7,12.4-1.1c3-0.3,6-0.6,9-0.9c25.7-2.5,58.2-5.4,83.7-9.1l1.1-7.2c0.9,0,1.6,0,2.4,0c1.7-0.1,2.8-1.7,2.6-3.6
      c-0.2-1.3-0.7-3.3-0.2-3.8c1.1-1,2.9-1.9,4.2-1.6c3.1,0.8,5.6,0.3,7.6-2.5c0.5-0.7,1.4-1.2,2-2c0.7-0.9,1.6-2,1.7-3.1
      c0.2-2-0.7-2.9,0.5-3.9L978.2,481.6z"
          />
        </g>
        <g id="Mid_South_Section_8">
          <path
            className="st11"
            d="M891.4,611.7L873,636.3c-0.1-3-0.9-4.4-3.4-6.3c-1.1-0.8-1.3-1.8-0.8-3c0.7-2,1.3-4.1,2-6.5
      c-5.2,0.3-10.1,0.6-15.1,1c-6.6,0.4-13.2,0.9-19.9,1.2c-0.6,0-1.4-0.6-1.8-1.2c-0.5-0.7-0.6-1.6-0.9-2.6c1.2-0.4,2.3-0.8,3.3-1.1
      c0-0.3,0.1-0.5,0-0.7c-1.9-5.4,0.9-9.5,3.6-13.7c1.3-2,2.7-3.9,4.1-6c-0.5-0.2-0.9-0.4-1.5-0.7c1.4-1,2.7-1.9,4.4-3
      c-1.8-2.1-3.6-4-5.3-6.1c-0.3-0.3-0.5-0.9-0.5-1.3c-0.2-1.4-0.1-2.8-0.4-4.1c-0.2-0.9-0.7-1.8-1.5-2.6c0.1-0.3,0.2-0.6,0.3-0.8
      c3-3.3,2.7-6.7,0.4-10.2c-0.6-0.9-1-2-1.3-3c-0.1-0.5-0.1-1.3,0.2-1.5c2.1-1.8,2.3-4.3,2.5-6.8c2.3,0.1,2.5-1.3,2.3-3
      c-0.1-0.8,0-1.6,0-2.4c0.2-2.6,1.7-4.1,5.2-4.6c0.1-2.6,0.4-5.3,0.3-8c-0.1-2.1,0.4-3.2,2.7-2.9c0.5,0.1,1.2-0.1,1.9-0.2
      c-0.1-2.5,1.6-2.4,3.5-2.6c10.8-0.9,21.6-1.9,32.4-2.8c0.4,0,0.9,0,1.5,0c0,3.8,0,7.4,0,11.1c-0.2,15.1-0.4,30.2-0.6,45.3
      c0,2.5-0.1,5.1-0.2,7.6c-0.3,6.1,0.4,10.4,1.5,16.4"
          />
        </g>
        <g id="Mid_South_Section_7">
          <g>
            <path
              className="st11"
              d="M871.8,653.8c4.7,2.2,8.8,4.1,13.1,6.2c-0.5,1.5-0.9,3-1.3,4.5c-1.1-0.6-2-1.1-3-1.6
        c-0.6-0.6-1.1-1.4-1.9-1.8c-4.6-2.3-9.3-4.5-14-6.7c-0.6-0.3-1.4,0-2,0.1c0.2,0.6,0.4,1.2,0.6,1.8c0,0.1,0.2,0.2,0.3,0.3
        c2.8,3.5,2.8,3.6-0.7,6.9c-1.2-3.5-1.3-3.5-6.1-3.6c-1.2,0-1.2,0-2.8,3.6c-3.4-0.6-6.9-1.2-10.6-1.8c0.6-1.8,1.6-3.2,0.4-5
        c-0.9-1.3-1.5-2-3-1.2c-1.3,0.7-2.4,0.8-3.5-0.8c-3.5-5.1-6.6-5.7-12-2.8c-0.5,0.3-1,0.6-1.7,1c0.8,1.3,1.6,2.4,2.6,3.9
        c-1.3,0.2-2.3,0.5-3.3,0.5c-5.8-0.4-11.1-2.2-16.2-4.9c-0.5-0.3-1-1.1-1-1.7c0-2.1-1.1-3.1-3-3.6c-0.9-0.2-1.8-0.6-2.9-1
        c0.3,2.1,0.5,4.1,0.8,6.2c-3,0.7-6,1.4-8.9,2.2c-0.5-2-0.4-3.5,1.1-4.8c0.4-0.4,0.7-0.9,1.2-1.3c2.5-1.9,2.7-3.9,1.5-6.9
        c-0.8-1.9-0.5-4.6-0.2-6.8c0.7-4.2,1.8-8.2,2.6-12.4c0.1-0.7-0.3-1.5-0.7-2.2c-1-1.6-2-3.2-3.2-4.7c-1.2-1.5-1.7-3.1-1.4-5
        c0.1-0.9-0.5-2.2-1.2-2.7c-3.6-2.4-4.7-5.6-4.3-9.9c0.4-5,0.1-10,0.1-15.3c1.8-0.1,3.5-0.3,5.1-0.4c14.1-0.6,28.1-1.3,42.2-1.9
        c2.9-0.1,4.7,1.5,5,4.4c0.1,0.8,0,1.6,0.2,2.4c0.3,0.8,0.7,1.5,1.3,2.2c1.1,1.4,2.3,2.7,3.6,4.1c-2.8,1.8-5.3,3.5-8.3,5.4
        c1.8,0.1,3.1,0.2,4.5,0.2c-1.8,3.1-3.4,6.1-5.2,9c-1.2,2-1.8,3.9-1.1,6.2c0.2,0.7,0.2,1.4,0.4,2.3c-1.2,0.4-2.5,0.8-3.8,1.2
        c0.7,2.1,1.3,4.1,2.1,6.1c0.2,0.5,1.2,1,1.8,0.9c9.2-0.5,18.3-1.2,27.5-1.8c1.9-0.1,3.8-0.2,6.1-0.4c-0.6,1.8-0.9,3.4-1.6,4.9
        c-0.6,1.5-0.3,2.5,0.9,3.4c0.7,0.6,1.3,1.4,2,2c2,1.5,2.3,3.5,2.1,6.2c-3-1-6-1.8-8.8-3c-2-0.9-3.1-0.4-4.2,1.5
        c-1.3,2.3-2.8,4.4-4.5,6.9c3,0.7,5.7,1.4,8.4,1.9c0.6,0.1,1.4-0.6,2-1c1.7-1.1,3.3-2.3,5-3.5c0.2,0.2,0.4,0.4,0.6,0.6
        c-0.7,1.2-1.4,2.4-2.2,3.9c1.8,0.5,3.4,1.3,5,1.3c0.9,0,1.8-1.4,2.8-2.3c0.6,0.9,1.3,2.1,1.9,3
        C875.8,649.9,874,651.7,871.8,653.8"
            />
            <path
              className="st11"
              d="M857.4,642.6c1-1.5,2-3.1,3.1-4.6c0.3-0.4,1-0.7,1.4-0.6c2.2,0.7,4.3,1.4,6.7,2.3
        C865.2,642.6,862.3,645.9,857.4,642.6"
            />
            <path
              className="st11"
              d="M871,644.8c0.6-1.2,1.2-2.4,1.9-3.8c0.4,0,1.2,0.1,1.9,0.1C873.7,645.3,873.2,645.8,871,644.8"
            />
          </g>
        </g>
        <g id="Mid_South_Section_6">
          <g>
            <path
              className="st11"
              d="M776.5,533l-5.6-3.8l2.5-24.4c0.8,5.5,1.6,11,2.4,16.5c0.3,2.3,0.6,4.6,0.7,6.9
        C776.5,529.8,776.5,531.4,776.5,533"
            />
            <path
              className="st11"
              d="M776.5,546.9l-8.3-1.7v-17.8l8.3,5.6C776.5,537.7,776.5,542.3,776.5,546.9"
            />
            <path
              className="st10"
              d="M858.9,514.3c-0.5,0.2-0.9,0.5-1.3,0.7c-2,0.7-3,2-2.5,4.1c0.6,2.5-1.2,4-2.6,5.7c0.9,1.3,1.7,2.5,2.3,3.3
        c-1.1,2.2-2,4.1-3,6.3c-1,0.1-2.2,0.3-3.7,0.5c-0.1,0-0.3,0.1-0.5,0.1c-0.2,3.5-0.4,7-0.5,10.4c-3.2,0.5-5.3,3.3-5.3,6.9
        c0,1.5,0.7,3.5-2.2,3.3c-0.2,3.1-0.4,6-2.9,8c-0.3,0.3-0.2,1.3,0,1.9c0.9,2.3,2,4.5,3,6.8c1,2.2-1.1,5.8-3.5,5.9
        c-15.8,0.7-31.6,1.4-47.4,2.1c-0.4,0-0.9,0-1.6,0c0-3,0.1-6-0.1-9c-0.1-1.2-0.2-3.2-0.9-3.6c-2-0.9-4.3-2.9-6.3,0.4
        c-0.2,0-0.4-0.1-0.6-0.1c-0.3-1.2-0.9-2.4-1-3.7c-0.1-9.7-0.3-19.4,0-29.1c0-0.1,0-0.3,0-0.4c0-0.3,0-0.5,0-0.8
        c0.2-9-1.5-17.7-3-26.5c-0.1-0.8-0.3-1.6-0.4-2.4l11.5,3.2l0.2,10.4l8,0.4l3.2-17c16.5-0.6,33-1.2,49.6-1.9
        c2.7-0.1,4.6,0.5,6.3,3.4c-2,1.7-4.1,3.5-6.2,5.3c4.1,4.1,8.4,2.8,12.8,1.6C860.8,512.5,860.5,513.6,858.9,514.3"
            />
          </g>
        </g>
        <g id="Mid_South_Section_5">
          <path
            className="st11"
            d="M870.5,508.5l-3.3-10.2c-0.7,0-1.4,0.1-2.1,0.1c-1,0.1-1.9,0.1-2,1.4c-0.2,2.6-0.3,5.2-0.4,7.8
      c-0.2,3.7,0.2,7.6-4.4,9.1c-0.6,0.2-1.4,1.2-1.4,1.8c0,2.5,0,4.8-2.2,6.4c0.2,0.2,0.4,0.5,0.5,0.7l13.3-1.1l6.9-0.6L870.5,508.5z"
          />
        </g>
        <g id="Mid_South_Section_4">
          <path
            className="st11"
            d="M868.9,493.8c-0.6,0.2-1.1,0.3-1.6,0.4c-3.8,0.6-5.5,2.4-6,6.4c-0.2,2.3-0.4,4.6-0.4,6.9
      c0,1.1-0.3,1.6-1.4,1.8c-1.8,0.3-3.6,0.9-5.4,1c-1.2,0.1-2.4-0.5-4.3-1c2.5-2.2,4.5-3.9,7-6c-1.9-1.6-3.3-2.9-4.8-4
      c-0.5-0.4-1.3-0.5-2-0.5c-17.3,0.6-34.6,1.3-51.9,1.9l4.3-22.1l36.4-32.9c0.1,0.4,0.2,0.9,0.2,1.4c3-0.4,6.1-3.5,9.2,0.2
      c-1.3,2.4-2.7,4.9-3.9,7.6c-0.4,0.9-0.3,2.2,0,3.1c1.1,3.1,3.5,5.2,6.2,7.1c3.2,2.2,6.1,4.9,9.1,7.5c0.5,0.4,1,1.1,1.3,1.7
      c1.5,3.3,1.5,3.3-0.6,6.7c2,2.3,4.1,4.5,6.3,6.9c0.6-0.6,1.1-1.1,1.7-1.8C870.5,488.5,868.8,491.1,868.9,493.8"
          />
        </g>
        <g id="Mid_South_Section_3">
          <path
            className="st11"
            d="M857.5,447.4l0.4-4.5l-5-0.5l-0.5,4.6l-1.9-0.2c-1.4,2.7-2.8,5.3-4.1,7.9c-0.3,0.6-0.8,1.6-0.5,2
      c1,1.8,2.1,3.7,3.6,5.1c3.6,3.2,7.8,5.9,11.3,9.3c1.6,1.6,2.5,4.2,3.3,6.5c0.3,0.9-0.7,2.2-1.1,3.5c1.1,1.2,2.4,2.5,3.8,4
      c1.8-2.2,3.3-4.2,4.9-6.2c2.4,1.2,4.7,2.2,6.9,3.5c1.5,0.8,2.4,0.5,3.1-1.1c-1.2-1.2-2.3-2.5-3.7-3.9c2.9-1.6,5.6-3.1,8.3-4.7
      c-0.9-1-1.6-1.8-2.4-2.6c0.8-1,1.5-1.8,2.2-2.8c-0.2-0.4-0.6-0.7-0.6-1c-0.1-2.9-0.1-5.8,0-8.8c0-0.4,0.6-1,1-1.2
      c1.7-0.6,2.4-1.9,3-3.5c0.2-0.7,0.5-1.3,0.8-2L857.5,447.4z"
          />
        </g>
        <g id="Mid_South_Section_2">
          <path
            className="st10"
            d="M888,489.9c-0.1,0.3-0.2,0.5-0.2,0.7c-0.2,4-0.3,3.9-4,4.2c-5,0.3-10,0.8-15,1.2c-0.9,0.1-1.8,0-2.7,0
      c0.1-0.1,0.1-0.3,0.2-0.3c4.3-0.6,4.2-0.6,4.9-5.2c0.3-2.1,0.4-4-0.8-5.8c-1-1.6,0.6-2.3,1.3-3.2c0.3-0.4,1.5-0.3,2.1-0.1
      c2.1,0.9,4,2,6,3.1c0.1,0.1,0.3,0.1,0.5,0.2c1.2-1.2,2.4-2.5,3.9-4c-1.3-1-2.4-1.8-4-3c3.2-1.9,5.7-3.4,8.4-5
      c-1-1.1-1.8-2.1-2.8-3.2c1.4-1.7,2.6-3.4,4-4.8c0.5-0.5,1.7-0.4,2.5-0.3c1.4,0.3,2.7,0.7,3.9-0.6c0.3-0.3,1.3-0.2,1.8,0
      c2.2,1,4.4,2.1,6.9,3.3c0.4-2.1,0.7-4,1-6.3c1.9,1.2,3.7,2.3,5.6,3.5c0.8-2.5,1.6-4.9,2.6-7.7c0.6,0.7,0.9,1.4,1.4,1.7
      c0.7,0.5,1.6,1.2,2.4,1.2c5.3-0.3,4.2,0.1,4.6-4.6c0-0.5,0.1-1,0.2-1.7c0.9,0.2,1.7,0.4,2.6,0.5c0.6-3-0.8-7.3,4.4-7.4
      c-0.6-2.2-1.1-4-1.7-5.9c3.2-1.5,2.7,1.9,4,3.1c2.3-1.8,4.6-3.7,7.1-5.7c-0.7-1.9-1.4-3.8-2.3-6c2.4,0.3,4.6,0.3,6.5,0.9
      c0.8,0.3,3.7,0.2,4.2,1.2c1,2.3,2.2,4.9,4.8,4c0.7-0.2,3,0,3.7,0.4c3.2,1.8,4.8,2.5,6.7-0.3c2.8-0.4,4.9,0.5,6.7,2.1
      c0.9-1.6,1.9-4.7,2.6-3.6c2.2,3.5,4.2,4.1,6.4,4.6c0.7,0.2,2,0.3,1.9,0.9c-1.5,7.7,2.8,13.1,8.1,16.5c0.9,0.6,1.8,1,2.9,1.7
      c-2.3,3.4-4.7,5.3-8,7.4c-1,0.6-1.5,1.2-1.3,2.5c0.3,2.7-1.3,4.3-3.4,5.5c-2.5,1.5-5,3.1-7.4,4.9c-1.7,1.3-3.4,2.1-5.5,2.4
      c-14.2,1.6-33.8,3.5-48.1,4.9c-9.4,0.9-18.8,1.3-28.2,1.9C889.1,489.7,888.5,489.9,888,489.9"
          />
        </g>
        <g id="Mid_South_Section_1">
          <path
            className="st10"
            d="M924.7,448.4c-0.6,1.1-0.4,2.6-0.5,4c-0.9-0.2-1.6-0.3-2.7-0.6c-0.1,1-0.1,2-0.2,3c-0.1,0.6-0.1,1.3-0.2,1.9
      c-0.1,0.6-0.6,1.7-0.9,1.8c-1,0.1-2.4,0.1-3-0.5c-1.2-1-2.1-2.4-3.2-4c0-0.1-0.1-0.1-0.1-0.2c0,0.1,0,0.1-0.1,0.1
      c-1,2.9-1.9,5.5-2.9,8.4c-2-1.2-3.9-2.3-6-3.6c-0.4,2.1-0.7,4.1-1.1,6.3c-2-0.9-3.8-1.9-5.8-2.6c-0.6-0.3-1.7-0.2-2.2,0.2
      c-1,0.7-2,1.3-3.2,0.7c-0.3-0.2-0.7-0.3-1-0.3c0,0-0.1,0-0.1,0l0.4-0.5c-1.8-0.2-3.1,1.2-4.5,2.6c0-2.3,0-4.7,0-7
      c0-0.2,0.2-0.5,0.3-0.5c3.3-1.1,3.3-4.3,4.6-6.7c0-0.1,0.1-0.1,0.1-0.2l7,0.7l0.2-5.4l5.5-0.4l0.2,7.4l1,0.1h0l1.8,0.2l17.8-13.3
      l0.1-0.1l10.2-7.7c0.3,1.4,1.1,3.3,1.7,5.4l-5.7,4.5l-1.3-2.5c0,0-1.2-1.1-3.9,0.4c0,1.1,1.6,5.6,1.6,5.6S926.4,445.2,924.7,448.4
      z"
          />
        </g>
      </g>
      <g id="Great_Lakes_Region" data-name="GreatLakes" onClick={handleMapClick}>
        <g id="Great_Lakes_Section_6">
          <path
            className="st12"
            d="M952.9,370.5l-14.8,58c-2.1-18-4.2-36.1-6.4-54.3c5.2-1,10.3-1.7,15.2-3
      C948.9,370.6,950.9,370.4,952.9,370.5"
          />
        </g>
        <g id="Great_Lakes_Section_5">
          <path
            className="st12"
            d="M936.5,432.3c0-0.2,0-0.8,0-1c-1.7-14.3-3.3-28.7-4.9-43c-0.6-5-1.2-10-1.8-15c-0.7,0-1.2-0.1-1.7,0
      c-9.3,1.2-18.7,2.4-28,3.6c-1.8,0.2-3.3,0.7-4.5,2.2c-2,2.6-4.5,2.4-7.4,1.3c1.8,17.6,3.6,35,5.4,52.2c-0.8,0.5-1.8,0.9-1.8,1.3
      c-0.1,1.6-0.4,3.8,0.4,4.9c2,2.5,2.5,4.8,2,7.2c-0.3,1.4-0.9,2.7-1.7,4.1c-0.2,0.4-0.3,0.8-0.5,1.2l7.9,0.9l0.3-5.6l4.2,0l0.2,6.7
      l2.1,0.1h0l1.9,0.2l12.3-9.5l5.7-4.3c0-0.1,0.9-0.6,0.9-0.6"
          />
        </g>
        <g id="Great_Lakes_Section_4">
          <path
            className="st12"
            d="M891.8,440.7c-2.2-2.5-1.4-5.5-1.5-8.4c0-0.3,0.3-0.8,0.5-0.8c1.5-0.3,1.2-1.4,1.1-2.4
      c-1.6-15.3-3.2-30.5-4.9-45.8c-0.2-1.7-0.1-3.5-0.8-4.9c-2.6-5.2-6-10-6.3-16.2c-14.8,1.1-29.4,2.1-44.1,3.2
      c3,2.8,5.8,5.5,8.7,8.1c1.4,1.2,1.6,2.5,0.5,3.9c-2.5,3.1-4.2,6.4-3.9,10.5c0,0.1-0.1,0.2-0.2,0.5c-3.5,0.3-7.1,0.6-10.7,1
      c-0.1,2.8,0,5.3,2.2,7.3c0.5,0.4,0.5,1.7,0.3,2.4c-0.5,1.6-1.7,3.2-1.9,4.8c-0.2,2.2-1.2,3.3-3.2,3.7c-1.7,0.4-2.1,1.4-1.7,2.9
      c0.5,1.7-0.2,2.9-1.1,4.2c-0.5,0.8-0.8,2-0.7,3c0.1,1.5,0.3,3.1,0.8,4.5c0.4,1.2,1,2.5,1.9,3.4c2.9,2.8,5.9,5.6,9.1,8.2
      c2.4,1.9,4,4.1,4.1,7.2v0.2c0,1,0.4,2,0.7,3.6c4-3.2,7-0.7,10.3,1.1c-0.1,0.2-0.2,0.4-0.4,0.7l1.9,0.2l0.5-4.5l5,0.5l-0.4,4.5
      l33,3.2c0.5-1.4,1.1-2.7,1.8-4c0.1-0.1,0.1-0.2,0.2-0.3C893.5,444.2,893.4,442.4,891.8,440.7"
          />
        </g>
        <g id="Great_Lakes_Section_3">
          <path
            className="st13"
            d="M932.4,371.6c-0.9-0.2-1.8-0.8-2.6-0.7c-9.3,1.1-18.7,2.3-28,3.4c-0.6,0.1-1.1,0-2,0c1-2.2,1.9-4.3,2.8-6.3
      c5.3-11.2,4-21.9-2.1-32.3c-1-1.8-1.7-3.3-0.7-5.2c1.1-2.2-0.4-4.1-0.7-6.1c-0.1-0.8-0.4-2,0-2.4c3.2-3.3,2.4-7.1,1.6-11
      c-0.2-0.7,0-1.6,0.3-2.2c0.9-1.8,2.1-3.4,3.2-5.3c0.1,0,0.6,0.3,1.3,0.5c1.1-2,2.2-4,3.3-5.9c0.2,0,0.3,0.1,0.5,0.1v6.7
      c1.8-0.3,3.2-0.6,4.7-0.8c0-2.4,0.1-4.6,0-6.9c-0.1-1.4,0.4-2.3,1.6-3.1c1.5-1,2.9-2.2,4.4-3.4c-1.1-1.3-2-2.3-2.9-3.4
      c2.5-3,5.1-3.4,8.9-1.7c0.9,0.4,1.7,0.8,2.5,1.3c2.8,2,6,2.7,9.3,2.4c3.6-0.4,5.3,1.5,6.2,4.7c-0.8,0.4-1.5,0.9-2.5,1.5
      c1.4,2.1,3,4.2,4.2,6.4c1.7,3,0.5,6.2,0.2,9.3c0,0.4-0.9,0.7-1.4,1c-1.5,0.7-2.5,1.6-2,3.5c0.1,0.4-0.6,1.2-1.1,1.4
      c-1.7,0.7-3.4,1.3-5.4,2c0.9,2.3,1.6,4.7,2.7,6.9c0.8,1.7,4.8,2.1,6.3,0.9c1-0.8,1.9-1.6,2.9-2.4c1.4-1,2.3-2.1,1.8-4
      c-0.1-0.4,0.6-1.2,1.1-1.6c0.5-0.4,1.2-0.4,1.8-0.7c2.9-1,5.1,0,6.3,2.9c2,5,4.1,10,5.6,15.1c0.8,2.6,0.4,5.6,0.4,8.4
      c0,0.5-0.7,1.1-1.1,1.6c-2.4,2.7-4.7,5.4-7.2,7.9c-2,2-2.5,4.3-1.8,6.8c0.8,2.7-0.8,4.6-1.6,6.8c-0.1,0.4-0.8,0.6-1.2,0.7
      c-6.4,1.2-12.9,2.5-19.3,3.7C932.5,372.1,932.5,371.8,932.4,371.6"
          />
        </g>
        <g id="Great_Lakes_Section_2">
          <path
            className="st12"
            d="M799.7,300.5c-0.9-0.8-1.6-1.4-2.3-2c-3-2.7-2.4-5.6,1.5-6.9c2-0.7,4.8-0.9,5.7-2.3c0.9-1.4,0-4-0.1-6.1
      c-0.2-2.4-0.5-4.8-0.6-7.2c0-1.1,0.5-2.2,0.9-3.9c3,3.2,5.3,1.5,7.6,0.2c3.4-1.9,6.8-3.6,10.3-5.4c0.1-0.1,0.3,0,0.7,0
      c-0.1,1.6,0,3.3-0.3,4.9c-0.3,1.6,0.4,2.1,1.8,2.1c1.1,0,2.2,0.4,3.3,0.3c3.3-0.3,5.5,1.3,7.1,4c1.1,1.8,2.7,2.3,4.8,2.3
      c3.6,0,7.1,0.5,10.7,0.6c1.4,0.1,2.4,0.5,3,1.7c0.7,1.5,1.9,1.8,3.4,1.7c2.9-0.1,5.8,0,8.8,0c0.9,3.9,4,4.8,7.4,5.5
      c-0.1,2.8-0.3,5.4-0.4,8.2c1.2-0.6,2.2-1.1,3.3-1.7c0.6,4.3,1.4,8.5-1.8,12.1c-0.2,0.3-0.4,0.6-0.6,0.9c-2.8,3.1-2.4,6.2,0.1,9.6
      c1.5-2.9,3.1-5.7,4.5-8.5c0.7-1.4,1.3-2.5,3.2-2.3c0.4,0.1,1.1-0.6,1.4-1.1c0.7-1,1.1-2.2,1.9-3.1c-0.5,1.6,0.7,3.9-2.1,4.6
      c-0.3,0.1-0.4,0.7-0.5,1.1c-0.3,1-0.4,2.2-0.8,3.2c-1.2,2.8-0.8,5.4,0.7,7.9c0.8,1.3,0.8,2.4-0.7,3.2c-1.6,0.8-2,2.2-2.2,3.9
      c-0.3,3.8-1,7.5-1.5,11.2c-0.8,5.3-0.2,10.5,1.4,15.7c0.5,1.7,0.6,3.5,0.9,5.5c-15.2,1.1-30,2.1-44.9,3.2c-0.2-1.1-0.4-2-0.6-3.2
      c-1.4-0.1-2.9-0.1-4.4-0.5c-0.9-0.2-2.1-0.7-2.6-1.4c-2.4-3.4-2.1-6.6,0.7-10.4c0-0.1,0-0.3-0.1-0.4c-4.5-3.5-5.2-8.5-5.7-13.6
      c-0.2-1.9-0.8-3.3-2.8-3.8c-0.7-0.2-1.3-0.7-2-0.9c-2.1-0.7-3.5-1.9-4.1-4.1c-0.3-1.3-1.2-1.9-2.6-1.7c-2.4,0.4-3.9-0.6-5.4-2.6
      c-1.4-1.9-3.8-3.1-5.6-4.9c-0.8-0.8-1.1-2-1.6-3c-0.1-0.2-0.1-0.6-0.1-0.9C797.8,308.4,796.8,304.2,799.7,300.5"
          />
        </g>
        <g id="Great_Lakes_Section_1">
          <path
            className="st12"
            d="M866.8,252.5c-1.7,2.1-3.2,4.5-5.1,6.3c-2.8,2.6-1.6,5.5-1.4,8.6c0.6-0.3,1.1-0.4,1.4-0.7
      c2.9-3,6.4-2.3,9.8-1.5c0.6,0.1,1.3,0.6,1.8,1.1c1.6,1.9,3.2,3.9,4.8,5.8c0.4,0.5,1.6,1,1.9,0.8c3.6-2.4,6.7-1.4,9.7,1.4
      c1.8-1.9,3.5-3.6,5.1-5.4c1-1.2,2.1-1.8,3.8-1.8c3-0.1,6-0.4,9-0.7c1.4-0.1,2.9-0.1,3.8-1.7c0.2-0.4,1.1-0.5,1.6-0.7
      c0.2,0.4,0.3,0.5,0.3,0.6c0.3,1.7-1.3,3.6,1.2,5.1c3.2,2,6.2,2.1,9.4,0.4c1.4-0.8,2.7-1.8,4.4-2.8c1.1,2.8,2.3,5.5,3.3,8.2
      c0.7,2,1.6,2.8,3.7,2.1c1.2-0.4,2.6-0.4,3.8-0.6c0.1,0.2,0.2,0.5,0.4,0.7c-0.5,0.3-1,0.9-1.6,0.9c-3,0-6,0-9-0.2
      c-2.9-0.2-5.8-0.6-8.9-0.9l-0.1,5c-1.2-1.1-2.1-1.8-2.8-2.7c-1.3-1.6-2.9-2.3-5-2.1c-0.9,0.1-1.9,0.1-2.9-0.1
      c-1.3-0.2-2.1,0.2-2.4,1.6c-0.4,2-1.6,2.7-3.5,2.3c-0.4-0.1-0.9,0.1-1.3,0c-3.6-0.4-6.1,0.6-7.2,4.5c-0.3,1-1.5,1.7-2.4,2.6
      c-0.2-0.1-0.3-0.2-0.5-0.4c0.2-1.7,0.4-3.5,0.6-5.8c-1.4,1.8-2.6,3.2-3.9,4.8c-0.9-1.7-1.6-3.2-2.5-5c-2.6,6-5.1,11.8-7.7,17.6
      c-0.3-0.1-0.6-0.1-0.9-0.2c-0.1-1.6-0.3-3.2-0.4-5c-1,0.5-1.8,0.9-2.8,1.4c0-1.5-0.1-2.9,0.1-4.1c0.5-2.1-0.4-2.8-2.2-3.1
      c-0.9-0.1-1.9-0.2-2.5-0.8c-1.1-1.2-1.9-2.6-2.9-4c0.2-0.2,0.3-0.5,0.5-0.7c-3.3-0.1-6.5-0.2-9.8-0.2c-1.6,0-2.7-0.4-3.3-1.9
      c-0.6-1.3-1.6-1.7-3-1.8c-3.5-0.2-7-0.7-10.5-0.9c-2.2-0.1-3.9-0.6-5.1-2.7c-0.4-0.8-1.1-1.5-1.5-2c2.2-2.3,4.1-4.5,6.3-6.6
      c0.5-0.5,1.5-0.6,2.2-0.4c3.1,0.5,5.6-0.2,7.5-2.9c0.3-0.5,0.9-1,1.4-1.1c3-0.5,4-3.1,5.7-5.1c2.4-2.9,5.7-4.8,9.2-6
      C866.5,252.1,866.6,252.3,866.8,252.5"
          />
        </g>
      </g>
      <g id="Florida_Region" data-name="Florida" onClick={handleMapClick}>
        <g id="Florida_Section_2">
          <path
            className="st14"
            d="M1067.8,723.2c-0.1,0.5-0.4,1.2-0.7,1.5c-3.6,1.8-7.1,4.1-11.3,4.4c-0.8,0.1-1.6-0.7-2.5-1.2
      c0.7-1.1,1.2-1.7,1.8-2.6c-2.6-3.4-5.1-6.8-7.9-10.1c-0.4-0.5-1.1-0.7-1.9-0.9l7.7-2.6v-9l-10.2-11.3l4.3-9.7h16
      c1.7,2.7,3.5,5.3,4.7,8.2c1.1,2.7,1.4,5.8,1.6,8.8C1070.1,707.1,1069.1,715.2,1067.8,723.2z"
          />
        </g>
        <g id="Florida_Section_1">
          <path
            className="st15"
            d="M1041.5,713.8l0.1,0.2c-2.4-0.6-3.8-2.3-4.8-5c-0.9-2.5-1.7-5.3-4.3-6.9c-0.4-0.2-0.5-1-0.6-1.6
      c-0.6-2.7-1.2-5.4-1.8-8.4c-1.8,0.2-3.5,0.4-5.3,0.6c-0.1,0.7-0.3,1.3-0.4,2c-0.2-0.1-0.4-0.1-0.5-0.2c-2.4-2.7-4.9-5.4-7.3-8.2
      c-0.3-0.4-0.2-1.3-0.1-2c0.1-0.5,0.5-0.9,0.8-1.3c3-3.2,3.8-6.9,2-11c-4.5,0.7-5.4,1.5-5.9,5.7c-1.9-0.3-2.7-1.3-2.5-3.3
      c0.5-6.1,0.9-12.2,1.3-18.3c0.1-1.2-0.5-2.4-1-3.6c-0.3-0.8-1.1-1.4-1.5-2.1c-0.7-1.4-1.6-2.5-2.7-3.2l29.3-10.8
      c2.1,3.3,4.6,6.4,7.1,9.5c0.8,1,1.8,2,2.6,2.9c0.8,0.9,1.7,1.8,2.8,3.1c-1.4-0.3-2.3-0.5-3.7-0.7c2,3.8,3.8,7.4,5.8,10.8
      c3.6,6.1,7.4,12,11,18.1H1046l-5.2,11.8l10.5,11.6v7L1041.5,713.8z"
          />
        </g>
      </g>
      <g id="Atlanta_Region" data-name="Atlanta" onClick={handleMapClick}>
        <g id="Atlanta_Section_4">
          <path
            className="st16"
            d="M1036.3,636.5l-29.3,10.8c-1.1-0.7-2.4-0.9-4.1-0.6c-0.7,0.1-1.6-0.5-2.2-1.1c-4.4-4.4-8.8-8.9-13.1-13.5
      c-1-1.1-2-1.6-3.4-0.9c-1.5,0.7-2.5,0.2-3.3-1.1c-0.6-1-1.3-1.2-2.3-0.6c-0.7,0.4-1.6,0.8-2.4,1c-2.1,0.5-2.9,1.8-2.9,3.9
      c0,0.7-0.8,1.7-1.5,2c-4.9,1.9-10,3.6-15,5.3c-0.3-0.5-0.5-0.8-0.5-1.2c-0.4-3.7-2.8-5.5-6.1-6.8c-3.7-1.4-7.2-3-11-4.5
      c0.1-0.4,0.2-1.1,0.3-2.2c-2.3-0.2-4.5-0.5-6.8-0.6c-0.3,0-0.7,0.3-0.9,0.5c-2,3.1-5.6,2.5-8.7,3.9c1.5-2.7,1.5-2.7-0.5-5.6
      c-1.9,2-3.7,4-5.8,6.1c-1.1-2.1-2-3.7-1.1-5.7c0.2-0.5-0.2-1.5-0.7-2c-1.1-1.2-2.4-2.3-4.1-3.9c1.2-0.5,2-1.2,2.9-1.3
      c14.7-1.8,29.4-3.5,44.1-5.2c0.6-0.1,1.8,0.5,2,1.1c1.4,4.3,4.2,4.9,8.5,4.6c14-1.1,28.1-1.6,42.1-2.4c1.3-0.1,2.1,0.4,3.1,1.3
      c1.1,1.1,3,1.5,4.7,2.2c0.7-3.3,0.2-6-0.8-8.8c-0.2-0.7-0.1-1.9,0.3-2.5c0.4-0.4,1.6-0.5,2.3-0.3c2.7,0.7,5.3,1.5,7.6,2.2
      C1027.8,620.7,1031.4,628.9,1036.3,636.5"
          />
        </g>
        <g id="Atlanta_Section_3">
          <path
            className="st16"
            d="M895.8,633.5c-3.9,0.3-7.8,0.1-11.4,1c-3.5,0.9-6.8,3-10.2,4.5c-0.1-0.1-0.2-0.2-0.2-0.4
      c0-0.5,0.1-0.9,0-1.4l18.4-24.5c0.5,2.9,1,5.9,1.5,8.8C894.4,625.5,895.1,629.4,895.8,633.5"
          />
        </g>
        <g id="Atlanta_Section_2">
          <path
            className="st16"
            d="M936,526.2c1.8,5.8,3.6,11.5,5.4,17.3c3.2,10.4,6.4,20.7,9.7,31c0.5,1.5,2,2.8,3.2,3.9
      c2.3,2.2,2.6,6.2,0.6,8.7c-0.4,0.5-0.7,1.3-0.7,1.9c-0.2,1.9-0.4,3.8-0.3,5.7c0.1,1.1,0.6,2.3,1.3,3.1c1.5,1.8,2.1,3.6,1.2,5.8
      c-0.8,2-0.6,4,0.8,5.7c0.4,0.5,0.6,1.1,1,2c-3.3,0.4-6.5,0.9-9.6,1.2c-11.7,1.4-23.4,2.8-35.2,4c-2.4,0.3-3.2,2-4.6,3.6
      c1.4,1.4,2.8,2.6,4.2,3.9c0.4,0.4,1,1.1,0.9,1.3c-1.2,2.5,0.5,4.5,1.1,6.9c-2.7,0.9-5.3,1.9-8.4,3c0.7-1.4,1.1-2.2,1.6-3.3
      c-2.1-0.8-2.2-2.4-2-4.4c0.2-1.7,0-3.3-2.2-3.9c-1.5-0.4-2.4-0.2-2.5,1.5c-0.2,2.7-0.5,5.4-0.8,8.2h-3.1
      c-0.8-5.1-1.7-10.2-2.5-15.4c-0.8-4.8-1.9-9.6-2.2-14.5c-0.4-6.1-0.2-12.2-0.2-18.3c0.2-16.2,0.4-32.4,0.6-48.6c0-0.9,0-1.8,0-2.6
      c0.2-3.5,0.2-3.5,3.6-3.9c12.6-1.3,25.2-2.7,37.8-4C935.1,526.1,935.5,526.2,936,526.2"
          />
        </g>
        <g id="Atlanta_Section_1">
          <path
            className="st17"
            d="M988.4,530.9c0.3,0.5,0.6,0.8,0.7,1.1c1.5,4.9,4.7,8.2,9.2,10.4c2.3,1.1,4,2.8,4.8,5.3
      c0.4,1.4,1.3,2,2.8,1.9c1.3-0.1,2.6,0.1,2.8,1.7c0.6,3.8,3.1,5.7,6.5,6.7c2.4,0.7,3.8,2.2,3.9,4.8c0,0.5,0.3,1,0.3,1.5
      c0.2,2.8,1.5,4.4,4.3,5c2.1,0.5,3.6,3.2,3.3,5.4c-0.1,1-0.2,2-0.4,3.2c1.9,0.2,3.6,0.3,5.9,0.6c-1.7,1.9-3.1,3.5-4.7,5.3
      c3.3,2.8,0.9,5.5-0.7,8.2c-0.7,1.2-1.3,2.4-2.1,3.8c1.2,0.4,2.2,0.8,3.3,1.2c-1.4,3.6-5.5,6.2-3.1,11c-2.3-0.7-4.2-1-5.9-1.8
      c-1.9-0.8-2.4,0.8-3.4,1.6c-1,0.7-1,1.5-0.6,2.7c0.7,2.1,1.2,4.4,1.8,6.6c-0.2,0.1-0.5,0.3-0.7,0.4c-0.6-0.4-1.5-0.7-1.8-1.3
      c-0.8-1.7-2-1.7-3.6-1.6c-15.3,0.9-30.6,1.8-45.8,2.6c-0.8,0-1.8-0.7-2.3-1.3c-1-1.4-1.7-3.1-2.6-4.5c-2.2-3.2-3.2-6.4-1.7-10.2
      c0.3-0.6-0.2-1.9-0.8-2.5c-2.9-2.7-2.1-6.1-1.9-9.4c0-0.4,0.2-1,0.5-1.1c2.5-1.5,1.7-3.7,1.4-5.8c-0.3-2.1-0.4-4.2-2.5-5.5
      c-1.8-1.1-2.6-2.9-3.2-5c-4.5-14.6-9.1-29.2-13.6-43.8c-0.2-0.7-0.4-1.4-0.6-2.2c14-1.9,27.9-3.8,42.3-5.7c-0.7,1.7-1.3,3.1-2,4.5
      c-0.7,1.3-0.4,2,0.8,2.6c1.5,0.8,2.9,1.7,4.3,2.6C984.9,531,986.4,532.2,988.4,530.9"
          />
        </g>
      </g>
      <g id="Charlotte_Region" data-name="Charlotte" onClick={handleMapClick}>
        <g id="Charlotte_Section_4">
          <path
            className="st18"
            d="M1034.6,566c1.1,1.4,1.9,2.4,2.9,3.7c-1.8,0.3-3.5,0.6-5.6,0.9c0.8,1.3,1.6,2.4,2.2,3.5
      c0.7,1.3,0.1,2.5-1.3,2.6c-1.3,0-2.6-0.1-3.9-0.2c-0.1-1.4,0-2.6-0.3-3.8c-0.7-2.7-2.1-4.9-5.2-5.4c-1.3-0.2-2-1-2-2.4
      c-0.2-5.1-2.7-8.3-7.7-9.5c-2.4-0.6-2.7-2.7-3.2-4.5c-0.6-2.1-1.7-3-3.8-3c-0.7,0-1.7-0.5-1.9-1c-1.1-3.3-3.6-5-6.4-6.5
      c-3.7-1.9-6.2-4.7-7.4-8.6c-0.3-1-0.8-1.8-1.4-3c-4.3,2.3-6.5-1.7-9.7-3c1-2.3,1.8-4.4,2.9-6.3c0.4-0.7,1.5-1.2,2.2-1.2
      c3.2-0.1,5.6-1.3,7.7-3.8c0.8-1,2.4-1.7,3.7-1.8c6.6-0.9,13.3-1.6,20-2.3c3.7-0.4,7.4,2.8,7.7,6.6c0,0.2,0.1,0.4,0.2,0.8
      c5.8-1.1,11.6-2.1,17.3-3.3c1.9-0.4,3.4-0.2,5,1.1c6.3,5,12.8,9.9,19.3,15c-3.6,4.8-7,9.5-10.6,14.4c1.4,0.6,2.6,1.2,4.2,1.9
      c-2.3,1.7-2.4,5.4-6.1,5.1c-1.8,6-7.5,8.8-11.3,13.1c-0.7,0.8-2.5,0.5-3.7,0.7C1037.2,565.7,1036.3,565.8,1034.6,566"
          />
        </g>
        <g id="Charlotte_Section_3">
          <g>
            <path
              className="st18"
              d="M1114.6,477.5c3.4,4.4,3.1,11.2-0.7,13.4c0.2-1.1,0.2-1.7,0.4-2.3c0.2-0.5,0.8-1,0.7-1.4
        c-0.2-2.6,1-5.4-1.1-7.7C1113.8,479.3,1114.3,478.4,1114.6,477.5"
            />
            <path
              className="st18"
              d="M1107.3,496c-0.3-0.2-0.7-0.5-1.9-1.3c1.9-1.5,3.5-2.8,5.3-4.2c2,1.2,0.1,2-0.5,2.8
        C1109.4,494.2,1108.4,495,1107.3,496"
            />
            <path
              className="st19"
              d="M1089.5,475.9c0.4,0.4,0.5,0.6,0.6,0.7c2.9,2.6,5.8,3.2,9,0.5c0.8-0.6,1.8-0.9,2.9-1.4
        c0.3,1.7,0.7,3.3,1.1,5.6c1.5-1.9,2.8-3.4,4.1-5c1.4,2.3,1.7,4,0.5,5.8c-0.9,1.4-1.7,2.9-2.2,4.5c-0.6,2.1-1.9,2.4-3.8,2.2
        c-4.5-0.4-9-0.7-13.5-1c-0.2,0.2-0.3,0.4-0.5,0.5c0.4,0.5,0.7,1.4,1.3,1.6c2.5,1,5,1.9,7.7,2.9c-1.1,2.1-2,4-3.1,6.1h9.9
        c-0.7,1.8-1.3,3.2-1.7,4.3c-1,0-1.8-0.2-2.5,0c-2.4,0.8-4.8,1.6-7.2,2.6c-0.7,0.3-1.4,0.8-1.9,1.3c-3,3.4-5.9,6.9-9,10.1
        c-1.8,1.9-2.3,4-2.4,6.3c-0.1,1.5-0.7,2.3-2.2,2.7c-2.6,0.7-5.2,1.5-7.7,2.4c-1.3,0.5-2.2,0.2-3.2-0.6
        c-6.5-5.1-13-10.1-19.6-15.1c-0.8-0.6-2.3-0.9-3.3-0.8c-5.6,0.9-11.2,2.1-16.8,3.1c-0.6-3.8-4.3-4.8-6.5-7.1
        c-0.3-0.2-1-0.1-1.5,0c-7.5,0.8-14.9,1.5-22.4,2.5c-1.4,0.2-3,0.9-3.9,1.9c-2.2,2.5-4.7,3.7-8,3.8c-0.9,0-1.7,0.8-2.5,1.3
        c-0.3,0.2-0.6,0.5-0.9,0.5c-6.3,1-12.6,1.9-19.1,2.9v-3.4c1.3-0.6,2.6-0.9,3.6-1.7c1.6-1.3,2.3-3,1.5-5.2
        c-0.6-1.7,0.6-3.1,2.3-2.5c4.6,1.6,7.5-0.6,10.5-3.5c1.8-1.8,3.5-3.3,3.9-6c0.1-1,1.7-1.8,2.7-2.8c1.3,1.2,2.3,2.2,3.6,3.4
        c0.9-2.2,1.6-4.2,2.5-6.1c0.3-0.6,1-1.5,1.5-1.5c0.7,0,1.5,0.6,2,1.1c0.7,0.7,1.1,1.5,1.9,2.6c0.8-2.5,1.5-4.7,2.2-6.9
        c0.2-0.6,0.2-1.5,0.6-1.6c3-1.2,1.7-3.7,1.7-5.7c0-0.3,0-0.7,0.1-1.1c4.8-0.4,9.6-0.4,14.2-1.2c11.8-2,23.5-4.1,35.2-6.5
        c16.8-3.5,33.6-7.2,50.4-10.9c1.2-0.3,1.9-0.1,2.4,1.1c0.8,1.9,1.7,3.7,2.7,5.8C1100,468.7,1095.3,473.2,1089.5,475.9"
            />
          </g>
        </g>
        <g id="Charlotte_Section_2">
          <path
            className="st18"
            d="M998.7,485.7c-1.2,1-1.3,3.4-1.8,4.7c-1.7-0.2-3.3-0.6-4.8-0.6c-0.5,0-1.2,0.7-1.4,1.3c-0.7,1.5-1.3,3-2,4.9
      c-1.4-1.5-2.7-2.5-4.6-0.9c-0.1,0.1-0.2,0.2-0.4,0.3L980,483c6.6-1,13.3-2,19.9-3C999.8,482,1001,483.8,998.7,485.7"
          />
        </g>
        <g id="Charlotte_Section_1">
          <path
            className="st18"
            d="M1101.9,458.1c-1.8-3.9-4.9-4.8-8.4-2.3c-1.3,0.9-2.3,0.9-3.7,0.2c-2.5-1-4.9-2.5-7.4-3.8l-4.4,9l-7.9,1.9
      l-18.2,4.3l5.8-8.8L1040,452l-14.7,5c0,0,0,0.1-0.1,0.5c-2.4,0.9-4.9,2-7.6,2.8c-1.6,0.4-3.4,0.1-3.3,2.6c0,0.2-0.4,0.4-0.6,0.5
      c-2.3,0.5-4.6,0.8-6.8,1.3c-1,0.2-2.1,0.4-2.8,1c-2.8,2.5-5.7,2.5-8.2-0.3c-1-1.1-1.7-2.3-2.4-3.2c-3.1,2.5-6.2,5-9.3,7.6
      c-0.3,0.3-0.6,0.9-0.6,1.3c-0.1,4-2.5,5.9-5.8,7.4c-1.9,0.9-3.7,2.2-5.5,3.3c0.1,0.2,0.2,0.4,0.2,0.6c3.7-0.5,7.4-1.1,11.1-1.6
      c5.9-0.9,11.8-2.2,17.7-2.5c9.8-0.5,19.6-1.3,29.3-3.3c6.4-1.3,12.8-2.7,19.1-4.1c8.7-1.9,17.4-3.8,26.1-5.7c0.1,0,0.2,0,0.3-0.1
      c1.6-0.3,3.2-0.7,4.9-1c7.1-1.5,14.2-3.1,21.5-4.7C1102.2,458.7,1102,458.4,1101.9,458.1"
          />
        </g>
      </g>
      <g id="Ohio_Valley_Region" data-name="OhioValley" onClick={handleMapClick}>
        <g id="Ohio_Valley_Section_5">
          <path
            className="st20"
            d="M1027.8,411.7c-0.4-2.4-0.7-4.7-1.1-7c-5.8,1.1-11.2,2.2-16.8,3.3c-1.3-6.8-2.5-13.3-3.8-19.8
      c-0.2,0-0.5,0.1-0.8,0.1c0,0.5-0.1,1,0,1.5c1.8,4.5,1,9.2,1.6,13.8c0.8,5.9-1.3,9.7-6.6,12c-0.1,0.1-0.2,0.2-0.4,0.2
      c-3,0.9-5.2,2.4-3.8,6.6c-1.5-0.8-2.3-1.3-3.7-2v9.6c-1.8-0.7-3-1.2-4.7-2c-0.5,2.1-1.7,4.2-1.3,5.9c0.4,1.9-0.1,2.9-1.2,4.2
      c-1.3,1.5-3.1,2.9-3.5,4.6c-0.9,3.9-1,7.9,2.9,10.6c1.5,1.1,2.9,2.5,4.6,3.4c2.7,1.4,5.4,2.8,6.5,6c0.2,0.6,0.9,1.2,1.4,1.7
      c2,1.9,3.6,2,5.4-0.1c1.1-1.3,2.2-1.6,3.9-1.5c1.8,0.1,3.8-0.3,5.5-0.9c0.8-0.3,1-1.9,1.3-2.5c2.7-0.8,5.2-1.5,7.6-2.2l-9.6-42.8
      L1027.8,411.7z"
          />
        </g>
        <g id="Ohio_Valley_Section_4">
          <g>
            <g>
              <path
                className="st21"
                d="M1031.5,411.2c-0.5,0.7-1,1.4-1.6,2.1c-0.1-0.6-0.2-1.3-0.3-1.9L1031.5,411.2z"
              />
            </g>
            <path
              className="st21"
              d="M1046.3,400.7c-1.7,3.1-2.1,3.4-5.1,2.6c-1.3-0.3-1.8,0.3-2.4,1.2c-0.9,1.5-1.6,3.4-4.1,2.4
        c-0.3-0.1-1,0.6-1.3,1.1c-0.6,1-1.2,2-1.9,3l-1.9,0.2c-0.3-2.3-0.7-4.7-1-7.2C1034.5,403.1,1040.4,401.9,1046.3,400.7"
            />
          </g>
        </g>
        <g id="Ohio_Valley_Section_3">
          <path
            className="st20"
            d="M1018.2,384.5l1.6-11.6l-5.3-2l2.5-7.5l4.1,0.1l1.4-10.2c-3,0.6-6.1,1.3-9.1,1.9c-0.6-1.5-1.2-2.8-1.9-4.3
      c-2.8,1.6-5.4,3.2-8,4.7c-1.2,0.6-1.3,1.3-1.1,2.6c0.3,1.6,0.6,3.1,0.9,4.7c1.7,8.8,3.3,17.6,4.9,26.4c0.5,2.7,1,5.3,1.5,8
      c0.5,2.8,1.1,5.7,1.7,8.5c0.8-0.1,1.3-0.1,1.8-0.2c0.7-0.1,1.4-0.3,2.1-0.4c10.6-2.1,21.2-4.2,31.8-6.3L1018.2,384.5z"
          />
        </g>
        <g id="Ohio_Valley_Section_2">
          <path
            className="st21"
            d="M1005.1,408.2c0,1.5-0.4,2.4-1.8,3.1c-2.7,1.4-5.4,2.8-8,4.5c-0.7,0.5-1,1.6-1.7,2.6
      c-0.4-0.2-1.1-0.5-2.5-1.2c-0.2,3.4-0.4,6.6-0.5,9.8c-1-0.4-2.3-0.9-3.7-1.5c-0.9,2.4-1.7,4.8-2.6,7.1c-0.1,0.2-0.2,0.5-0.1,0.6
      c1.7,3.5-1.8,4.6-3.1,6.6c-0.2,0.3-1.1,0.4-1.5,0.2c-1.7-0.8-3.6-1.4-5-2.6c-1.2-1.1-1.8-2.9-2.8-4.5c-1.1,1.8-2.2,3.6-3,5.1
      c-1.8-0.8-3.5-1.5-5.2-2.1c-1.1-0.4-2-0.3-2.4,1c-0.6,1.8-1.9,1.9-3.2,1c-1.6-1.1-3.2-1.5-5.1-1.1c-0.5,0.1-1.7-0.5-1.8-1
      c-1.3-3.9-4.2-5.1-8-5c-1.1,0-2.2-0.3-3.4-0.5c-0.1-0.4-0.1-0.9-0.2-1.3l14.8-58c2.1,0.1,4.2,0.6,6.3,1.4c1.3,0.5,2.4,1,2.2,2.6
      c4.1,0.9,7.9,1.1,11.3-1.6c2.2-1.8,4.7-1.4,7.1-1.1c1.9,0.2,3.2-0.4,4.2-1.9c0.8-1.3,2-2.3,2.8-3.6c2.8-4.6,7.5-6.8,12.1-9.4
      c0.7,3.8,1.4,7.4,2.1,11c0.9,4.7,1.7,9.5,2.7,14.1c0.5,2.3,0.7,4.2-2.5,4.9C1006,394.3,1004.9,401.3,1005.1,408.2"
          />
        </g>
        <g id="Ohio_Valley_Section_1">
          <path
            className="st20"
            d="M1023.2,334.9c-0.3,0.7-0.3,1.5-0.8,2c-3.4,3.7-6.8,7.4-10.3,11.1c-0.6,0.6-1.3,1.1-2.1,1.6
      c-5.3,3.2-10.6,6.5-16,9.4c-3.8,2-6.2,5.3-8.5,8.6c-1.9,2.6-4,3.2-7,2.9c-1.8-0.2-3.8,0.7-5.4,1.6c-2.7,1.6-5.3,2.3-8,0.9
      c-0.9-0.6-1.7-1.4-2.6-1.8c-2.1-0.8-4.3-1.4-6.6-2.1c0.4-1.1,0.8-2,1.3-3.2c3.3,3.5,7.4,4.6,11.8,4.5c1,0,2.3-0.5,3-1.2
      c5-4.5,10-9.1,14.9-13.8c1.3-1.2,2.6-1.9,4.5-2.2c7-1,13.1-4.2,18.8-8.4c4-2.9,7.7-6.3,11.5-9.4c0.3-0.3,0.6-0.5,1-0.8
      C1022.8,334.8,1023,334.9,1023.2,334.9"
          />
        </g>
      </g>
      <g id="Mid_Atlantic_Region" data-name="MidAtlantic" onClick={handleMapClick}>
        <g id="Mid_Atlantic_Section_4">
          <g>
            <path
              className="st22"
              d="M1089.8,440.9c-2.1-1.2-4.3-2.4-6.4-3.7c-2.4-1.4-4.8-2.9-7.2-4.3c-0.9-0.6-1.6-1.8-1.9-2.8
        c-0.5-1.7-0.7-3.5-1-5.5c-1.8,1.1-3.4,2.1-4.9,3c-0.2-0.1-0.5-0.2-0.7-0.3c0.2-2-0.1-4.2,0.7-6c0.8-1.8,2.6-3.2,3.7-4.5
        c-1.9-4.5-5.4-4.4-8.7-3.7c-1.3-1.8,0.2-4.2-2.4-5.1c-3.1-1-3.1,2.6-5.3,3.2c-1.4-0.9-3-2.1-4.7-3.1c-1.6-1-3.2-0.1-3.2,1.8
        c0,0.8,0,1.6,0,2.4c0.3,4-2.9,6.5-4.5,9.7c-0.1,0.2-0.7,0.4-1,0.4c-2.9-0.4-3.1,1.6-3.4,3.6c-0.1,0.7-0.3,1.4-0.4,2.1
        c-0.8,4-0.7,4.1-4.7,4.8c-0.7,0.1-1.6-0.5-2.3-0.9c-0.6-0.3-1.4-1.2-1.7-1.1c-0.6,0.4-1.2,1.2-1.4,1.9c-1.2,4.3-2.2,8.6-3.5,12.9
        c-1.2,3.8-2.1,7.6,0.3,11.3l14.7-5l17.8,6.6l-5.8,8.8l18.2-4.3l7.9-1.9l4.4-9c-2.9-1.5-5.9-2.7-9.4-2.6c-0.2,0-0.7-0.7-0.6-0.9
        c0.2-0.3,0.6-0.8,1-0.8c1.7,0,3.4,0.1,5,0.2c4.6,0.3,9.3,0.2,13.2,2.5c0-2.3-0.3-4.4,0.1-6.3
        C1091.8,442.6,1091.4,441.7,1089.8,440.9"
            />
            <path
              className="st22"
              d="M1083.9,450.1c3,1,6,2.1,9,3.1c0,0.3,0,0.5,0,0.8c-0.8,0.1-1.6,0.6-2.2,0.4c-2.4-1-4.8-2.3-7.2-3.4
        C1083.6,450.7,1083.7,450.4,1083.9,450.1"
            />
          </g>
        </g>
        <g id="Mid_Atlantic_Section_3">
          <path
            className="st22"
            d="M1054.7,402.7c-0.3-0.3-0.8-0.5-1.3-0.6c-1.3-0.3-2.7-0.6-4.5-1c-0.9,1-2.1,2.5-3.5,3.7
      c-0.6,0.5-1.8,0.9-2.6,0.7c-1.7-0.5-2.7,0-3.5,1.5c-0.5,1-0.9,2.5-2.6,2.1c-1.3-0.4-2,0.3-2.6,1.4c-0.5,0.9-1,1.9-1.6,2.8
      c-1.1,1.9-2.3,3.9-3.6,6.1c-0.3-2-0.6-4-0.9-5.9c-0.1-0.6-0.2-1.2-0.3-1.8l-16.5,2.6l9.6,42.8c0.6-0.2,1.2-0.4,1.8-0.5
      c-0.4-2.2-1.2-4-0.9-5.5c1.5-6.7,3.4-13.2,5-19.9c0.5-2,2.1-2.1,3.4-2.3c0.6-0.1,1.3,0.9,2.1,1.3c0.7,0.4,1.5,0.9,2.1,0.7
      c0.7-0.2,1.7-0.9,1.9-1.5c0.6-1.9,1-4,1.3-6c0.3-1.6,0.8-2.7,2.7-2.6c0.7,0,1.7-0.3,2-0.9c1.5-2.4,3.3-4.6,3.7-7.4
      c0.1-0.7,0.1-1.5-0.1-2.4c-0.4-2.3,0.5-3.8,2.8-4.2c0.9-0.1,2-0.1,2.7,0.3c1.4,0.7,2.7,1.7,4.1,2.6c0.8-0.9,1.5-1.6,2.1-2.3
      C1056.7,405.2,1055.7,403.9,1054.7,402.7"
          />
        </g>
        <g id="Mid_Atlantic_Section_2">
          <path
            className="st22"
            d="M1101.6,381.2c-1.4,4.4-4.4,6.4-8.8,6.3c-1.8,0-2.7,0.7-2.8,2.5c-0.1,1.5-0.9,2.2-2.4,2.5
      c-0.1,0-0.2,0-0.2,0.1c-11.4,2.2-22.8,4.5-34.3,6.8c-0.4,0.1-0.8,0.3-1.3,0.4c0.4,0.4,0.7,0.5,1,0.5c1.9-0.3,3,0.4,4,2.1
      c0.8,1.3,2.1,2.7,3.4,3.2c2.6,0.9,5,1.7,4.3,5.6c5.5-2.1,7.4,2.3,10.3,4.9c-1.4,2.9-4.8,4.4-5.2,7.8c0.2,0.1,0.4,0.3,0.5,0.4
      c1.5-0.9,3-1.8,4.8-2.9c0.5,2.8,0.9,5.2,1.3,7.6c0.1,0.6,0.2,1.5,0.6,1.8c3.8,2.3,7.6,4.6,11.5,6.9c0.5-4,0.4-4.1-2.7-5.4
      c-1.1-0.5-2.2-1-3.6-1.6c2.1-4.3,1.8-8.3-1.5-11.7c-0.2-0.2-0.2-0.5-0.3-0.8c-1-3.9-2.1-7.7-3.1-11.6c-0.2-0.6-0.1-1.5,0.2-1.9
      c2.8-3.4,5.6-6.7,8.5-10c0.2-0.2,0.6-0.3,1-0.4c0.2,0,0.3,0,0.5,0.1c0.3,0.2,0.6,0.9,0.5,1.3c-0.5,2.2-1.2,4.4-1.7,6.6
      c-0.2,0.9-0.4,2,0,2.7c2.1,4.7,2.7,9.6,2.1,14.7c-0.1,0.6,0.7,1.7,1.2,1.8c4.3,0.8,7.1,3.8,9.8,6.9c0.6,0.7,1,2,0.8,2.8
      c-0.7,3.7-1.5,7.3-2.5,10.9c-0.5,1.7,0.1,2.6,1.9,3.7c1.2-4.5,2.5-8.7,3.4-12.9c1.5-6.7,3.9-13.4,2.7-20.4c-0.2-1-0.7-2.4-1.5-2.8
      c-5.5-3.1-7.8-8.7-11-13.6c-0.2-0.3-0.2-1.2,0-1.4c0.3-0.3,1.1-0.3,1.5-0.1c4.1,2.6,8.8,4.3,11.4,8.8c0.3,0.5,0.8,0.9,1.2,1.4
      c2.5-4.9,4.9-9.5,7.2-14.2c0.1-0.2,0.2-0.5,0.3-0.7l-11.4-11.8C1102.5,379,1102,380.1,1101.6,381.2z"
          />
        </g>
        <g id="Mid_Atlantic_Section_1">
          <path
            className="st23"
            d="M1091.4,367.1l-2.9-23.8c-1.5-1.9-4.3-2.9-6.7-2.4c-17.7,3.7-35.3,7.4-53,11.1c-2.1,0.4-4.2,0.9-6.3,1.3
      l-1.4,10.2l-4.1-0.1l-2.5,7.5l5.3,2l-1.6,11.6l28.9,14.3c13-2.6,26-5.2,39-7.7c1.5-0.3,2.3-0.9,2.4-2.5c0.1-1.8,1-2.5,2.7-2.6
      c1.7-0.1,3.5-0.5,5.2-0.9c0.5-0.1,1.2-0.3,1.4-0.7c1.5-2.7,2.9-5.5,4.4-8.3C1098.5,373,1095,370.1,1091.4,367.1"
          />
        </g>
      </g>
      <g id="New_York_Region" data-name="NewYork" onClick={handleMapClick}>
        <g id="New_York_Section_4">
          <path
            className="st24"
            d="M1142.6,347.8c-1.9,2-3.2,4.2-5.2,5.4c-6.6,3.8-13.4,7.3-20.2,10.9c-0.6,0.3-1.5,0.2-2.2,0
      c-0.6-0.2-1.2-0.5-1.8-0.8l2-4.7c0.2,0.4,0.5,0.7,0.7,1c5.5-2.5,11.3-5.1,17-7.7c0.4-0.2,0.9-0.6,1.2-1
      C1136,347.9,1138.7,347.3,1142.6,347.8z"
          />
        </g>
        <g id="New_York_Section_3">
          <g>
            <path
              className="st24"
              d="M1106.1,372.2l-12.6-8.6c-0.3,0.6-1,1.6-1.6,2.5c3.6,3,8.7,6.4,12.2,9.2L1106.1,372.2L1106.1,372.2z"
            />
            <g>
              <path
                className="st24"
                d="M1114.1,356.8l-2.4,5.5c-0.4-0.2-0.9-0.4-1.4-0.7c-0.8,2.3-1.5,4.5-2.3,6.8c0.1,0,0.2,0.1,0.4,0.1
          l-2.3,3.6v0l-2,3.1c-3.4-2.8-6.8-5.7-10.4-8.6c0.7-0.7,1.2-1.3,1.5-1.9c0.7-1.4,0.3-2.7-1.7-3.9c1.6-2.7,3-5.3,4.5-7.7
          c0.3-0.5,1.3-0.8,1.9-0.7c4.6,1.3,9.2,2.7,13.8,4.2C1113.8,356.7,1114,356.7,1114.1,356.8z"
              />
              <path
                className="st24"
                d="M1114.6,370.6c-2-0.6-4-1.3-6.2-2l-2.3,3.6v0l9.7,6.6c0.1-2.1,0.2-4.2,0.3-6.3
          C1116.1,371.4,1115.7,370.9,1114.6,370.6z"
              />
              <path
                className="st24"
                d="M1104.1,375.3L1104.1,375.3c0.1,0.1,0.2,0.2,0.4,0.3c-0.4,0.8-0.9,1.6-1.3,2.3l11.4,11.8
          c0.4-0.9,0.7-2,0.8-3c0.2-2.6,0.3-5.2,0.4-7.9l-9.7-6.6L1104.1,375.3z"
              />
            </g>
          </g>
        </g>
        <g id="New_York_Section_2">
          <path
            className="st24"
            d="M1093,362.8c0.3,0.2,0.4,0.6,0.7,0.9c-0.7,1.1-1.5,2.2-2.3,3.4l-2.9-23.8c0.5,0.6,0.8,1.2,1,1.9
      c1,3.8,3.2,5.8,7.4,6c-1.5,2.7-2.7,5.2-4.3,7.5C1091.5,360.3,1091.5,361.5,1093,362.8"
          />
        </g>
        <g id="New_York_Section_1">
          <path
            className="st25"
            d="M1114.7,353.1c-0.3,0.4-0.3,1-0.5,1.7c-6.9-2.1-13.8-4.2-20.6-6.3c-0.5-0.2-0.9-0.7-1.2-1.1
      c-0.3-0.5-0.5-1-0.6-1.6c-0.2-1.3-0.7-2.4-1.3-3.2l23.3-7.7c0.5,2.4,1.1,4.9,1.7,7.3C1116.3,345.9,1116.9,349.6,1114.7,353.1"
          />
        </g>
      </g>
      <g id="New_England_Region" data-name="NewEngland" onClick={handleMapClick}>
        <g id="New_England_Section_7">
          <path
            className="st26"
            d="M1117.2,353c2.7-5.4-0.4-10.4-1-15.5c-0.2-1.9-0.7-3.7-1.1-5.7c8.1-2.1,16.1-4.1,24.2-6.1
      c2,4.9,2.9,9.9,5.1,14.7c-0.4,0.4-0.4,0.4-3.7-1.8c-3.5,5-9.4,4.8-14.5,6.4c-1.7,0.5-3.2,1.2-4.3,2.6
      C1120.5,349.5,1118.8,351.2,1117.2,353"
          />
        </g>
        <g id="New_England_Section_6">
          <path
            className="st26"
            d="M1145.5,339c-1.5-4.7-2.9-9.1-4.3-13.5c0.3-0.3,0.3-0.3,0.4-0.4c5.8-1.5,4.2-2,6.2,3.1
      c0.2,0.6,0.4,1.2,0.6,1.9C1150.1,335.2,1150,335.2,1145.5,339"
          />
        </g>
        <g id="New_England_Section_5">
          <path
            className="st26"
            d="M1155,331.2c-2.9-2.9-7.4-4.3-6.5-9.7c-11.4,2.9-22.5,5.7-34,8.6v-15.2c4.9-1.1,10.2-2.3,15.4-3.5
      c4.8-1.1,9.5-2,14.3-3.2c1-0.3,2-1,2.8-1.8c1.1-1,1.9-2.2,2.8-3.2c0.3,0,0.4-0.1,0.5,0c3.6,2.3,3.7,2.3,1.9,6.2
      c-0.7,1.5-0.7,2.5,0.6,3.7c2.9,2.8,5.5,5.8,8.3,8.7c0.7,0.7,1.8,1.1,2.8,1.5c1.9,0.7,3.8,1.3,5.8,1.9c0.1,0.2,0.1,0.4,0.2,0.6
      c-1.4,0.5-2.9,0.9-4.2,1.5c-2,0.9-3.9,1.2-6.1,0.2c-0.6-0.3-1.7,0.2-2.3,0.6C1156.5,329,1155.9,330,1155,331.2"
          />
        </g>
        <g id="New_England_Section_4">
          <g>
            <path
              className="st27"
              d="M1113.8,334.9l-23.3,7.7c-1.4-2-3.7-2.9-6.2-3.6c-0.6-0.2-1.4-0.1-2.1,0.1c-22,4.6-44,9.2-66.1,13.8
        c-0.5,0.1-1,0.2-1.4,0.2c-1.8-3.1-1.8-3.1,0.5-5.6c3-3.2,5.9-6.5,8.9-9.6c1-1,1.2-1.9,0.7-3.1c-0.9-2.2-1.7-4.4-2.9-6.5
        c-1.4-2.5-1.1-4.6,1.5-5.7c3.3-1.4,6.8-2.7,10.2-3.6c3.6-1,7.1-0.4,9.7,2.9c0.8-0.5,1.4-0.9,1.9-1.3c2.3-2.1,4.8-3.1,7.9-2.8
        c0.8,0.1,2-0.7,2.6-1.4c2.5-3,5-6.1,8.9-7.4c-0.2-3.4-3.6-6.8,0.5-10.2c-2.3-1.2-4.2-2.1-6.6-3.4c5.3-4.1,8-9.7,11.2-15
        c0.6-0.9,1.3-1.8,1.9-2.7c2.8-4.2,6.3-6.8,11.8-7.3c5.1-0.5,10.2-2.4,15.5-3.7c1.8,4.2,4.1,8.3,5.4,12.7c0.7,2.2-0.5,5-0.8,7.5
        c-0.1,0.7-0.1,1.5,0.1,2.1c1.2,3.1,2.4,6.3,3.8,9.8c2.1,0.5,2.1,0.5,2.6,3.7c1.6,8.6,2.3,17.3,2.6,26.1
        C1113,330.8,1113.3,332.8,1113.8,334.9"
            />
            <path
              className="st26"
              d="M1057.9,297.3c1.5,0.7,2.9,1.4,4.5,2.1c-2.7,2.9,0,5.5,0.1,7.7c-2.8,2.9-5.3,5.5-7.9,8
        c-0.4,0.4-1.1,1-1.6,0.9c-3.8-0.5-6.8,1.1-9.3,3.5c-6.5-4.8-12.6-2-18.8,0.2c-1.6,0.6-3,1.5-4.6,2.4c-0.6-0.9-1.2-1.7-1.9-2.6
        c4.6-4.2,9.9-6.5,16-7.3c6.4-0.8,12.8-2.2,19.1-3.6c0.7-0.2,1.6-1.1,1.9-1.9C1056.5,303.8,1057.1,300.6,1057.9,297.3"
            />
          </g>
        </g>
        <g id="New_England_Section_3">
          <path
            className="st26"
            d="M1113.8,313.2c-0.6-3.5-1.2-7-1.7-10.4c-0.2-1.1-0.4-2.3-0.5-3.5c-0.2-1.2-0.3-2.3-2-2.2
      c-0.4,0-1-0.9-1.3-1.5c-0.7-1.8-1.3-3.6-1.8-5.5c-0.4-1.5-1-3.1-0.7-4.5c0.9-4,0.7-7.7-1.4-11.3c-1.4-2.5-2.4-5.3-3.6-8.2
      c8.9-2.4,17.5-4.8,26.4-7.2c0.3,3.6-1.4,7.4,2.5,9.9c-1.9,2.2-3.7,4.2-5.4,6.3c-0.4,0.5-0.9,1.6-0.7,2c2.1,3.4,1.4,6.8,0.3,10.1
      c-1.4,4.4-1.4,8.6-0.4,13.1c0.5,2,0.3,4.1,0.4,6.1c0.1,1.4,0.2,2.9,0.3,4.5C1120.8,311.7,1117.4,312.4,1113.8,313.2"
          />
        </g>
        <g id="New_England_Section_2">
          <path
            className="st26"
            d="M1132,268.8c-3.8-2.3-3.3-5.8-3-9.3c0.1-1.4,0.2-2.9,0-4.4c-0.2-2.1,0.7-3.1,3-3.3c1.5,4.4,3,8.7,4.5,13.1
      c2.8,8.3,5.4,16.6,8.5,24.8c0.9,2.3,2.9,4.3,4.8,6.1c1.6,1.6,1.3,2.9,0.2,4.4c-1.5,1.9-3.1,3.7-4.7,5.5c-0.5,0.5-1.3,0.9-2.1,1
      c-5,1.2-9.9,2.3-14.9,3.4c-2.1,0.5-2.6,0-2.8-2.1c-0.4-4.3-0.8-8.5-1.2-12.8c-0.1-1.1,0-2.3,0.2-3.4c0.4-2.4,0.9-4.7,1.6-7
      c0.7-2.3,0.8-4.4-0.5-6.5c-0.6-1-0.3-1.8,0.5-2.7C1128.1,273.4,1129.9,271.2,1132,268.8"
          />
        </g>
        <g id="New_England_Section_1">
          <path
            className="st26"
            d="M1187.6,236.4c5.4-1.8,7.8,1.2,9.2,6.2c-2.5,2.5-5.3,5.3-8.2,8c-0.3,0.3-1,0.5-1.4,0.4
      c-2.5-0.5-3.2,0.9-3.4,3c-0.1,0.6-0.3,1.2-0.5,1.9c-1.8,0-3.8-0.5-5,0.2c-1.1,0.6-1.4,2.6-2.1,4.1c-2.1-1.1-4.1-2.2-6.5-3.6
      c-0.1,2.6-0.3,4.8-0.4,7.1c0,0.8-0.1,1.6-0.1,2.4c0.2,2.4-0.5,4.1-3.1,4.7c-2.2,0.5-2.8,2.3-3.2,4.2c-0.3,1.5-1,2.6-2.6,2.7
      c-4.1,0.1-5,2.7-5.4,6.2c-0.5,3.8-1.6,7.6-2.6,11.9c-3.1-3.1-5.5-5.7-6.7-9.3c-3.5-11-7.4-21.8-11.1-32.7
      c-0.3-0.9-0.6-1.8-0.9-2.8c0.6-0.3,1.1-0.7,1.6-0.8c2.8-0.6,3.8-2.9,3.8-5.2c-0.1-3.5,1.7-6.1,3-9c0.5-1,0.7-2.3,0.7-3.5
      c-0.1-4.6-0.3-9.2-0.7-13.7c-0.4-5.1,1.6-9.5,3.3-14.1c1-2.9,2.1-5.7,3.2-8.7c4.6,0.1,2.5,4.6,4.6,6.5c2.1-1.9,4.4-3.9,6.6-6
      c3.4-3.1,3.2-2.9,7.3-0.7c3.8,2,5.7,4.7,6.5,9c1.3,6.9,3.3,13.7,5.1,20.6c0.2,0.8,1.1,2,1.8,2.1c4.5,0.7,6.8,3,6.8,7.6
      C1187.1,235.3,1187.3,235.6,1187.6,236.4"
          />
        </g>
      </g>
      <g id="Labels">
        <g id="Southwest_Label" onClick={() => handleMapClick('Southwest')}>
          <path className="st28" d="M124.8,556.7" />
          <g>
            <text transform="matrix(1 0 0 1 149.6365 559.703)" className="st29 st30 st31">
              Southwest
            </text>
          </g>
          <polyline
            className="st32"
            points="284,545.4 276.6,545.4 276.6,558.7 289.9,558.7 289.9,551.3"
          />
          <g>
            <line className="st32" x1="285.6" y1="549.9" x2="458.7" y2="499" />
          </g>
        </g>
        <g id="Mid_West_Label" onClick={() => handleMapClick('Midwest')}>
          <g>
            <text transform="matrix(1 0 0 1 257.3089 659.4271)" className="st29 st30 st31">
              Midwest
            </text>
          </g>
          <polyline
            className="st32"
            points="377.8,645.1 370.4,645.1 370.4,658.4 383.7,658.4 383.7,651.1"
          />
          <g>
            <line className="st32" x1="380.1" y1="648.9" x2="584.6" y2="554.1" />
            <line className="st32" x1="490.7" y1="597.6" x2="584.6" y2="554.1" />
          </g>
        </g>
        <g id="Texas_Label" onClick={() => handleMapClick('Texas')}>
          <g>
            <text transform="matrix(1 0 0 1 370.4061 733.7963)" className="st29 st30 st31">
              Texas
            </text>
          </g>
          <polyline
            className="st32"
            points="454.9,719.4 447.6,719.5 447.8,732.8 461.1,732.6 461,725.2"
          />
          <g>
            <line className="st32" x1="458.7" y1="721.6" x2="648" y2="626.1" />
          </g>
        </g>
        <g id="Mid_South_Label" onClick={() => handleMapClick('MidSouth')}>
          <line className="st32" x1="745.7" y1="772.2" x2="868.2" y2="559.7" />
          <g>
            <text transform="matrix(1 0 0 1 758.8741 783.8679)" className="st29 st30 st31">
              Mid-South
            </text>
          </g>
          <polyline
            className="st32"
            points="742.6,769.5 735.3,769.6 735.5,782.9 748.8,782.6 748.6,775.3"
          />
        </g>
        <g id="Atlanta_Label" onClick={() => handleMapClick('Atlanta')}>
          <line className="st33" x1="894.3" y1="714" x2="972.6" y2="568.9" />
          <g>
            <text transform="matrix(1 0 0 1 906.7699 724.6147)" className="st29 st30 st31">
              Atlanta
            </text>
          </g>
          <polyline
            className="st33"
            points="892.3,710.2 885,710.3 885.2,723.6 898.5,723.4 898.4,716"
          />
        </g>
        <g id="Florida_Label" onClick={() => handleMapClick('Florida')}>
          <line className="st33" x1="1091.5" y1="722.6" x2="1050.4" y2="695.5" />
          <polyline
            className="st33"
            points="1089.5,725.3 1089.5,732.6 1102.8,732.6 1102.8,719.3 1095.5,719.3"
          />
          <g>
            <text transform="matrix(1 0 0 1 1109.4679 734.2893)" className="st29 st30 st31">
              Florida
            </text>
          </g>
        </g>
        <g id="Charlotte_Label" onClick={() => handleMapClick('Charlotte')}>
          <line className="st33" x1="1132.6" y1="598.2" x2="1048.6" y2="497.9" />
          <g>
            <text transform="matrix(1 0 0 1 1152.3787 611.8119)" className="st29 st30 st31">
              Charlotte
            </text>
          </g>
          <polyline
            className="st33"
            points="1130.8,602.1 1130.8,609.5 1144.1,609.5 1144.1,596.2 1136.7,596.2"
          />
        </g>
        <g id="Ohio_Valley_Label" onClick={() => handleMapClick('OhioValley')}>
          <line className="st32" x1="1163.5" y1="503.2" x2="980.5" y2="403.5" />
          <g>
            <text transform="matrix(1 0 0 1 1182.7321 516.2963)" className="st29 st30 st31">
              Ohio Valley
            </text>
          </g>
          <polyline
            className="st32"
            points="1160.5,505.9 1160.5,513.3 1173.7,513.3 1173.7,500 1166.4,500"
          />
        </g>
        <g id="Mid_Atlantic_Label" onClick={() => handleMapClick('MidAtlantic')}>
          <line className="st33" x1="1200.6" y1="416.1" x2="1047.8" y2="388.4" />
          <g>
            <text transform="matrix(1 0 0 1 1223.119 428.8593)" className="st29 st30 st31">
              Mid-Atlantic
            </text>
          </g>
          <polyline
            className="st33"
            points="1200.1,419.6 1200.1,426.9 1213.4,426.9 1213.4,413.6 1206,413.6"
          />
        </g>
        <g id="New_York_Label" onClick={() => handleMapClick('NewYork')}>
          <line className="st33" x1="1242.3" y1="312.5" x2="1099.9" y2="352.4" />
          <g>
            <text transform="matrix(1 0 0 1 1265.0244 316.3762)" className="st29 st30 st31">
              New York
            </text>
          </g>
          <polyline
            className="st33"
            points="1245.8,315.8 1253.1,315.8 1253.1,302.5 1239.8,302.5 1239.8,309.8"
          />
        </g>
        <g id="New_England_Label" onClick={() => handleMapClick('NewEngland')}>
          <line className="st33" x1="1208.6" y1="185.9" x2="1159.5" y2="244.8" />
          <g>
            <text transform="matrix(1 0 0 1 1226.9558 188.9989)" className="st29 st30 st31">
              New England
            </text>
          </g>
          <polyline
            className="st33"
            points="1206.6,181.9 1206.6,174.6 1219.9,174.6 1219.9,187.9 1212.5,187.9"
          />
        </g>
        <g id="Great_Lakes_Label" onClick={() => handleMapClick('GreatLakes')}>
          <g>
            <text transform="matrix(1 0 0 1 911.9301 187.998)" className="st29 st30 st31">
              Greak Lakes
            </text>
          </g>
          <polyline
            className="st32"
            points="898.3,188 905.6,188 905.6,174.7 892.3,174.7 892.3,182.1"
          />
          <g>
            <line className="st32" x1="893.9" y1="185.9" x2="868.6" y2="396.9" />
          </g>
        </g>
        <g id="Minneapolis_Label" onClick={() => handleMapClick('Minneapolis')}>
          <line className="st32" x1="653.5" y1="171.8" x2="679.2" y2="256.1" />
          <polyline
            className="st32"
            points="656.2,168.6 656.2,161.3 642.9,161.3 642.9,174.6 650.2,174.6"
          />
          <g>
            <text transform="matrix(1 0 0 1 501.5017 174.5634)" className="st29 st30 st31">
              Minneapolis
            </text>
          </g>
        </g>
        <g id="Northwest_Label" onClick={() => handleMapClick('Northwest')}>
          <line className="st32" x1="449.6" y1="295.7" x2="314.6" y2="151.4" />
          <g>
            <text transform="matrix(1 0 0 1 181.2774 153.7843)" className="st29 st30 st31">
              Northwest
            </text>
          </g>
          <polyline
            className="st33"
            points="316.6,147.9 316.6,140.5 303.3,140.5 303.3,153.8 310.7,153.8"
          />
        </g>
      </g>
    </svg>
  );
};

export default USAMap;
