/* eslint-disable react/no-danger */
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactGA from 'react-ga';

import _ from 'lodash';
// import { isMobile } from 'react-device-detect';

import { Grid, Stepper, Step, StepLabel, Button, Typography } from '@mui/material';

import styles from '../../styles/Question.module.scss';

import buttonStyles from '../../styles/ButtonStyles.module.scss';

import ScoreBox from './ScoreBox';

const correctAnswerIcon = require('../../assets/answer_correct_icon.png');
const wrongAnswerIcon = require('../../assets/answer_wrong_icon.png');

const Question = ({ question, activeStep, score, updateScore, onEnd }) => {
  const [userAnswer, setUserAnswer] = useState(null);
  const [answers, setAnswers] = useState([]);

  const { opponent } = useSelector((state) => state.data);

  useEffect(() => {
    const answerList = question.ordered_answers ? question.answers : _.shuffle(question.answers);
    setAnswers(
      answerList.map((answer, idx) => {
        return { ...answer, id: idx };
      })
    );
  }, [question]);

  useEffect(() => {
    if (userAnswer && userAnswer.is_correct) {
      updateScore(question.points);
    }
  }, [userAnswer]);

  const getPostAnswerClass = (answer) => {
    if (userAnswer) {
      // the users answer
      if (userAnswer === answer) {
        return userAnswer.is_correct ? buttonStyles.correct : buttonStyles.wrong;
      }
      // answer that isnt the users answer but is the correct answer
      if (answer !== userAnswer && answer.is_correct) {
        return buttonStyles.correct;
      }
      return buttonStyles.other;
    }
    return '';
  };

  const postAnswerIcon = (answer) => {
    if (userAnswer) {
      let icon = null;
      if (userAnswer === answer) {
        icon = userAnswer.is_correct ? correctAnswerIcon : wrongAnswerIcon;
      }
      if (answer !== userAnswer && answer.is_correct) {
        icon = correctAnswerIcon;
      }
      return (
        icon && (
          <img
            style={{ position: 'absolute', bottom: -15, height: 35 }}
            src={icon}
            alt="answerIcon"
          />
        )
      );
    }
  };

  const handleNext = () => {
    onEnd(userAnswer);
    setUserAnswer(null);
    setAnswers([]);
  };

  const renderQuestionText = () => {
    return (
      <div className={styles.questionText}>
        <span dangerouslySetInnerHTML={{ __html: question.text }} />
      </div>
    );
  };

  const renderInfoText = () => {
    let infoTitle = 'CORRECT!';
    if (!userAnswer.is_correct) {
      infoTitle = `The correct answer is ${String.fromCharCode(
        97 + _.find(answers, { is_correct: true }).id
      ).toUpperCase()}`;
    }

    ReactGA.event({
      category: 'Question Answer',
      action: `ActiveStep: ${activeStep} - QuestionId: ${question.id} - Answer: ${userAnswer.text} - Correct: ${userAnswer.is_correct}`
    });

    return (
      <div className={styles.infoText}>
        <Typography
          variant="h5"
          color={'text.green'}
          align="center"
          sx={{ fontWeight: 600, paddingBottom: 2 }}
        >
          {infoTitle}
        </Typography>
        <span dangerouslySetInnerHTML={{ __html: question.info_box }} />
      </div>
    );
  };

  const renderAnswers = () => {
    const answersItems = answers.map((answer) => {
      return (
        <Grid item xs={12} md={6} key={answer.id}>
          <div
            onClick={() => !userAnswer && setUserAnswer(answer)}
            role="button"
            tabIndex={0}
            className={`flex-center ${buttonStyles.outerButton}`}
          >
            <span className={`flex-center ${buttonStyles.answerLetter}`}>
              {String.fromCharCode(97 + answer.id).toUpperCase()}
            </span>
            <div
              className={`flex vertical-center horizontal-center ${
                buttonStyles.innerButton
              } ${getPostAnswerClass(answer)}`}
            >
              <span dangerouslySetInnerHTML={{ __html: answer.text }} />
            </div>
            {postAnswerIcon(answer)}
          </div>
        </Grid>
      );
    });

    return (
      <div className={styles.answersWrapper}>
        <Typography
          variant="h5"
          color={'text.lightBlue'}
          align="center"
          sx={{ fontWeight: 600, paddingBottom: 2 }}
        >
          Select an answer:
        </Typography>
        <Grid spacing={5} container justifyContent={'center'}>
          {answersItems}
        </Grid>
      </div>
    );
  };

  const getOpponentScore = () => {
    const questionIdx = userAnswer ? activeStep : activeStep - 1;
    if (activeStep === 0 && !userAnswer) {
      return 0;
    }
    return opponent[questionIdx];
  };

  const steps = ['1', '2', '3', '4', '5'];

  // console.log('opponentIdx', opponentIdx);
  return (
    <div className={styles.questionWrapper}>
      <ScoreBox score={score} opponentScore={getOpponentScore()} />

      <div
        className="flex direction-column"
        style={{
          height: '85vh'
          // border: '1px solid yellow'
        }}
      >
        <div className={styles.question}>
          {userAnswer ? renderInfoText() : renderQuestionText()}
        </div>
        {renderAnswers()}
        <div className="flex direction-column vertical-center" style={{ paddingTop: 50 }}>
          <Stepper activeStep={activeStep} sx={{ width: 400 }} alternativeLabel>
            {steps.map((label) => {
              return (
                <Step key={label}>
                  <StepLabel />
                </Step>
              );
            })}
          </Stepper>
          {userAnswer && (
            <Button
              variant="contained"
              color="purple"
              sx={{ margin: 5, width: 200, height: 50, fontSize: 20 }}
              onClick={() => handleNext(userAnswer)}
            >
              Next
            </Button>
          )}
        </div>
      </div>
      <div className={styles.references}>
        <span
          // style={{ textAlign: 'left', textTransform: 'none' }}
          dangerouslySetInnerHTML={{ __html: question.references }}
        />
      </div>
    </div>
  );
};

export default Question;
