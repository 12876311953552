import { useState } from 'react';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga';

import { Dialog, Button, FormControlLabel, Typography, IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

import { CustomCheckBox } from '../common';

import Wrapper from './Wrapper';
import USAMap from '../USAMap';
import { setData } from '../../redux/dataSlice';

const regions = require('../../data/regions.json');

const regionImgs = require.context('../../assets/region_maps', true);

const RegionMap = ({ section, nextPath, nextPathLabel }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { section } = useParams();

  const [showModal, setShowModal] = useState(false);
  // const [selectedState, setSelectedState] = useState(null);
  const [regionConfirmed, setRegionConfirmed] = useState(true);
  const [selectedRegion, setSelectedRegion] = useState(null);

  const handleMapClick = (evnt_or_region) => {
    const region = evnt_or_region.target
      ? evnt_or_region.target.parentElement.dataset.name ||
        evnt_or_region.target.parentElement.parentElement.dataset.name ||
        evnt_or_region.target.parentElement.parentElement.parentElement.dataset.name ||
        evnt_or_region.target.parentElement.parentElement.parentElement.parentElement.dataset.name
      : evnt_or_region;

    if (!region) {
      debugger;
    }

    // alert(region);
    // return;

    ReactGA.event({
      category: 'Map Region Selected',
      action: region
    });

    handleRegionChange(region);
    setShowModal(true);
  };

  const handleRegionChange = (region) => {
    ReactGA.event({
      category: 'Region Change',
      action: region
    });
    const regionData = _.find(regions, { region: region });
    setSelectedRegion(regionData);
  };

  const getRegionName = (region) => {
    return _.find(regions, { region: region }).regionName;
  };

  const renderModal = () => {
    if (!selectedRegion) return null;

    return (
      <Dialog open={showModal} onClose={() => setShowModal(false)} fullWidth maxWidth="lg">
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            position: 'absolute',
            zIndex: 1,
            width: '100%'
          }}
        >
          <IconButton
            aria-label="close"
            sx={{ cursor: 'pointer' }}
            onClick={() => setShowModal(false)}
          >
            <CancelIcon color="secondary" fontSize="large" />
          </IconButton>
        </div>
        <div className="flex" style={{ padding: 30 }}>
          <img
            src={regionImgs(`./${selectedRegion.region}.png`)}
            alt=""
            style={{ height: 600, maxWidth: 750 }}
          />
          <div className="flex direction-column " style={{ maxHeight: '50vh' }}>
            <div className="flex direction-column vertical-center">
              <div
                className="flex direction-column vertical-center"
                style={{ justifyContent: 'space-evenly' }}
              >
                <div className="center-text">
                  <Typography
                    variant="h4"
                    gutterBottom
                    color="secondary"
                    align="center"
                    sx={{ fontWeight: 800 }}
                  >
                    {selectedRegion.regionName} Region
                  </Typography>
                  Or
                  <Typography
                    variant="h6"
                    gutterBottom
                    color="text.otherGray"
                    align="center"
                    sx={{ fontWeight: 800 }}
                  >
                    Visit a Nearby Region
                  </Typography>
                  <div
                    className="flex-center"
                    style={{
                      justifyContent: 'space-between',
                      width: 375,
                      margin: '0 auto'
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ textTransform: 'unset', fontWeight: 800, minWidth: 175 }}
                      onClick={() => handleRegionChange(selectedRegion.prev)}
                    >
                      {getRegionName(selectedRegion.prev)}
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ textTransform: 'unset', fontWeight: 800, minWidth: 175 }}
                      onClick={() => handleRegionChange(selectedRegion.next)}
                    >
                      {getRegionName(selectedRegion.next)}
                    </Button>
                  </div>
                </div>
                <FormControlLabel
                  onChange={() => setRegionConfirmed(!regionConfirmed)}
                  checked={regionConfirmed}
                  // className="darkBlue"
                  control={<CustomCheckBox />}
                  label={`Confirm ${selectedRegion.regionName} Region`}
                  labelPlacement="end"
                />
              </div>
            </div>
            <Button
              variant="contained"
              color="purple"
              sx={{ width: 200, margin: '0 auto', textTransform: 'unset', fontWeight: 800 }}
              onClick={() => {
                ReactGA.event({
                  category: 'Region Confirmed',
                  action: selectedRegion.regionName
                });
                dispatch(setData({ region: selectedRegion.regionName }));
                setShowModal(false);
                navigate(`/${section}/topics`);
              }}
              disabled={!regionConfirmed}
            >
              Apply Region
            </Button>
          </div>
        </div>
      </Dialog>
    );
  };

  return (
    <Wrapper
      prevPath={`/${section}/topics`}
      nextPath={nextPath}
      nextPathLabel={nextPathLabel}
      containerWidth="lg"
    >
      <div style={{ padding: 5, backgroundColor: 'white', borderRadius: '5px' }}>
        <Typography variant="h6" component="div" color="text.otherGray" align="center">
          Select a region on the map
        </Typography>
        <USAMap handleMapClick={handleMapClick} />
      </div>
      {renderModal()}
    </Wrapper>
  );
};

export default RegionMap;
