import { Button, TextField, Typography, Grid } from '@mui/material';

import _ from 'lodash';

import PrivacyNotice from './PrivacyNotice';

const emailRgx =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const ContactModal = ({
  fields,
  handleChange,
  errors,
  submitForm,
  submitted,
  isSubmitDisabled,
  submitting,
  submittingError
}) => {
  const renderErrorText = (fieldName) => {
    if (errors[fieldName]) {
      let errorText = 'This field is required.';
      if (fieldName === 'email' && fields[fieldName].length && !emailRgx.test(fields[fieldName])) {
        errorText = 'Please enter a valid email address.';
      }
      return <span style={{ color: 'red' }}>{errorText}</span>;
    }
  };

  return (
    <div className="flex-center direction-column" style={{ padding: 30, minHeight: 400 }}>
      {submitted ? (
        <>
          {' '}
          <Typography
            variant="h4"
            gutterBottom
            color="primary"
            align="center"
            sx={{ fontWeight: 800 }}
          >
            Thank You!
          </Typography>
          <Typography variant="h5" gutterBottom align="center" style={{ fontWeight: 'bold' }}>
            Your ONE will reach out to you soon.
          </Typography>
        </>
      ) : (
        <>
          <Typography
            variant="h6"
            gutterBottom
            color="primary"
            align="center"
            sx={{ fontWeight: 800 }}
          >
            Please fill out the form below, and your local <br /> Oncology Nurse Educator will
            contact you.
          </Typography>
          <Grid container spacing={2} justifyContent={'space-around'} sx={{ margin: '30px 0' }}>
            <Grid item xs={12} md={6}>
              <TextField
                onChange={handleChange('firstName')}
                value={fields.firstName}
                label="First Name"
                required
                variant="outlined"
                inputProps={{ maxLength: 99 }}
                sx={{ width: '100%' }}
              />
              {renderErrorText('firstName')}
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                onChange={handleChange('lastName')}
                value={fields.lastName}
                label="Last Name"
                required
                variant="outlined"
                inputProps={{ maxLength: 99 }}
                sx={{ width: '100%' }}
              />
              {renderErrorText('lastName')}
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                onChange={handleChange('company')}
                value={fields.company}
                label="Company/Affiliation"
                required
                inputProps={{ maxLength: 99 }}
                variant="outlined"
                sx={{ width: '100%' }}
              />
              {renderErrorText('company')}
            </Grid>
            <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className="flex direction-column">
                <TextField
                  onChange={handleChange('degree')}
                  value={fields.degree}
                  label="Degree"
                  required
                  inputProps={{ maxLength: 99 }}
                  variant="outlined"
                  sx={{ width: '98%' }}
                />
                {renderErrorText('degree')}
              </div>
              <div className="flex direction-column">
                <TextField
                  onChange={handleChange('phone')}
                  value={fields.phone}
                  label="Phone"
                  required
                  variant="outlined"
                  inputProps={{ maxLength: 99 }}
                  sx={{ width: '98%' }}
                />
                {renderErrorText('phone')}
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                onChange={handleChange('email')}
                value={fields.email}
                label="Email"
                required
                variant="outlined"
                sx={{ width: '100%' }}
              />
              {renderErrorText('email')}
              <p style={{ color: 'red' }}>*Indicates a required field.</p>
              <PrivacyNotice />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                onChange={handleChange('message')}
                value={fields.message}
                label="Message"
                multiline
                rows={5}
                variant="outlined"
                sx={{ width: '100%' }}
              />
              {renderErrorText('message')}
              <Button
                sx={{ width: 180, marginTop: 5, textTransform: 'unset' }}
                className="bold"
                variant="contained"
                color={'purple'}
                disabled={isSubmitDisabled}
                onClick={submitForm}
              >
                {submitting ? 'Submitting...' : 'Submit'}
              </Button>
              {submittingError && (
                <Typography
                  variant="body1"
                  gutterBottom
                  align="center"
                  style={{ fontWeight: 'bold', color: 'red' }}
                >
                  Something went wrong. Please try again.
                </Typography>
              )}
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};

export default ContactModal;
