/* eslint-disable react/no-danger */
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import ReactGA from 'react-ga';
import { Button, Divider, Typography, Grid } from '@mui/material';

import ContactModal from './ContactModal';

import styles from '../../styles/One.module.scss';

const regions = require('../../data/regions.json');
const mapIcon = require('../../assets/map_icon.png');

// const onesData = require('../../data/ones.json');

const oneProfiles = require.context('../../assets/one_profiles', true);
const defaultProfileImg = require('../../assets/profile.png');

// const colors = require('../../styles/_colors.scss');

const One = ({ selectedRegion, handleRegionChange, hasLeaderBoard, toggleLeaderBoard }) => {
  // const [one, setOne] = useState({});
  const [ones, setOnes] = useState([]);
  const onesData = useSelector((state) => state.data.ones);

  const [contactModalOpen, setContactModalOpen] = useState(false);

  const toggleModal = () => {
    setContactModalOpen(!contactModalOpen);
  };

  useEffect(() => {
    // console.log('selectedRegion', selectedRegion);
    if (selectedRegion) {
      // setOne({});
      const selectedOnes = onesData[selectedRegion.region];
      // if (!selectedOne) {
      //   console.log('No one selected for region', selectedRegion.region);
      //   debugger;
      // }
      if (selectedOnes) {
        // ReactGA.event({
        //   category: 'One Selected',
        //   action: `${selectedRegion.region}: ${selectedOne.full_name}`
        // });
        // console.log('selectedOnes', selectedOnes);
        setOnes(selectedOnes);
      }
    }
  }, [selectedRegion]);

  const getRegionName = (region) => {
    return _.find(regions, { region }).regionName;
  };

  const getProfileImg = (oneData) => {
    try {
      return oneProfiles(`./${oneData.image_file}`);
    } catch {
      return defaultProfileImg;
    }
  };

  const renderOneRow = (oneData) => {
    // console.log('oneData', oneData);
    return (
      <Grid container style={{ marginBottom: 25 }} key={oneData.id}>
        <Grid item xs={2.5}>
          <div style={{ marginRight: 10, maxHeight: 150 }}>
            <img
              src={getProfileImg(oneData)}
              alt=""
              style={{ objectFit: 'cover', height: '100%', width: '100%' }}
            />
          </div>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="body2">
            <span style={{ fontWeight: 800, fontSize: 18 }}>{oneData.full_name}</span>
            <br />
            <span dangerouslySetInnerHTML={{ __html: oneData.bio }} />
          </Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <div className={styles.one}>
      <Typography variant="h5" className="purple" style={{ textAlign: 'center', fontWeight: 800 }}>
        Your ONE
        <br />
        {selectedRegion.regionName} Region
      </Typography>

      {hasLeaderBoard && (
        <div style={{ width: '99%' }}>
          <div
            className="flex-center"
            style={{ cursor: 'pointer', justifyContent: 'flex-end' }}
            onClick={toggleLeaderBoard}
            role="button"
            tabIndex={0}
          >
            View Map&nbsp;
            <img src={mapIcon} alt="map icon" width={20} />
          </div>
        </div>
      )}

      <Divider color="#f0a529" sx={{ height: 3, width: '100%', margin: '20px 0px' }} />

      <div
        className="flex-center direction-column"
        style={{ padding: 10, minHeight: 450, justifyContent: 'flex-start' }}
      >
        {ones.map((oneData) => {
          return renderOneRow(oneData);
        })}
        {ones.length > 1 && (
          <Typography
            variant="body2"
            className="purple"
            style={{ marginTop: 20, fontStyle: 'italic', textAlign: 'unset' }}
          >
            ONEs specialize in either Lung and GI or Gynecology, Hematology, and Breast Cancer. Your
            ONE visit will be based on the topic selected.
          </Typography>
        )}
      </div>

      <div className="flex-center direction-column" style={{ padding: '10px 0', width: '90%' }}>
        <Button
          style={{ textTransform: 'none', marginBottom: 20 }}
          color="darkYellow"
          variant="contained"
          onClick={toggleModal}
        >
          <span style={{ color: 'white', fontSize: 20 }}>
            Select a topic and have my ONE contact me
          </span>
        </Button>
        <Divider color="#f0a529" sx={{ height: 3, width: '100%', margin: '10px 0' }} />
      </div>

      {selectedRegion && (
        <>
          <Typography
            variant="h6"
            // gutterBottom
            color="black"
            align="center"
            sx={{ fontWeight: 800 }}
          >
            Visit a Nearby Region
          </Typography>
          <div
            className="flex-center"
            style={{ justifyContent: 'space-evenly', width: '70%', marginTop: 25 }}
          >
            <Button
              sx={{ width: 180 }}
              variant="contained"
              color="primary"
              style={{ textTransform: 'unset', fontWeight: 800 }}
              onClick={() => handleRegionChange(selectedRegion.prev)}
            >
              {getRegionName(selectedRegion.prev)}
            </Button>
            <Button
              sx={{ width: 180 }}
              variant="contained"
              color="primary"
              style={{ textTransform: 'unset', fontWeight: 800 }}
              onClick={() => handleRegionChange(selectedRegion.next)}
            >
              {getRegionName(selectedRegion.next)}
            </Button>
          </div>
        </>
      )}
      <ContactModal
        showModal={contactModalOpen}
        toggleModal={toggleModal}
        selectedRegion={selectedRegion}
      />
    </div>
  );
};

export default One;
