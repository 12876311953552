import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';

import styles from '../styles/Home.module.scss';

const azLogo = require('../assets/az_logo.png');

// const audioUrl = 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3';

const Home = () => {
  const navigate = useNavigate();

  // TODO: use grid intead of divs and flex for the layout

  return (
    <div className={styles.home}>
      <img src={azLogo} alt="az logo" className={styles.azLogo} />
      <h1 className="darkBlue center-text" style={{ backgroundColor: 'transparent' }}>
        WELCOME TO ONCOLOGY NURSES VERSUS CONNECT!
      </h1>

      <Typography variant={'h5'} className="dark-gray-text bold" style={{ textAlign: 'center' }}>
        You can select the Nurses Versus game or connect with your <br /> regional Oncology Nurse
        Educator (ONE)
      </Typography>

      <div className={styles.navBoxes}>
        <div
          className={`${styles.navBox} ${styles.navBoxGame}`}
          role="button"
          tabIndex={0}
          onClick={() => navigate('/game/topics')}
        />
        <div
          className={`${styles.navBox} ${styles.navBoxOne}`}
          role="button"
          tabIndex={0}
          onClick={() => navigate('/one/topics')}
        />
      </div>
      <p
        className="dark-gray-text"
        style={{
          textAlign: 'center',
          position: 'absolute',
          bottom: 0,
          width: '100%',
          fontSize: 12
        }}
      >
        &copy;2024 AstraZeneca. All rights reserved. US-74894 Last Updated 3/23
      </p>
    </div>
  );
};

export default Home;
