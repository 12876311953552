import { useState, useRef, useEffect } from 'react';
import _ from 'lodash';
import { isMobile, useMobileOrientation } from 'react-device-detect';
import { Container, Grid, Typography } from '@mui/material';
import { Timer } from '../common';

const bonusSpinTrapezoid = require('../../assets/game_bonus_round/bonus_spin_trapezoid.png');
const nursesVersusBanner = require('../../assets/nurses_versus_banner.png');
const xNoPoints = require('../../assets/game_bonus_round/X-0-Points.png');

const buttonImgs = require.context('../../assets/game_bonus_round', true);

const TILES = [
  { points: 25, img: buttonImgs('./25.png') },
  { points: 50, img: buttonImgs('./50.png') },
  { points: 75, img: buttonImgs('./75.png') },
  { points: 100, img: buttonImgs('./100.png') },
  { points: 0, img: buttonImgs('./X.png') }
];

const BunusSpin = ({ onEnd }) => {
  const [tiles, setTiles] = useState([]);
  const [currentTile, setCurrentTile] = useState(0);
  const [spinning, setGameSpinning] = useState(false);
  const [gameStopped, setGameStopped] = useState(false);

  const spinnerIntervalRef = useRef(null);
  const { isLandscape } = useMobileOrientation();

  const [isVertical, setIsVertical] = useState(false);

  useEffect(() => {
    const checkIfVertical = () => {
      // console.log('isMobile', isMobile, 'width', window.innerWidth);
      // 1150 px seems to start pushing the tiles off the grid
      setIsVertical((isMobile && !isLandscape) || (!isMobile && window.innerWidth < 1200));
    };
    window.addEventListener('resize', checkIfVertical);

    checkIfVertical();

    return () => {
      window.removeEventListener('resize', checkIfVertical);
    };
  }, []);

  const getRandomTiles = () => {
    return [...TILES, ...TILES, ...TILES, ...TILES, ...TILES.slice(0, 2)].map((tile, idx) => {
      return {
        ...tile,
        id: idx
      };
    });
  };

  const resetGame = () => {
    setGameSpinning(false);
    setGameStopped(false);
    setTiles(getRandomTiles());
    setCurrentTile(0);
  };

  useEffect(() => {
    setTiles(getRandomTiles());
    return resetGame;
  }, []);

  const toggleGame = () => {
    setGameSpinning(!spinning);
    if (spinning) {
      clearInterval(spinnerIntervalRef.current);
      setGameStopped(true);
      setTimeout(() => onEnd(tiles[currentTile].points), 4000);
    }
  };

  useEffect(() => {
    if (spinning) {
      spinnerIntervalRef.current = setInterval(() => {
        setCurrentTile((prevTile) => (prevTile === tiles.length - 1 ? 0 : prevTile + 1));
      }, 50);
    }
  }, [spinning]);

  // const isVertical = isMobile || dimensions.width < 900;

  const renderButtonOrTimer = () => {
    if (spinning || gameStopped) {
      const tileValue = tiles[currentTile].points;
      return gameStopped ? (
        <div style={{ textAlign: 'center' }}>
          <Typography variant="h4" sx={{ fontWeight: 800 }}>
            {tileValue === 0 ? 'Sorry, better luck next time' : 'Congratulations!'} <br />
          </Typography>
          <div className="flex-center">
            {tileValue === 0 && (
              <img src={xNoPoints} alt="no points" style={{ height: 70, marginRight: 10 }} />
            )}
            <Typography
              variant="h2"
              color={tileValue === 0 ? 'text.red' : 'text.lightBlue'}
              sx={{ fontSize: 78, fontWeight: 800 }}
            >
              {tileValue === 0 ? ' 0' : tileValue} Points{tileValue !== 0 && '!'}
            </Typography>
          </div>
        </div>
      ) : (
        <div
          role={'button'}
          tabIndex={0}
          onClick={toggleGame}
          style={{
            width: 280,
            height: 300,
            cursor: 'pointer',
            backgroundImage: `url(${buttonImgs('./stop_button.png')})`,
            backgroundSize: 'cover'
          }}
        />
      );
    }
    return (
      <div className="flex direction-column vertical-center horizontal-center">
        <img src={bonusSpinTrapezoid} alt="bonus spin" style={{ width: '40%' }} />
        <img src={nursesVersusBanner} alt="nurses versus" style={{ width: '70%' }} />
        <Typography variant="h4" color="text.lightBlue" sx={{ fontWeight: 800, padding: 2 }}>
          GET READY!
        </Typography>
        <Timer totalSeconds={3} interval={1000} onEnd={toggleGame} />
      </div>
    );
  };

  const getTiles = (startIdx, count) => {
    return tiles.slice(startIdx, count).map((tile) => {
      return (
        <Grid key={tile.id} item>
          <div
            style={{
              borderWidth: 6,
              borderColor:
                (spinning || gameStopped) && currentTile === tile.id ? 'white' : 'transparent',
              borderStyle: 'dotted',
              boxShadow:
                (spinning || gameStopped) && currentTile === tile.id ? '0 0 10px #e9f1f7' : null,
              borderRadius: '15%'
            }}
          >
            <div
              style={{
                height: isMobile ? 60 : 110,
                width: isMobile ? 60 : 120,
                borderRadius: '15%',
                fontSize: 24,
                fontWeight: 'bold',
                backgroundImage: `url(${tile.img})`,
                backgroundSize: 'auto 100%'
              }}
            />
          </div>
        </Grid>
      );
    });
  };

  return (
    <div>
      <Container maxWidth="lg" sx={{ margin: '200px auto' }}>
        <Grid container spacing={2}>
          <Grid
            container
            item
            // spacing={2}
            wrap={'nowrap'}
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            {isVertical ? getTiles(0, 5) : getTiles(0, 8)}
          </Grid>
          <Grid container item wrap={'nowrap'} justifyContent={'space-between'}>
            <Grid
              wrap={'nowrap'}
              direction={'column-reverse'}
              container
              item
              xs={2}
              spacing={2}
              justifyContent={'center'}
              alignItems={'flex-start'}
            >
              {isVertical ? getTiles(16, 22, 'auto') : getTiles(19, 22, 2)}
            </Grid>
            <Grid
              item
              justifyContent={'center'}
              alignItems={'center'}
              xs={8}
              sx={{
                fontSize: 24,
                fontWeight: 'bold',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              {renderButtonOrTimer()}
            </Grid>
            <Grid direction={'column'} container item xs={2} spacing={2} alignItems={'flex-end'}>
              {isVertical ? getTiles(5, 11, 'auto') : getTiles(8, 11)}
            </Grid>
          </Grid>
          <Grid
            container
            item
            // spacing={2}
            justifyContent={'space-between'}
            alignItems={'center'}
            direction={'row-reverse'}
            wrap={'nowrap'}
          >
            {isVertical ? getTiles(11, 16) : getTiles(11, 19)}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default BunusSpin;
