import { useState, useEffect } from 'react';

import { useNavigate, useMatch } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// import IconButton from '@mui/material/IconButton';

// import { Home, VolumeUp, VolumeOff } from '@mui/icons-material';

import { resetData } from '../../redux/dataSlice';

const azLogo = require('../../assets/az_logo.png');
const azLogoWhite = require('../../assets/az_logo_white.png');

const HeaderNav = ({ color, standAlone }) => {
  const topic = useSelector((state) => state.data.topic);
  const region = useSelector((state) => state.data.region);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logoImg = color === 'light' ? azLogoWhite : azLogo;

  const routeMatch = useMatch('/:section/topics/*');

  const isSpecialCase = () => {
    if (window.location.search.indexOf('useAllQuestions') < 0) {
      return true;
    }
    if (window.location.search.indexOf('questionId') < 0) {
      return true;
    }
  };

  useEffect(() => {
    if (!routeMatch) {
      if ((!topic || !region) && !isSpecialCase()) {
        navigate('/');
      }
    }
  }, [routeMatch]);

  // console.log('color', color);

  // const [audio] = useState(
  //   new Audio('https://nurses-versus-beta.s3.amazonaws.com/audio/pt_music.mp3')
  // );
  // const [isPlaying, setIsPlaying] = useState(false);

  // useEffect(() => {
  //   audio.addEventListener('ended', () => {
  //     setIsPlaying(false);
  //   });
  //   // setTimeout(() => {
  //   //   setIsPlaying(true);
  //   // }, 500);
  //   return () => {
  //     audio.removeEventListener('ended', () => {
  //       setIsPlaying(false);
  //     });
  //   };
  // }, []);

  // useEffect(() => {
  //   if (isPlaying) {
  //     audio.play();
  //   } else {
  //     audio.pause();
  //   }
  // }, [isPlaying]);

  return (
    <div
      className="flex vertical-center"
      style={{
        color: color,
        position: 'absolute',
        top: 0,
        justifyContent: 'space-between',
        width: '100%',
        marginTop: 15,
        padding: '0 10px'
      }}
    >
      <span className="flex vertical-center">
        <img
          src={logoImg}
          alt="az logo"
          style={{
            width: 200,
            marginRight: 30,
            position: 'relative',
            top: 10,
            left: 10,
            cursor: 'pointer'
          }}
          role={'presentation'}
          onClick={() => {
            dispatch(resetData());
            navigate(standAlone ? '/standalone/topics' : '/');
          }}
        />
      </span>
      {/* <span className={`bold ${color}`}>{topic}</span> */}
    </div>
  );
};

export default HeaderNav;
