import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, IconButton } from '@mui/material';
import ReactGA from 'react-ga';
import CancelIcon from '@mui/icons-material/Cancel';

import _ from 'lodash';

import { HeaderNav } from '../common';
import Leaderboard from './LeaderBoard';
import One from './One';

const regions = require('../../data/regions.json');

const regionImgs = require.context('../../assets/region_maps', true);

const OneAndLeaderBoard = ({ hasLeaderBoard, standAlone }) => {
  const [showLeaderBoard, setShowLeaderBoard] = useState(hasLeaderBoard);

  const region = useSelector((state) => state.data.region);

  const [selectedRegion, setSelectedRegion] = useState(_.find(regions, { regionName: region }));

  if (!selectedRegion) {
    // debugger;
    return <div>Loading...</div>;
  }

  // debugger;

  const handleRegionChange = (rgn) => {
    // const regionData = regions.find((region) => region.region === regionName);

    const regionData = _.find(regions, { region: rgn });
    ReactGA.event({
      category: 'Region Change',
      action: regionData.regionName
    });
    setSelectedRegion(regionData);
  };

  const toggleLeaderBoard = () => {
    setShowLeaderBoard(!showLeaderBoard);
  };

  const renderLeaderboardOrMap = () => {
    if (!region) return null;

    if (hasLeaderBoard && showLeaderBoard) {
      ReactGA.event({
        category: 'Leaderboard',
        action: 'Leaderboard rendered'
      });
      return <Leaderboard />;
    }

    ReactGA.event({
      category: 'Region Map',
      action: 'Region Map rendered'
    });
    return (
      <div className="flex-center" style={{ position: 'relative', height: 600, maxWidth: 750 }}>
        {hasLeaderBoard && (
          <IconButton
            onClick={toggleLeaderBoard}
            aria-label="close"
            sx={{ cursor: 'pointer', position: 'absolute', top: 0, right: 10 }}
          >
            <CancelIcon color="secondary" fontSize="large" />
          </IconButton>
        )}
        <img
          src={regionImgs(`./${selectedRegion.region}.png`)}
          alt=""
          // height={650}
          style={{ objectFit: 'contain', width: '100%', height: '100%' }}
        />
      </div>
    );
  };

  // console.log('selectedRegion', selectedRegion);

  return (
    <>
      <HeaderNav color="purple" hideSong standAlone={standAlone} />
      <Grid
        container
        spacing={2}
        justifyContent={'center'}
        // alignItems={'center'}
        sx={{ marginTop: 10 }}
      >
        <Grid item xs={10} md={5}>
          {renderLeaderboardOrMap()}
        </Grid>
        <Grid item xs={10} md={5}>
          <One
            selectedRegion={selectedRegion}
            handleRegionChange={handleRegionChange}
            hasLeaderBoard={hasLeaderBoard}
            toggleLeaderBoard={toggleLeaderBoard}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default OneAndLeaderBoard;
