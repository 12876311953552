import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import config from '../config';

// const questions = require('../data/questions.json');

// console.log('process.env', process.env);

const dataSlice = createSlice({
  name: 'data',
  initialState: {
    allQuestions: [],
    seenQuestions: [],
    error: false,
    loaded: false,
    loading: false,
    // topic: process.env.NODE_ENV === 'production' ? null : process.env.REACT_APP_TOPIC,
    topic: 'General',
    otherTopic: 'Pop Culture',
    region: process.env.NODE_ENV === 'production' ? null : process.env.REACT_APP_REGION,
    ones: null,
    one: null,
    leaderBoard: [],
    opponent: [200, 300, 500, 600, 850]
  },
  reducers: {
    resetData(state) {
      state.seenQuestions = [];
      state.error = false;
      // state.topic = null;
      state.region = null;
      state.one = null;
    },
    setData(state, action) {
      Object.assign(state, action.payload);
    },
    setLeaderBoard(state, action) {
      state.leaderBoard = action.payload;
      state.opponent = action.payload[0].progression.map((score) => parseInt(score, 10));
      // console.log('state', state);
    },
    setError(state, action) {
      state.error = action.payload.error;
    }
  }
});

export const { resetData, setError, setData, setLeaderBoard } = dataSlice.actions;

export const getInitialData = () => (dispatch, getState) => {
  dispatch(setData({ loading: true }));

  Promise.all([
    config.fetch(`${config.baseApiUrl}/api/questions`),
    config.fetch(`${config.baseApiUrl}/api/leaders`),
    config.fetch(`${config.baseApiUrl}/api/ones`)
  ])
    .then(([questionsData, leaderData, oneData]) => {
      Promise.all([questionsData.json(), leaderData.json(), oneData.json()]).then(
        ([questions, leaders, ones]) => {
          dispatch(
            setData({
              allQuestions: questions,
              leaderBoard: leaders,
              ones: ones,
              opponent: leaders[0].progression.map((score) => parseInt(score, 10)),
              loaded: true,
              loading: false
            })
          );
        }
      );
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log('error fetching data', error);
    });
};

// export const getOne = () => (dispatch, getState) => {
//   dispatch(setData({ loading: true }));
//   const { auth } = getState();
//   config
//     .fetch(`${config.baseApiUrl}/api/one`, {
//       headers: auth.headers
//     })
//     .then((response) => {
//       response.json().then((oneData) => {
//         dispatch(setData({ one: oneData }));
//       });
//     });
// };

// eslint-disable-next-line max-len
// export const toggleSessionRegistration = (session, updateSuccessStatus) => (dispatch, getState) => {
//   const { auth, data } = getState();
//   const wasRegistered = _.includes(data.mySessions, session.id);
//   config
//     .fetch(`${config.baseApiUrl}/api/toggleregistration`, {
//       headers: auth.headers,
//       method: 'POST',
//       body: JSON.stringify({
//         session: session.id,
//         action: wasRegistered ? 'unregister' : 'register'
//       })
//     })
//     .then((response) => {
//       if (response.status !== 404) {
//         response.json().then((sessionData) => {
//           const { mySessions } = sessionData;

//           setTimeout(() => dispatch(setData({ mySessions })), 1250);
//           this delay is keeping the session in the list for a bit longer
//           updateSuccessStatus(response.status);
//         });
//       }
//     });
// };

export default dataSlice.reducer;
