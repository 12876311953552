import { Routes, Route, useParams } from 'react-router-dom';

import TopicAndZipSearch from './TopicAndZipSearch';
import RegionsMaps from './RegionsMap';

const TopicAndRegion = () => {
  const { section } = useParams();

  const componentProps = {
    nextPath: section === 'game' ? '/game/intro' : '/one',
    nextPathLabel: section === 'game' ? 'PLAY GAME!' : 'FIND MY ONE',
    section
  };

  if (section === 'standalone') {
    componentProps.nextPath = '/standalone/one';
  }

  return (
    <Routes>
      <Route path="/map" element={<RegionsMaps {...componentProps} />} />
      <Route path="/" element={<TopicAndZipSearch {...componentProps} />} />
    </Routes>
  );
};

export default TopicAndRegion;
