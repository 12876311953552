import { useState, useEffect, useRef } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import colors from '../../styles/_colors.scss';

const Timer = (props) => {
  const { onEnd, totalSeconds, interval } = props;

  const intervalRef = useRef(null);
  const [timerValue, setTimerValue] = useState(totalSeconds);

  useEffect(() => {
    intervalRef.current = setInterval(
      () => setTimerValue((prevTimerValue) => prevTimerValue - 1),
      interval
    );
    return () => clearInterval(intervalRef.current);
  }, []);

  useEffect(() => {
    if (timerValue === 0) {
      setTimeout(onEnd, 700);
    }
  }, [timerValue]);

  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        variant="determinate"
        value={Math.round((100 / totalSeconds) * timerValue)}
        thickness={3}
        size={130}
        sx={{
          color: colors.lightblue
        }}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="h2" component="div" color={colors.lightblue}>
          <span>
            <div style={{ fontWeight: 600, fontSize: '4rem' }}>{timerValue}</div>
          </span>
        </Typography>
      </Box>
    </Box>
  );
};

export default Timer;
