import colors from './styles/_colors.scss';

export default {
  palette: {
    primary: {
      main: colors.darkblue
    },
    secondary: {
      main: colors.purple
    },
    purple: {
      main: colors.purple,
      contrastText: colors.white
    },
    neutral: {
      main: colors.lightgray,
      contrastText: colors.darkblue
    },
    light: {
      main: colors.white,
      contrastText: colors.darkblue
    },
    darkYellow: {
      main: colors.yellow,
      contrastText: colors.darkblue
    },
    midLightGray: {
      main: colors.midLightGray,
      contrastText: colors.darkblue
    },
    otherGray: {
      main: colors.otherGray
    },
    text: {
      purple: colors.white,
      neutral: colors.darkblue,
      green: colors.green,
      lightBlue: colors.lightblue,
      red: colors.red,
      darkYellow: colors.yellow,
      otherGray: colors.otherGray
    },
    action: {
      disabledBackground: null, // don't set the disable background color
      disabled: colors.midgray
    }
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: { borderRadius: 0 }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff'
        }
      }
    },
    MuiStep: {
      styleOverrides: {
        root: {
          padding: 0
        }
      }
    },
    MuiStepConnector: {
      styleOverrides: {
        line: {
          borderTop: '4px solid #fff',
          position: 'relative',
          top: 6
        }
      }
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          'fill': '#fff',
          'width': 40,
          'height': 40,
          '&.Mui-active': {
            border: '5px solid #5EC2D2',
            borderRadius: '50%'
          }
        },
        text: {
          fill: colors.darkblue,
          fontWeight: 'bold'
        }
      }
    }
  }
};
