import { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Grid, Button } from '@mui/material';

import styles from '../../styles/LeaderBoard.module.scss';

import config from '../../config';
import { setLeaderBoard } from '../../redux/dataSlice';

const leaderBoardBanner = require('../../assets/leaderboard_banner.png');

const LeaderBoard = ({ pollingInterval, bannerWidth, isLiveLeaderBoard }) => {
  const navigate = useNavigate();
  const leaderBoard = useSelector((state) => state.data.leaderBoard);

  const dispatch = useDispatch();
  const getLeaderBoard = () => {
    config.fetch(`${config.baseApiUrl}/api/leaders`).then((response) => {
      response.json().then((leaders) => {
        dispatch(setLeaderBoard(leaders));
      });
    });
  };

  useEffect(() => {
    let polling = null;
    if (pollingInterval) {
      polling = setInterval(() => {
        getLeaderBoard();
      }, pollingInterval);
    }
    return () => {
      clearInterval(polling);
    };
  }, []);

  return (
    <div className={styles.leaderBoard}>
      <img
        src={leaderBoardBanner}
        alt="leaderboard banner"
        width={bannerWidth || 500}
        // style={{ border: '1px solid blue' }}
      />
      <Grid container item xs={10} sx={{ marginTop: 5 }} className={isLiveLeaderBoard ? 'live-leaderboard-wrapper' : ''}>
        <Grid container item spacing={2} key={'totalrow'} className={styles.row}>
          <Grid item xs={3} />
          <Grid item xs={6} />
          <Grid
            item
            xs={3}
            className={`${styles.column}`}
            sx={{ color: isLiveLeaderBoard && 'white', marginBottom: 1 }}
          >
            TOTAL
          </Grid>
        </Grid>
        {leaderBoard.map((leader, idx) => {
          return (
            <Grid
              container
              item
              spacing={2}
              sx={{ marginBottom: 2 }}
              key={idx}
              className={styles.row}
            >
              <Grid
                item
                xs={3}
                className={`${styles.column} ${styles.outerColumn} ${
                  leader.self && styles.outerColumnSelf
                }`}
              >
                <span>{idx + 1} </span>
              </Grid>
              <Grid
                item
                xs={6}
                className={`${styles.column} ${styles.nameColumn} ${
                  leader.self && styles.nameColumnSelf
                }`}
              >
                {leader.name}
              </Grid>
              <Grid
                item
                xs={3}
                className={`${styles.column} ${styles.outerColumn} ${
                  leader.self && styles.outerColumnSelf
                }`}
              >
                {leader.score}
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      {!pollingInterval && (
        <Button
          variant="contained"
          color="purple"
          sx={{ width: 200, margin: '0 auto' }}
          onClick={() => navigate('/game/intro')}
        >
          Play Again?
        </Button>
      )}
    </div>
  );
};

export default LeaderBoard;
