import { useNavigate } from 'react-router-dom';

import { Container, Typography } from '@mui/material';

import buttonStyles from '../../styles/ButtonStyles.module.scss';

const bannerImg = require('../../assets/nurses_versus_banner.png');
const x_icon = require('../../assets/game_bonus_round/X.png');

const Intro = () => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="lg" sx={{ height: '100%' }}>
      <div
        className="flex direction-column vertical-center horizontal-center"
        style={{ marginTop: 150, padding: 50 }}
      >
        <img src={bannerImg} alt="Nurses versus" width={600} style={{ width: '100%' }} />
        <div style={{ textAlign: 'center', lineHeight: 2.2, marginBottom: 20 }}>
          <Typography
            variant="h5"
            color={'text.lightBlue'}
            align="center"
            sx={{ fontWeight: 600, marginTop: 5, fontSize: '2.0rem' }}
          >
            How to play:
          </Typography>
          <Typography variant="body" style={{ position: 'relative', fontSize: '1.5rem' }}>
            Start out with a bonus spin to test your luck. <br />
            Next, answer each question as it appears. Correct answers earn 50 points. <br />
            For every correct answer, you earn a bonus spin for a chance to earn more points.
            <br />
            In your bonus spin, if you land on an{' '}
            <img
              alt="X"
              src={x_icon}
              width={24}
              style={{ textAlign: 'center', position: 'relative', top: 5 }}
            />{' '}
            you don&apos;t get any extra points for that round.
          </Typography>
        </div>

        <div
          onClick={() => navigate('/game')}
          role="button"
          tabIndex={0}
          className={`flex-center ${buttonStyles.outerButton}`}
        >
          <div className={`flex vertical-center horizontal-center ${buttonStyles.innerButton}`}>
            <span style={{ fontSize: 22, padding: '5px 40px' }}>
              Let&apos;s Start With a Free Bonus Spin!
            </span>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Intro;
