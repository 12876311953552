/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, useSearchParams } from 'react-router-dom';
import ReactGA from 'react-ga';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import { Modal, Box, Button, TextField } from '@mui/material';

import LinearProgress from '@mui/material/LinearProgress';
import styles from '../../styles/Game.module.scss';
import { ContentWrapper } from '../common';
import Intro from './Intro';
import BonusSpin from './BonusSpin';
import Question from './Question';

import config from '../../config';

import { setData, setLeaderBoard } from '../../redux/dataSlice';

const Game = () => {
  const [questions, setQuestions] = useState(null);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [bonusSpin, setBonusSpin] = useState(true);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [userName, setUserName] = useState('');

  const [score, setScore] = useState(0);
  const [scoreProgress, setScoreProgress] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [queryParams, setQueryParams] = useSearchParams();

  const { topic, otherTopic } = useSelector((state) => state.data);
  const seenQuestions = useSelector((state) => state.data.seenQuestions);
  const allQuestions = useSelector((state) => state.data.allQuestions);
  const leaderBoard = useSelector((state) => state.data.leaderBoard);

  useEffect(() => {
    if (!questions) {
      setLoading(true);

      let gameQuestions = [];
      if (queryParams.get('useAllQuestions')) {
        gameQuestions = allQuestions;
      } else if (queryParams.get('questionId')) {
        gameQuestions = [
          _.find(allQuestions, (q) => q.id === parseInt(queryParams.get('questionId'), 10))
        ];
      } else {
        gameQuestions = getQuestions(seenQuestions);
        if (gameQuestions.length < 5) {
          gameQuestions = getQuestions([]);
          dispatch(setData({ seenQuestions: gameQuestions.map((q) => q.id) }));
        } else {
          dispatch(
            setData({ seenQuestions: seenQuestions.concat(gameQuestions.map((q) => q.id)) })
          );
        }
      }
      // console.log('gameQuestions', gameQuestions, 'queryParams', queryParams, 'category', topic);
      setQuestions(gameQuestions);
      setLoading(false);
    }
  }, []);

  const getQuestions = (alreadySeen) => {
    const shuffledQuestions = _.shuffle(allQuestions);
    return _.shuffle(
      shuffledQuestions
        // filter all questions by category and remove already seen questions, then splice first 4
        // (General Oncology Questions)
        .filter((q) => q.category === topic && !_.includes(alreadySeen, q.id))
        .splice(0, 4)
        .concat(
          shuffledQuestions
            // filter all questions by other category and
            // remove already seen questions, then splice first 1
            // (Pop Culture Questions)
            .filter((q) => q.category === otherTopic && !_.includes(alreadySeen, q.id))
            .splice(0, 1)
        )
    );
  };

  // OLD QUESTION LOADER. KEEP FOR FUTURE NEED TO SELECT
  // useEffect(() => {
  //   if (!questions) {
  //     setLoading(true);

  //     let gameQuestions = [];
  //     if (queryParams.get('useAllQuestions')) {
  //       gameQuestions = allQuestions;
  //     } else if (queryParams.get('questionId')) {
  //       gameQuestions = [
  //         _.find(allQuestions, (q) => q.id === parseInt(queryParams.get('questionId'), 10))
  //       ];
  //     } else {
  //       gameQuestions = _.shuffle(
  //         allQuestions.filter((q) => q.category === topic && !_.includes(seenQuestions, q.id))
  //       ).splice(0, 5);
  //       if (gameQuestions.length < 5) {
  //         gameQuestions = _.shuffle(allQuestions.filter((q) => q.category === topic)).splice(0, 5);
  //         dispatch(setData({ seenQuestions: gameQuestions.map((q) => q.id) }));
  //       } else {
  //         dispatch(
  //           setData({ seenQuestions: seenQuestions.concat(gameQuestions.map((q) => q.id)) })
  //         );
  //       }
  //     }
  //     console.log('gameQuestions', gameQuestions, 'queryParams', queryParams, 'category', topic);
  //     setQuestions(gameQuestions);
  //     setLoading(false);
  //   }
  // }, []);

  const updateScore = (points) => {
    setScore((prevScore) => prevScore + points);
  };

  const handleNextQuestion = () => {
    // console.log('handleNextQuestion', currentQuestion, questions.length);
    setScoreProgress((prevProgress) => prevProgress.concat(score));
    const isLastQuestion = currentQuestion === questions.length - 1;
    // console.log('isLastQuestion', isLastQuestion);
    if (isLastQuestion) {
      setShowModal(true);
    } else {
      setCurrentQuestion(currentQuestion !== null ? currentQuestion + 1 : 0);
    }
  };

  const onQuestionEnd = (answer) => {
    if (answer.is_correct && !queryParams.get('useAllQuestions')) {
      setBonusSpin(true);
    } else {
      handleNextQuestion();
    }
  };

  const onBounusSpinEnd = (points) => {
    ReactGA.event({
      category: 'Bonus Spin',
      action: `Points: ${points}`
    });
    updateScore(points);
    handleNextQuestion();
    setBonusSpin(false);
  };

  const saveToLeaderBoard = () => {
    ReactGA.event({
      category: 'Save to Leaderboard',
      action: `Name: ${userName} Score: ${score}`
    });
    return config
      .fetch(`${config.baseApiUrl}/api/leaders`, {
        method: 'POST',
        body: JSON.stringify({ name: userName, score: score, progression: scoreProgress.join(',') })
      })
      .then((newLeaderBoardData) => {
        newLeaderBoardData.json().then((leaders) => {
          dispatch(setLeaderBoard(leaders));
          return leaders;
        });
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
  };

  const goToLeaderBoard = async () => {
    // add user to leaderboard

    // remove previous self reference from leaderboard entries

    if (userName) {
      setSaving(true);
      await saveToLeaderBoard();
      setSaving(false);
    } else {
      const newLeaderBoard = _.map(leaderBoard, (leader) => _.omit(leader, 'self'));
      dispatch(setData({ leaderBoard: newLeaderBoard }));
    }

    // navigate('/leaderboard');
    toggleModal();
    navigate('/leaderboard');
  };

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const renderModal = () => {
    return (
      <Modal open={showModal}>
        <Box
          className="darkBlue"
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '40%',
            borderRadius: '15px',
            bgcolor: 'white',
            boxShadow: 24,
            p: 4
          }}
        >
          <div
            className="flex direction-column vertical-center horizontal-center center-text"
            style={{ height: 400, justifyContent: 'space-evenly' }}
          >
            {_.last(leaderBoard).score <= score ? (
              <>
                <h1 className="darkBlue">
                  Congratulations!
                  <br /> You&apos;ve made the leaderboard!
                </h1>
                <p>Enter a name below to appear on the leaderboard</p>
                <TextField
                  variant="outlined"
                  color="primary"
                  value={userName}
                  onChange={(event) => setUserName(event.target.value)}
                />
              </>
            ) : (
              <>
                <h1 className="darkBlue">Sorry, you didn&apos;t make the leaderboard</h1>
                <p>Better luck next time</p>
              </>
            )}
            <Button
              variant="contained"
              color="purple"
              disabled={saving}
              sx={{ width: 200, margin: '0 auto' }}
              onClick={goToLeaderBoard}
            >
              {saving ? 'Saving data...' : 'View LeaderBoard'}
            </Button>
          </div>
        </Box>
      </Modal>
    );
  };

  const renderContent = () => {
    if (loading || !questions) {
      return <LinearProgress />;
    }

    // these routes assume the /game prefix is already in the url. check App.js for the route.
    return (
      <Routes>
        <Route path="/intro" element={<Intro />} />
        <Route
          path="/"
          element={
            bonusSpin ? (
              <BonusSpin onEnd={onBounusSpinEnd} />
            ) : (
              <Question
                question={questions[currentQuestion]}
                activeStep={queryParams.get('useAllQuestions') ? 1 : currentQuestion}
                score={score}
                updateScore={updateScore}
                onEnd={onQuestionEnd}
              />
            )
          }
        />
      </Routes>
    );
  };

  return (
    <ContentWrapper hasCurtains>
      <div className={styles.game}>
        {renderModal()}
        {renderContent()}
      </div>
    </ContentWrapper>
  );
};

export default Game;
