import { useState, useEffect } from 'react';
import ReactGA from 'react-ga';

import { Dialog, IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import ArrotBackIcon from '@mui/icons-material/ArrowBack';

import _ from 'lodash';

import TopicSelect from './TopicSelect';
import ContactForm from './ContactForm';

import config from '../../../config';

const initialFields = {
  firstName: '',
  lastName: '',
  company: '',
  degree: '',
  phone: '',
  email: '',
  message: ''
};

const emailRgx =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const ContactModal = ({ showModal, toggleModal, selectedRegion, One }) => {
  const [formStep, setFormStep] = useState('topics');
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [fields, setFields] = useState({ ...initialFields });
  const [errors, setErrors] = useState({});
  const [submittingError, setSubmittingError] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const onClose = () => {
    toggleModal();
    setTimeout(() => {
      setFormStep('topics');
      setSelectedTopics([]);
      setFields({ ...initialFields });
      setErrors({});
      setSubmitted(false);
      setSubmittingError(false);
    }, 100);
  };

  const submitForm = () => {
    ReactGA.event({
      category: 'Contact ONE',
      action: `One Contact Form Submit: ${selectedRegion.regionName}`
    });

    // debugger;

    const isValid = validate();
    if (isValid) {
      setSubmitting(true);

      fetch(`${config.baseApiUrl}/api/contactone`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          region: selectedRegion.regionName,
          topics: selectedTopics.join(', '),
          ...fields
        })
      }).then((res) => {
        if (res.status !== 200) {
          setSubmittingError(true);
          setSubmitting(false);
          return;
        }
        setSubmitting(false);
        setSubmitted(true);
      });
    }
  };

  useEffect(() => {
    checkIfSubmitDisabled();
  }, [fields]);

  const handleTopicChange = (topic) => (event) => {
    if (event.target.checked) {
      setSelectedTopics([...selectedTopics, topic]);
    } else {
      setSelectedTopics(_.filter(selectedTopics, (tpc) => tpc !== topic));
    }
  };

  const checkIfSubmitDisabled = () => {
    const requiredFields = _.omit(fields, ['message']);
    setIsSubmitDisabled(_.compact(_.values(requiredFields)).length < _.keys(requiredFields).length);
  };

  const handleChange = (fieldName) => (event) => {
    setFields({ ...fields, [fieldName]: event.target.value });
    setErrors({ ..._.omit(errors, fieldName) });
  };

  const validate = () => {
    const validationErrors = {};
    _.forEach(_.keys(fields), (field) => {
      if (field !== 'message') {
        validationErrors[field] = fields[field].length === 0;
        if (field === 'email' && !emailRgx.test(fields[field])) {
          validationErrors[field] = true;
        }
      }
    });
    setErrors(validationErrors);
    return _.every(_.values(validationErrors), (value) => value === false);
  };

  return (
    <Dialog
      open={showModal}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      // sx={{ position: 'absolute', top: -150 }}
    >
      {formStep !== 'topics' && !submitted && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            position: 'absolute',
            top: 10,
            left: 10,
            zIndex: 2,
            border: '2px solid lightgray',
            padding: 8,
            fontWeight: 'bold',
            cursor: 'pointer'
          }}
          onClick={() => setFormStep('topics')}
          role="button"
          tabIndex={0}
        >
          <ArrotBackIcon />
          &nbsp;&nbsp;BACK TO TOPICS
        </div>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          position: 'absolute',
          right: 5,
          top: 5,
          zIndex: 1,
          width: '100%'
        }}
      >
        <IconButton aria-label="close" sx={{ cursor: 'pointer' }} onClick={onClose}>
          <CancelIcon color="secondary" fontSize="large" />
        </IconButton>
      </div>
      {formStep === 'topics' ? (
        <TopicSelect
          selectedTopics={selectedTopics}
          handleTopicChange={handleTopicChange}
          setFormStep={setFormStep}
        />
      ) : (
        <ContactForm
          fields={fields}
          handleChange={handleChange}
          errors={errors}
          submitForm={submitForm}
          submitted={submitted}
          isSubmitDisabled={isSubmitDisabled}
          submitting={submitting}
          submittingError={submittingError}
        />
      )}
      {!submitted && (
        <p style={{ marginLeft: 10, fontSize: 12 }}>
          &copy;2024 AstraZeneca. All rights reserved. US-86529 Last Updated 3/24
        </p>
      )}
    </Dialog>
  );
};

export default ContactModal;
