import { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';

import { Container } from '@mui/material';

import LeaderBoard from './LeaderBoard';

import { ContentWrapper } from '../common';

const nursesVersusBanner = require('../../assets/nurses_versus_banner.png');
const azLogo = require('../../assets/az_logo_white.png');

const leaderBoardVideo = require('../../assets/leaderboard-video.mp4');

const LiveLeaderboard = () => {
  const [playVideo, setPlayVideo] = useState(false);
  const reactPlayer = useRef(null);

  const setVideoPlayerInterval = () => {
    setTimeout(() => {
      setPlayVideo(true);
    }, 90000);
  };

  useEffect(() => {
    setVideoPlayerInterval();
  }, []);

  return (
    <>
      <ReactPlayer
        ref={reactPlayer}
        url={leaderBoardVideo}
        width={'100vw'}
        height={'100vh'}
        muted
        playing={playVideo}
        style={{ overflow: 'clip', display: playVideo ? 'block' : 'none' }}
        onEnded={() => {
          setPlayVideo(false);
          reactPlayer.current.seekTo(0);
          setVideoPlayerInterval();
        }}
      />
      {!playVideo && (
        <ContentWrapper hasCurtains hideHeader>
          <Container maxWidth={'lg'}>
            <div className="flex-center">
              <img
                src={nursesVersusBanner}
                alt="nurses versus"
                style={{
                  width: '37%',
                  margin: '10px auto'
                }}
              />
            </div>
            {/* <Container maxWidth={'lg'}> */}
            <LeaderBoard
              hideButton
              pollingInterval={1000 * 10}
              bannerWidth={800}
              isLiveLeaderBoard
            />
            {/* </Container> */}

            <div className="flex-center direction-column">
              <img src={azLogo} alt="AstraZeneca" width={'20%'} />
              <p>&copy;2022 AstraZeneca. All rights reserved. US-62595 Last Updated 3/22</p>
            </div>
          </Container>
        </ContentWrapper>
      )}
    </>
  );
};

export default LiveLeaderboard;
