import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga';
import { useSelector, useDispatch } from 'react-redux';

import {
  Button,
  Divider,
  // FormGroup,
  // FormControlLabel,
  TextField,
  Typography
  // Grid
} from '@mui/material';

import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

// import { CustomCheckBox } from '../common';

import { setData } from '../../redux/dataSlice';

import Wrapper from './Wrapper';

const zipRegionMapping = require('../../data/zipRegionMapping.json');

const TopicAndRegion = ({ section, nextPath, nextPathLabel }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showKeyboard, setShowKeyboard] = useState(false);

  const [zipCode, setZipCode] = useState('');

  const [showRegionSearch, setShowRegionSearch] = useState(false);

  const region = useSelector((state) => state.data.region);
  const topic = useSelector((state) => state.data.topic);

  // useEffect(() => {
  //   console.log('topic', topic, 'section', section);
  // }, [topic]);

  const toggleKeyboard = () => {
    setShowKeyboard(!showKeyboard);
  };

  const handleTopicChange = (topicName) => {
    return (event) => {
      if (event.target.checked) {
        ReactGA.event({
          category: 'Topic Selected',
          action: topicName
        });
        dispatch(setData({ topic: topicName }));
      }
    };
  };

  const handleRegionSearch = () => {
    dispatch(setData({ region: null }));
    setShowRegionSearch(false);

    const regionByZip = zipRegionMapping[zipCode];
    ReactGA.event({
      category: 'Zip Code search',
      action: `${zipCode}: ${regionByZip || 'not found'}`
    });

    if (regionByZip) {
      dispatch(setData({ region: regionByZip }));
    } else {
      setShowRegionSearch(true);
    }
  };

  // const nextPath = section === 'game' ? '/game/intro' : '/one';
  // const nextPathLabel = section === 'game' ? 'PLAY GAME' : 'FIND MY ONE';

  const dividerColor = section === 'game' ? 'white' : 'gray';

  const onKeyBoardKeyPress = (button) => {
    if (button === 'SUBMIT') {
      handleRegionSearch();
      toggleKeyboard();
      return;
    }
    if (button === 'DEL') {
      setZipCode((prevZipCode) => prevZipCode.slice(0, -1));
      return;
    }
    setZipCode((prevZipCode) => prevZipCode + button);
  };

  const renderFormOrKeyboard = () => {
    if (showKeyboard) {
      return (
        <>
          <div style={{ padding: 20, height: 100 }}>
            <Typography
              variant="h5"
              align={'center'}
              fontWeight={'bold'}
              color={section === 'game' ? 'lightBlue' : 'primary'}
            >
              Tap keys to enter your zip code:
            </Typography>
            <Typography
              variant="h4"
              align={'center'}
              fontWeight={'bold'}
              color={section === 'game' ? 'white' : 'primary'}
            >
              {zipCode}
            </Typography>
          </div>
          <Keyboard
            onKeyPress={onKeyBoardKeyPress}
            layoutName={'numericNV'}
            theme={`hg-theme-default hg-layout-default numericKeyBoardNV ${
              section === 'game' ? '' : 'numericKeyBoardNVDark'
            }`}
            layout={{ numericNV: ['1 2 3', '4 5 6', '7 8 9', '0 DEL', 'SUBMIT'] }}
            buttonTheme={[
              {
                class: 'nv-delete',
                buttons: 'DEL'
              },
              {
                class: 'nv-submit-lightblue',
                buttons: 'SUBMIT'
              }
            ]}
          />
        </>
      );
    }
    return (
      <div
        className="flex direction-column"
        style={{
          marginTop: '10vh'
        }}
      >
        {/* <Typography
          variant="h5"
          align={'center'}
          fontWeight={'bold'}
          color={section === 'game' ? 'lightBlue' : 'primary'}
          className="select-interest-topic"
        >
          Please select your topic of interest
        </Typography>
        <Grid
          container
          spacing={2}
          justifyContent="space-evenly"
        >
          <Grid item xs={10} md={5}>
            <div className="flex-center">
              <FormControlLabel
                onChange={handleTopicChange('Hematology')}
                checked={topic === 'Hematology'}
                value="Hematology"
                color="neutral"
                control={<CustomCheckBox section={section} />}
                label={<Typography sx={{ fontWeight: 900, fontSize: 30 }}>HEMATOLOGY</Typography>}
                labelPlacement="end"
              />
            </div>
          </Grid>
          <Grid item xs={10} md={5}>
            <div className="flex-center">
              <FormControlLabel
                onChange={handleTopicChange('Medical Oncology')}
                checked={topic === 'Medical Oncology'}
                value="Medical Oncology"
                control={<CustomCheckBox section={section} />}
                label={
                  <Typography sx={{ fontWeight: 900, fontSize: 30 }} noWrap>
                    MEDICAL ONCOLOGY
                  </Typography>
                }
                labelPlacement="end"
              />
            </div>
          </Grid>
        </Grid>
        <Divider color={dividerColor} sx={{ margin: 2 }} /> */}
        <div>
          {section !== 'game' && (
            <Typography variant="h5" align={'center'} fontWeight={'bold'} color={'primary'}>
              Connect with your ONE, explore educational offerings, and request specific topics for
              your clinic.
            </Typography>
          )}

          <p className="bold center-text" style={{ fontSize: '1rem' }}>
            Please enter your ZIP Code to identify your region.
          </p>
          <div className="flex horizontal-center" style={{ marginTop: 15 }}>
            <TextField
              className="input-zipcode"
              placeholder="Enter ZIP Code"
              variant="filled"
              color="primary"
              value={zipCode}
              onChange={(event) => setZipCode(event.target.value)}
              onFocus={toggleKeyboard}
              readOnly
              sx={{
                input: {
                  '&::placeholder': {
                    opacity: 0.9
                  }
                }
              }}
              inputProps={{
                readOnly: true,
                style: {
                  backgroundColor: 'white',
                  width: 180,
                  padding: 12,
                  fontSize: 20,
                  height: '2.5rem',
                  border: section !== 'game' && '1.5px solid gray'
                }
              }}
            />
            <Button
              variant="contained"
              color={section === 'game' ? 'neutral' : 'midLightGray'}
              onClick={handleRegionSearch}
              className="bold"
              sx={{ width: 100 }}
            >
              Find
            </Button>
          </div>
        </div>
        {region && <p className="bold center-text">You are in the {region} Region</p>}
        {!region && showRegionSearch && (
          <p className="bold yellow center-text">
            Sorry, we could not find that ZIP Code.{' '}
            <span
              role="button"
              tabIndex="0"
              style={{
                color: section === 'game' ? 'white' : 'black',
                textDecoration: 'underline',
                cursor: 'pointer'
              }}
              onClick={() => navigate(`/${section}/topics/map`)}
            >
              Please select manually.
            </span>
            <span style={{ color: 'white' }}>.</span>
          </p>
        )}
        <Divider color={dividerColor} sx={{ margin: 2 }} />
      </div>
    );
  };

  return (
    <Wrapper
      prevPath={'/'}
      nextPath={nextPath}
      nextPathLabel={nextPathLabel}
      section={section}
      containerWidth="md"
      hasHeader
      showButtons={!showKeyboard}
    >
      {renderFormOrKeyboard()}
    </Wrapper>
  );
};

export default TopicAndRegion;
