import _ from 'lodash';
import { Typography, Grid, FormControlLabel, Button } from '@mui/material';
import { CustomCheckBox } from '../../common';

import PrivacyNotice from './PrivacyNotice';

const topicGroups = {
  a: {
    title: 'HEMATOLOGY ONCOLOGY EDUCATION',
    titleColor: '#820050',
    checkBoxColor: '#820050',
    topics: [
      'B-Cell Malignancies Overview and Disease Management Considerations',
      'B-Cell Malignancies: Treatment Modalities and Clinical Adverse Reactions With Bruton Tyrosine Kinase Inhibitors'
    ]
  },
  b: {
    title: 'HEPATOBILIARY ONCOLOGY EDUCATION',
    titleColor: '#003265',
    checkBoxColor: '#003265',
    topics: [
      'Overview of Hepatobiliary Cancers: Unresectable Hepatocellular Carcinoma and Locally Advanced and Metastatic Biliary Tract Cancers'
    ]
  },
  c: {
    title: 'LUNG CANCER EDUCATION',
    titleColor: '#96a900',
    checkBoxColor: '#96a900',
    topics: [
      'Understanding Lung Cancer and the Patient Journey',
      'The Role of Nursing in Guiding Clinical Decisions Through Comprehensive Molecular Testing',
      'Survivorship in Lung Cancer',
      'Best Practices: Lung Cancer Screening and Smoking Cessation Interventions'
    ]
  },
  d: {
    title: 'GENERAL ONCOLOGY EDUCATION',
    titleColor: '#3a4342',
    checkBoxColor: '#3a4342',
    topics: [
      'Identification and Management of Oncologic Emergencies',
      'Recommendations to Help Support Adherence to Oral Anticancer Agents',
      'Best Practices: Cardio-Oncology in Nursing',
      'Best Practices: Precision Medicine in Oncology Nursing',
      'Understanding and Managing lmmune-Mediated Adverse Reactions Related to Treatment With Immune Checkpoint Inhibitors'
    ]
  }
};

const renderSection = (section, topics, handleTopicChange) => {
  return (
    <div style={{ marginBottom: 30 }}>
      <Typography
        variant="h6"
        gutterBottom
        // color="primary"
        // align="center"
        sx={{ fontWeight: 800, color: section.titleColor }}
      >
        {section.title}
      </Typography>
      {section.topics.map((topic, idx) => {
        return (
          <div
            key={idx}
            style={{
              backgroundColor: '#f8f8f8',
              border: '2px solid lightgray',
              borderRadius: 10,
              padding: '4px 8px',
              marginBottom: 10,
              maxWidth: 550
            }}
          >
            <FormControlLabel
              onChange={handleTopicChange(topic)}
              checked={_.includes(topics, topic)}
              value={topic}
              color="neutral"
              control={<CustomCheckBox checkBoxColor={section.checkBoxColor} />}
              label={<Typography sx={{ fontWeight: 800, fontSize: 16 }}>{topic}</Typography>}
              labelPlacement="end"
            />
          </div>
        );
      })}
    </div>
  );
};

const TopicSelect = ({ selectedTopics, handleTopicChange, setFormStep }) => {
  return (
    <>
      <Typography
        variant="h6"
        gutterBottom
        color="primary"
        align="center"
        sx={{ fontWeight: 800, marginTop: 5 }}
      >
        Select a topic for follow-up from your ONE.
      </Typography>
      <Grid container spacing={2} justifyContent={'space-around'} sx={{ margin: '30px 0' }}>
        <Grid item xs={12} md={6}>
          {renderSection(topicGroups.a, selectedTopics, handleTopicChange)}
          {renderSection(topicGroups.b, selectedTopics, handleTopicChange)}
          {renderSection(topicGroups.c, selectedTopics, handleTopicChange)}
        </Grid>
        <Grid item xs={12} md={6}>
          {renderSection(topicGroups.d, selectedTopics, handleTopicChange)}
          <PrivacyNotice />
          <Button
            sx={{ marginTop: 5, textTransform: 'unset', fontWeight: 800 }}
            variant="contained"
            color={'purple'}
            onClick={() => setFormStep('contactForm')}
          >
            Next: Send Your Request
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default TopicSelect;
